import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listmasterdata from './listmasterdata';     
import listmasterdata_detail from './listmasterdata_detail';     

export default class masterdata extends React.Component {
 
     render() {

        return (
            <Switch>  
            <Route exact path='/reportmasterdata/detail' component={listmasterdata_detail} /> 
            <Route exact path='/reportmasterdata/**' component={listmasterdata} /> 
            </Switch>
        );
    }

};