import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  ValidateData,
  ValidateDataEmail,
  ValidateEmail,
} from "../../common/util";
import {
  BRIDGEGET,
  COMPANY_UPDATE,
  BASE_URL,
  COMPANY_UPDATE_UPLOAD,
  BRIDGEPOSTFILE,
  BRIDGEPOST,
} from "../../common/constantVariable";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "5px auto 5px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class UserUpdateApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ID_COMPANY: "",
      COMPANY_NAME: "",
      COMPANY_NAME_1: "",
      COMPANY_ADDRESS: "",
      LOGO_COMPANY: "",
      logo_company: "",

      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
      previewUpload: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get(BASE_URL + "setupparameter/company/" + this.props.match.params.id)
      .then((result) => {
        this.setState({ openBackdrop: false });
        if (Object.keys(result.data.data).length > 0) {
          const user = result.data.data[0];
          this.setState({
            ID_COMPANY: this.props.match.params.id,
            COMPANY_NAME: user.COMPANY_NAME,
            COMPANY_NAME_1: user.COMPANY_NAME,
            COMPANY_ADDRESS: user.COMPANY_ADDRESS,
            LOGO_COMPANY: user.LOGO_COMPANY,
          });
        }
      });
  }

  handleChangeUpload = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });

    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({ previewUpload: reader.result });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({ previewUpload: "" });
    }
  };

  handleSubmit = () => {
    this.setState({ openBackdrop: true });
    let url_post = BRIDGEPOSTFILE;
    let url = COMPANY_UPDATE_UPLOAD;

    if (this.state.logo_company === "") {
      url_post = BRIDGEPOST;
      url = COMPANY_UPDATE;
    }

    var postData = new FormData();
    postData.append("company_id", this.state.ID_COMPANY);
    postData.append("company_name", this.state.COMPANY_NAME);
    postData.append("company_address", this.state.COMPANY_ADDRESS);
    postData.append("file", this.state.logo_company);
    postData.append("url", url);

    this.setState({ openBackdrop: true });
    axios
      .post(url, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          message: res.data.data,
          openSnack: true,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnack: true,
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Grid item sm />
        <Grid item sm>
          <Typography variant="h6" className={classes.pageTitle}>
            Edit Perusahaan {this.state.COMPANY_NAME_1}
          </Typography>

          <form id="form1" name="form1" noValidate>
            <TextField
              id="ID_COMPANY"
              name="ID_COMPANY"
              type="text"
              InputProps={{
                readOnly: true,
              }}
              label="ID Perusahaan"
              variant="outlined"
              size="small"
              className={classes.textField}
              error={this.state.errors["ID_COMPANY"]}
              value={this.state.ID_COMPANY}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="COMPANY_NAME"
              name="COMPANY_NAME"
              type="text"
              variant="outlined"
              size="small"
              label="Nama Perusahaan"
              className={classes.textField}
              helperText={this.state.helpers["COMPANY_NAME"]}
              error={this.state.errors["COMPANY_NAME"]}
              value={this.state.COMPANY_NAME}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="COMPANY_ADDRESS"
              name="COMPANY_ADDRESS"
              type="text"
              label="Alamat Perusahaan"
              variant="outlined"
              size="small"
              multiline
              rows={3}
              className={classes.textField}
              helperText={this.state.helpers["COMPANY_ADDRESS"]}
              error={this.state.errors["COMPANY_ADDRESS"]}
              value={this.state.COMPANY_ADDRESS}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <input
              accept="image/*"
              name="logo_company"
              className={classes.input}
              style={{ display: "none" }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={this.handleChangeUpload}
            />
            <label htmlFor="raised-button-file">
              <Button color="primary" variant="outlined" component="span">
                Upload Logo Perusahaan
              </Button>
            </label>
            {this.state.LOGO_COMPANY !== "" &&
              this.state.previewUpload === "" && (
                <div>
                  <img
                    src={this.state.LOGO_COMPANY}
                    width={130}
                    alt="Description"
                  />
                </div>
              )}
            {this.state.previewUpload !== "" && (
              <div>
                <img
                  src={this.state.previewUpload}
                  width={130}
                  alt="Description"
                />
              </div>
            )}
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Simpan
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserUpdateApp);
