import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import {
  BASE_URL,
  REGISTER_SETUP_ASUMPTION_RATE,
} from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload_dokumen: "",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",

      listBulanPeriode: [],
      listTahunPeriode: [],
      bulan_laporan: "",
      tahun_laporan: "",
      param_model: "",
      year: new Date().getFullYear(),
      minYear: 2010,
      biaya_akuisisi: "",
      nilai_inflasi: "",
      discount_rate: "",
      expense_thn_1_polis: "",
      expense_thn_2_polis: "",
      expense_per_100_polis_thn_1: "",
      expense_per_100_polis_thn_2: "",
      expense_per_1000_fa_thn_1: "",
      expense_per_1000_fa_thn_2: "",
      pad_mortalita: "",
      pad_lapse: "",
      pad_inflasi: "",
      pad_unit_expense: "",
      pad_b1: "",
      pad_b2: "",
      pad_b3: "",
      pad_b4: "",
      pad_b5: "",
      pad_b6: "",
      pad_b7: "",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    if (
      e.target.name === "biaya_akuisisi" ||
      e.target.name === "nilai_inflasi" ||
      e.target.name === "discount_rate" ||
      e.target.name === "expense_thn_1_polis" ||
      e.target.name === "expense_thn_2_polis" ||
      e.target.name === "expense_per_100_polis_thn_1" ||
      e.target.name === "expense_per_100_polis_thn_2" ||
      e.target.name === "expense_per_1000_fa_thn_1" ||
      e.target.name === "expense_per_1000_fa_thn_2" ||
      e.target.name === "pad_mortalita" ||
      e.target.name === "pad_lapse" ||
      e.target.name === "pad_inflasi" ||
      e.target.name === "pad_unit_expense" ||
      e.target.name === "pad_b1" ||
      e.target.name === "pad_b2" ||
      e.target.name === "pad_b3" ||
      e.target.name === "pad_b4" ||
      e.target.name === "pad_b5" ||
      e.target.name === "pad_b6" ||
      e.target.name === "pad_b7"
    ) {
      let inputValue = e.target.value;
      const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
      e.target.value = sanitizedValue;
    }
    if (e.target.name === "param_model") {
      let inputValue = e.target.value;
      let value = inputValue.replace(/[^a-zA-Z0-9-]/g, "");
      value = value.replace(/\s/g, "");
      e.target.value = value;
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  downloadData = (e) => {
    this.setState({ openBackdrop: true });

    axios
      .get(BASE_URL + "downloadReport/download_last_asumpion_rate")
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");

    const valid = ValidateData(e.elements);

    if (!valid) {
      this.setState({
        messageError: "Data tidak lengkap.",
        openSnackError: true,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("biaya_akuisisi", this.state.biaya_akuisisi);
    formData.append("nilai_inflasi", this.state.nilai_inflasi);
    formData.append("discount_rate", this.state.discount_rate);
    formData.append("expense_thn_1_polis", this.state.expense_thn_1_polis);
    formData.append("expense_thn_2_polis", this.state.expense_thn_2_polis);
    formData.append(
      "expense_per_100_polis_thn_1",
      this.state.expense_per_100_polis_thn_1
    );
    formData.append(
      "expense_per_100_polis_thn_2",
      this.state.expense_per_100_polis_thn_2
    );
    formData.append(
      "expense_per_1000_fa_thn_1",
      this.state.expense_per_1000_fa_thn_1
    );
    formData.append(
      "expense_per_1000_fa_thn_2",
      this.state.expense_per_1000_fa_thn_2
    );
    formData.append("pad_mortalita", this.state.pad_mortalita);
    formData.append("pad_lapse", this.state.pad_lapse);
    formData.append("pad_inflasi", this.state.pad_inflasi);
    formData.append("pad_unit_expense", this.state.pad_unit_expense);
    formData.append("pad_b1", this.state.pad_b1);
    formData.append("pad_b2", this.state.pad_b2);
    formData.append("pad_b3", this.state.pad_b3);
    formData.append("pad_b4", this.state.pad_b4);
    formData.append("pad_b5", this.state.pad_b5);
    formData.append("pad_b6", this.state.pad_b6);
    formData.append("pad_b7", this.state.pad_b7);
    formData.append("param_model", this.state.param_model);
    formData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_ASUMPTION_RATE, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
          loading: false,
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.messageError}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Input Asumption Rate
              </Typography>
              <form id="form1" name="form1" noValidate>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <InputLabel htmlFor="custom-text-field">
                    ASUMTIONRATE-
                  </InputLabel>
                  <TextField
                    id="param_model"
                    name="param_model"
                    type="text"
                    label="Nama Model"
                    variant="outlined"
                    size="small"
                    inputProps={{
                      style: { textTransform: "uppercase" },
                      maxLength: 15, // Jumlah maksimal karakter, sesuaikan dengan kebutuhan Anda
                    }}
                    style={{ width: "250px" }}
                    className={classes.textField}
                    value={this.state.param_model}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="biaya_akuisisi"
                    name="biaya_akuisisi"
                    type="text"
                    label="Biaya Akuisisi"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.biaya_akuisisi}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="nilai_inflasi"
                    name="nilai_inflasi"
                    type="text"
                    label="Nilai Inflasi"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.nilai_inflasi}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="discount_rate"
                    name="discount_rate"
                    type="text"
                    label="Diskon Rate"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.discount_rate}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_thn_1_polis"
                    name="expense_thn_1_polis"
                    type="text"
                    label="Expense Thn 1 Per Policy/Ins"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 34 }}
                    className={classes.textField}
                    value={this.state.expense_thn_1_polis}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Rp
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_thn_2_polis"
                    name="expense_thn_2_polis"
                    type="text"
                    label="Expense Thn 2 dst Per Policy/Ins"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 34 }}
                    className={classes.textField}
                    value={this.state.expense_thn_2_polis}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Rp
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_per_100_polis_thn_1"
                    name="expense_per_100_polis_thn_1"
                    type="text"
                    label="Expense per 100 Premium Thn 1"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 5 }}
                    className={classes.textField}
                    value={this.state.expense_per_100_polis_thn_1}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Angka
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_per_100_polis_thn_2"
                    name="expense_per_100_polis_thn_2"
                    type="text"
                    label="Expense per 100 Premium Thn 2"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 5 }}
                    className={classes.textField}
                    value={this.state.expense_per_100_polis_thn_2}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Angka
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_per_1000_fa_thn_1"
                    name="expense_per_1000_fa_thn_1"
                    type="text"
                    label="Expense per 1000 FA Thn 1"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 5 }}
                    className={classes.textField}
                    value={this.state.expense_per_1000_fa_thn_1}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Angka
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="expense_per_1000_fa_thn_2"
                    name="expense_per_1000_fa_thn_2"
                    type="text"
                    label="Expense per 1000 FA Thn 2"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 5 }}
                    className={classes.textField}
                    value={this.state.expense_per_1000_fa_thn_2}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam Angka
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_mortalita"
                    name="pad_mortalita"
                    type="text"
                    label="PAD Mortalita"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_mortalita}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_lapse"
                    name="pad_lapse"
                    type="text"
                    label="PAD Lapse"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_lapse}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_inflasi"
                    name="pad_inflasi"
                    type="text"
                    label="PAD Inflasi"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_inflasi}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_unit_expense"
                    name="pad_unit_expense"
                    type="text"
                    label="PAD Unit Expense"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_unit_expense}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b1"
                    name="pad_b1"
                    type="text"
                    label="PAD Benefit 1"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b1}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b2"
                    name="pad_b2"
                    type="text"
                    label="PAD Benefit 2"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b2}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b3"
                    name="pad_b3"
                    type="text"
                    label="PAD Benefit 3"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b3}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b4"
                    name="pad_b4"
                    type="text"
                    label="PAD Benefit 4"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b4}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b5"
                    name="pad_b5"
                    type="text"
                    label="PAD Benefit 5"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b5}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b6"
                    name="pad_b6"
                    type="text"
                    label="PAD Benefit 6"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b6}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="pad_b7"
                    name="pad_b7"
                    type="text"
                    label="PAD Benefit 7"
                    variant="outlined"
                    size="small"
                    style={{ width: "290px", marginRight: 40 }}
                    className={classes.textField}
                    value={this.state.pad_b7}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                  <InputLabel
                    style={{ alignItems: "left" }}
                    htmlFor="custom-text-field"
                  >
                    Dalam %
                  </InputLabel>
                </div>
              </form>

              <br></br>
            </Grid>
            <Grid item sm />
          </Grid>
        </Paper>
        <Paper
          className={classes.m20}
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Grid container rowSpacing={0.1}>
                <br></br>
                <Grid item xs={8}>
                  <Div>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleSubmit}
                    >
                      {this.state.btnWording}
                    </Button>{" "}
                    &nbsp; &nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() => {
                        window.history.back(-1);
                      }}
                    >
                      Batal
                    </Button>
                  </Div>
                </Grid>
                <Grid item xs={4}>
                  <Div>
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      className={classes.button}
                      onClick={this.downloadData}
                    >
                      Download Data Terakhir
                    </Button>
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
