import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { BASE_URL } from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { SaveItem, GetItem, RemoveItem } from "../../../common/util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      list_periode: [],
      listPrmModel: [],
      searched: "",
      errormessage: "",
      prm_model: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    RemoveItem("prm_cari");
    this.getListPeriodeClosing();
  }

  getListPeriodeClosing = () => {
    axios
      .get("prosesdata/list_procress_done")
      .then((res) => {
        this.setState({
          list_periode: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          list_periode: [],
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <br></br>
        <br></br>
        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
          classes={classes}
          listPeriode={this.state.list_periode}
          getListPeriodeClosing={this.getListPeriodeClosing}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "PERIOD_DATA", label: "PERIOD_DATA", minWidth: 80 },
  { id: "COHORT", label: "COHORT", minWidth: 80 },
  { id: "IFRS_MODEL", label: "MODEL", minWidth: 80 },
  { id: "PRODUCT_NAME", label: "PRODUCT NAME", minWidth: 80 },
  { id: "MORTALITY_RATE", label: "MORTALITY RATE", minWidth: 120 },
  { id: "LAPSE_RATE", label: "LAPSE RATE", minWidth: 120 },
  { id: "ASUMPTION_RATE", label: "ASUMPTION RATE", minWidth: 120 },
  { id: "RIDER1RATE", label: "RIDER 1 RATE", minWidth: 120 },
  { id: "RIDER2RATE", label: "RIDER 2 RATE", minWidth: 120 },
  { id: "RIDER3RATE", label: "RIDER 3 RATE", minWidth: 120 },
];

const StickyHeadTable = ({
  list,
  isLoading,
  classes,
  listPeriode,
  getListPeriodeClosing,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [closing_period, setClosing_periode] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [listperiode, setListperiode] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [dialogOpenDuplikat, setDialogOpenDuplikat] = React.useState(false);
  const [inputTextDuplikat, setInputTextDuplikat] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const [messageSnackError, setMessageSnackError] = React.useState("");

  useEffect(() => setListperiode(listPeriode), [listPeriode]);
  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const setDataModel = () => {
    if (closing_period === "") {
      alert("Pilih Periode terlebih dahulu");
      return false;
    }

    setOpenBackdrop(true);
    setRows([]);
    axios
      .get("downloadReport/reportCohort/" + closing_period)
      .then((res) => {
        setOpenBackdrop(false);
        setRows(res.data.data);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        setRows([]);
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChangeJenisModel = (e) => {
    const value = e.target.value;
    if (value === "") {
      setClosing_periode("");
    } else {
      setClosing_periode(value);
    }
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Pilih Periode
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              onChange={handleChangeJenisModel}
              value={closing_period}
              size="small"
              style={{ height: 49 }}
              label="Pilih Periode"
              name="closing_period"
            >
              {listperiode.map((dataPrm, idx) => (
                <MenuItem value={dataPrm.closing_period}>
                  {dataPrm.tahun_laporan}-{dataPrm.nama_bulan}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={setDataModel}>
            Cari
          </Button>
        </Grid>
      </Grid>
      <br />

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
