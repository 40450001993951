import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import UserListApp from './userlist';
import UserRegisterApp from './register';
import UserUpdateApp from './update';


export default class UserApp extends React.Component {

     render() {

        return (
            <Switch>
                <Route exact path='/user/register' component={UserRegisterApp} />
                <Route exact path='/user/edit/:id' component={UserUpdateApp} />
                <Route exact path='/user/**' component={UserListApp} />

            </Switch>
        );
    }

};