import React, { Component, useState}  from 'react';
import withStyles from '@material-ui/core/styles/withStyles'; 
import axios from 'axios';
  
import Grid  from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import TextField  from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import {ValidateData, ValidateEmail, GetItem} from '../../../common/util';
import { BASE_URL} from '../../../common/constantVariable'; 

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 
  
const styles = {
    form: {
        textAlign: 'center'
    },
    image: {
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '5px auto 5px auto'
    },
    button: {
        marginTop: 20
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    }
};


class UserUpdateApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            AGE: GetItem('age'),
            prm_model: GetItem('prm_model'),
            age: '',
            lapse_rate: '', 
            valid: false,
            helpers: [],
            errors: [],
            errormessage: '', 
            openSnack : false, 
            openSnackError : false,
            Backdrop : false,
            message: '',

            listBulanPeriode : [], 
            listTahunPeriode : [],  
            inserted_by : GetItem('user_id'),
            bulan_laporan : '',
            tahun_laporan : '',
            year : new Date().getFullYear(),
            minYear : 2010,
        } 
    }
     
    componentDidMount() {
        this.setState({openBackdrop:true})
        axios.get(BASE_URL+'setupparameter/list_lapse_rate/' + this.state.AGE+'/' + this.state.prm_model)
            .then ( result => { 
                this.setState({openBackdrop:false})
                if (Object.keys(result.data.data).length>0) {
                    const user = result.data.data[0];  
                    this.setState({ 
                        age: user.AGE,
                        lapse_rate: user.Lapse_Rate,
                    });
                }
            })
    }

    handleSubmit = () => {
 
        const e = document.getElementById("form1");
         
        if (ValidateData(e.elements)===false) {
            this.setState({
                errormessage: "Data tidak lengkap"
            });    
            return false;
        }

       
 
        this.setState({openBackdrop:true})
        axios.put('setupparameter/lapse_rate/'+this.state.AGE+'/'+this.state.prm_model, this.state)
            .then((res) => {
                this.setState({openBackdrop:false})
 
                if(res.data.message != 'success'){
                    this.setState({
                        message : res.data.data,
                        openSnackError : true
                    })
                }else
                {
                    this.setState({
                        message : res.data.data,
                        openSnack : true
                    })
                } 
            })
            .catch( (err) => {
                this.setState({openBackdrop:false})
                this.setState({
                    errormessage: 'Gagal simpan data'
                })
            });
        
    }

    handleChange = (e) => {
        const error = e.target.required && e.target.value==='';

        this.state.errors[e.target.name] = error;
        this.state.helpers[e.target.name] =  error ? 'required' : '';
        
        if (!error &&  e.target.type==='email') {
            if (!ValidateEmail(e.target.value)) {
                this.state.errors[e.target.name] = true;
                this.state.helpers[e.target.name] = 'format email salah';
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            errormessage: '',
            errors: this.state.errors, 
            helpers: this.state.helpers
        })

    };

    handleClose = (event, reason) => {
        this.setState({openSnack:false});
        if (reason === 'timeout') {
            window.history.back(-1);
          }
      };
    

    render() { 
        const { classes } = this.props;
        const { errors, loading } = this.state;

        return (
            <Grid container className={classes.form} >
           <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.openBackdrop}
                onClick={this.handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
           
                <Snackbar open={this.state.openSnack} autoHideDuration={1000} 
                    onClose={(event, reason) => this.handleClose(event, reason)}>
                    <Alert severity="success" sx={{ width: '100%' }} variant="filled" 
                    onClose={() => {this.setState({openSnack:false}); window.history.back(-1)}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openSnackError} autoHideDuration={2000} >
                    <Alert severity="error" sx={{ width: '100%' }} variant="filled" 
                    onClose={() => {this.setState({openSnackError:false})}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>

                <Grid item sm />
                <Grid item sm>
                    
                    <Typography variant="h6" className={classes.pageTitle}>
                        Update Lapse Rate {this.state.age}
                    </Typography>

                    <form id="form1" name="form1" noValidate >
                    <TextField
                            id="email" name="age"  
                            type="number"
                            variant='outlined'
                            size='small'
                            label="AGE"
                            className={classes.textField}
                            value={this.state.age}
                            onChange={this.handleChange}
                            required fullWidth />

                        <TextField
                            id="username" name="lapse_rate"  
                            type="number"
                            label="Lapse Rate"
                            variant='outlined'
                            size='small'
                            className={classes.textField}
                            value={this.state.lapse_rate}
                            onChange={this.handleChange}
                            required fullWidth />
 

                        <Typography variant='body2' className={classes.customError} >
                            {this.state.errormessage}
                        </Typography>

                        <Button variant='contained' color='primary' className={classes.button} onClick={this.handleSubmit}>
                            Simpan
                        </Button> &nbsp;&nbsp;&nbsp;

                        <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                            Batal
                        </Button>

                     </form>
                </Grid>
                <Grid item sm />
            </Grid>
        )
    }
}

export default withStyles(styles) (UserUpdateApp);




