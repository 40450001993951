import React, { Fragment } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

import InputLabel from "@mui/material/InputLabel";
import { GetItem } from "../../../common/util";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";

import "@fontsource/roboto/400.css";

class listBsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      messageError: "",
      openSnackError: false,
      periode_laporan: "",
      closing_id: "",
      jenis_laporan: "",
      Backdrop: false,
      message: "",
      kategori: "",
      btnDownloadExcel: "Download Excel",
      btnDownloadPdf: "Download PDF",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get("downloadReport/list_period_data/balance_sheet_sum")
      .then((res) => {
        this.setState({
          openBackdrop: false,
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          openBackdrop: false,
          list: [],
        });
      });
  }

  handleDownloadExcel = () => {
    const tahun_laporan = this.state.closing_id;

    if (tahun_laporan === "") {
      this.setState({
        messageError: "Silakan pilih periode laporan terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    }

    var postData = new FormData();
    postData.append("closing_id", this.state.closing_id);
    postData.append("kategori", this.state.kategori);
    postData.append("user_id", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("downloadReport/download_report_bs_summary_excel", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleDownloadPdf = () => {
    const tahun_laporan = this.state.closing_id;

    if (tahun_laporan === "") {
      this.setState({
        messageError: "Silakan pilih periode laporan terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    }

    var postData = new FormData();
    postData.append("closing_id", this.state.closing_id);
    postData.append("kategori", this.state.kategori);
    postData.append("user_id", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("downloadReport/download_report_bs_summary_pdf", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleChangeAutoComplete = (event, newValue) => {
    const value = newValue.closing_id;
    if (value != "") {
      this.setState({
        closing_id: value,
      });
    }
  };
  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4">Report Balance Sheet</Typography>
        <br></br>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.messageError}
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item sm>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={this.state.list}
                    onChange={this.handleChangeAutoComplete}
                    name="closing_period"
                    getOptionLabel={(option) =>
                      option.tahun_laporan + " - " + option.nama_bulan
                    }
                    value={
                      this.state.list.find(
                        (option) => option.closing_id === this.state.closing_id
                      ) || null
                    }
                    sx={{ width: 250 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pilih Periode"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownloadExcel}
                >
                  {this.state.btnDownloadExcel}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownloadPdf}
                >
                  {this.state.btnDownloadPdf}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default listBsApp;
