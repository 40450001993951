import React from "react";
import { Route, Switch } from "react-router-dom";

import listApp from "./list";
import register from "./register";
import update from "./update";

export default class Rider_rate extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/setup/benefit_3_rate/register"
          component={register}
        />
        <Route exact path="/setup/benefit_3_rate/update" component={update} />
        <Route exact path="/setup/benefit_3_rate/**" component={listApp} />
      </Switch>
    );
  }
}
