import React, { Component, Fragment, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Box from "@mui/material/Box";
import { GetItem } from "../../../common/util";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "left",
  },
  pageTitle: {
    textAlign: "center",
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class detailMulaiProsesApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PERIOD_DATA: "",
      valid: false,
      listCohort: [],
      messageError: "",
      openSnackError: false,
      message: "",
      showForm: false,
      openSnack: false,
      disabled_option: false,
      bukaDialog: false,
      openBackdrop: false,
      hasCheckData: false,
      btnWording: "Simpan",
    };
  }

  componentDidMount() {}

  deleteDataGMM = () => {
    this.deleteData("GMM");
  };
  deleteDataPAA = () => {
    this.deleteData("PAA");
  };
  deleteDataVFA = () => {
    this.deleteData("VFA");
  };
  deleteDataLIC = () => {
    this.deleteData("LIC");
  };
  deleteDataREASGMM = () => {
    this.deleteData("REASGMM");
  };
  deleteDataREASPAA = () => {
    this.deleteData("REASPAA");
  };

  deleteData = (model) => {
    this.setState({ openBackdrop: true });
    axios
      .delete("tools/deletebackup/" + model + "/" + GetItem("user_id"))
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message === "success") {
          alert("Berhasil hapus data backup");
        } else {
          alert(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        // alert(res.data.message);
        this.setState({ openBackdrop: false });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.pageTitle}>
            Delete Data Backup
          </Typography>{" "}
          <br></br>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.openBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
        <Grid item xs={12}>
          <Grid container rowSpacing={0.5}>
            <br></br>
            <br></br>

            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataGMM}
                >
                  Delete Backup Data GMM
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataPAA}
                >
                  Delete Backup Data PAA
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataVFA}
                >
                  Delete Backup Data VFA
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataLIC}
                >
                  Delete Backup Data LIC
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <br></br>&nbsp;
        <br></br>
        <Grid item xs={12}>
          <Grid container rowSpacing={0.5}>
            <br></br>
            <br></br>

            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataREASGMM}
                >
                  Delete Backup Data REAS GMM
                </Button>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.deleteDataREASPAA}
                >
                  Delete Backup Data REAS PAA
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(detailMulaiProsesApp);
