import React, { Component, Fragment, useEffect}  from 'react'; 
import { Link,useHistory } from 'react-router-dom'; 
import axios from 'axios';
 
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'; 
import Button from '@mui/material/Button';  
import {BRIDGEGET,BASE_URL,BRIDGEDELETE} from '../../../common/constantVariable';
import Select from '@mui/material/Select';  
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';  
import { GetItem,SaveItem } from '../../../common/util';

 
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchBar from "material-ui-search-bar";
import Grid from '@mui/material/Grid';  
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
 
const styles = {
  search: {
      backgroundColor: '#dedede'
  }, 
};

class ProseslistApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            searched : '',
            errormessage: '', 
            openSnack : false,
            isLoading : false,
            openSnackError : false,
            message: '',
        }
    } 
    
    componentDidMount() {
        axios.get(BASE_URL+'setupparameter/list_morbidity_rate')
            .then( (res) => {
                this.setState({
                    list: res.data.data
                })
            })
            .catch( (err) => { 
                this.setState({
                    list: []
                })
            });
    };

 

    render() {

        const { classes } = this.props;

        return (
          <Fragment>
            <div> 
              <Button variant="contained" color="primary" onClick={this.handleNewProses} component={Link} to="/setup/morbidityrate/register">
              Tambah Morbidity Rate
                </Button>
            </div><br></br>

            <StickyHeadTable list={this.state.list} showProgress={this.state.isLoading} classes={classes}  />

          </Fragment>
        )

    }
}

export default withStyles(styles) (ProseslistApp);

const columns = [ 
  { id: 'KD_PRODUK', label: 'Kode Produk', minWidth: 120 },
  { id: 'AGE', label: 'Age', minWidth: 120 },
  { id: 'Morbidity_Rate', label: 'Morbidity Rate', minWidth: 120 }
]

const StickyHeadTable = ({list,isLoading,classes}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);   
  const [rows, setRows] = React.useState([]); 
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]); 
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [searched, setSearched] = React.useState("");

  
  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  
  
  let history = useHistory();
   
  const deleteData = () => { 
    axios.delete('setupparameter/morbidity_rate/' + GetItem('kd_produk')+'/'+GetItem('age'))
          .then( res => {  
               setRows([]);
               handleCloseDialog()
                axios.get(BASE_URL+'setupparameter/list_morbidity_rate')
                .then( (res) => {
                  setRows(res.data.data);
                })
                .catch( (err) => { 
                  handleCloseDialog() 
                });
          })
          .catch( err => {
            
          })
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
    
    
  const handleOpenClosing = (e) =>{
    const baseVal = e.target.value.split('-');
    const kd_produk = baseVal[0];  
    const age = baseVal[1];  
    SaveItem('age',age)  
    SaveItem('kd_produk',kd_produk)  
      if(baseVal[2] == 'update')
        {  
            history.push("/setup/morbidityrate/update");
        }else  if(baseVal[2] == 'delete')
        {  
          setOpenDialog(true)
          
        } 
    
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cdcdcd',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));


const handleSelectOption = (e) =>{
  const value = e.target.value;
  if(value ==='reset')
  {
    setKeyword_cari('');
    setSearched("");
    requestSearch('');
  }else
  {
    setKeyword_cari(value);
  }
  
}
const requestSearch = (searchedVal) => {   
  if(keyword_cari !== '')
  {
    const filteredRows = list.filter((row) => {
      if(keyword_cari === 'age')
      {
        return row.AGE.toString().toLowerCase().includes(searchedVal.toLowerCase());
      }else if(keyword_cari === 'kd_produk')
      {
        return row.KD_PRODUK.toString().toLowerCase().includes(searchedVal.toLowerCase());
      }else if(keyword_cari === 'rate')
      {
        return row.Morbidity_Rate.toString().toLowerCase().includes(searchedVal.toLowerCase());
      }  
    }); 
    setRows(filteredRows);
  } 
};

const cancelSearch = () => {
  setSearched("");
  requestSearch(searched);
};


  return (
 
    <Box sx={{ overflow: 'hidden' }}>  
     <Grid container spacing={2}>
          <Grid item xs={4}>
          <FormControl variant="filled" fullWidth 
          >
              <InputLabel id="demo-simple-select-helper-label">Cari Berdasarkan</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper" 
                value={keyword_cari} 
                onChange={handleSelectOption}
                size="small" 
                name="keyword_cari"
                label="Cari Berdasarkan" 
              > 
              <MenuItem value="kd_produk">	Kode Produk</MenuItem>
              <MenuItem value="age">Age</MenuItem> 
              <MenuItem value="rate">Rate</MenuItem>
              <MenuItem value="reset">Reset</MenuItem>

              </Select>
          </FormControl>
                    
          </Grid>
          <Grid item xs={4}>
            
            <SearchBar
             className={classes.search}
              value={searched} 
              onChange={(searchedVal) => requestSearch(searchedVal)}
              onCancelSearch={() => cancelSearch()}
              placeholder="cari disini.."
            />
          </Grid> 
        </Grid><br/>
         <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Hapus Data ?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Anda yakin ingin menghapus data ini ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Batal</Button>
              <Button onClick={deleteData} autoFocus>
                Hapus
              </Button>
            </DialogActions>
          </Dialog>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <StyledTableCell
                  key='no'
                  align='center'
                  style={{ minWidth: 40 }}
                >
                  No
                </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
                 <StyledTableCell
                  key='edit'
                  align='center'
                  style={{ minWidth: 30 }}
                >
                  Aksi
                </StyledTableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => { 
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key='no' align='center'>
                        {idx + 1 + page * rowsPerPage}    
                      </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];
                      

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key='edit' align='center'>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}> 
                          <Select value='aksi' onChange={handleOpenClosing} label='Aksi'>
                            <MenuItem value='aksi'>Aksi</MenuItem> 
                               <MenuItem value={row.KD_PRODUK+'-'+row.AGE+'-update'}>Ubah Parameter</MenuItem> 
                               <MenuItem value={row.KD_PRODUK+'-'+row.AGE+'-delete'}>Hapus Parameter</MenuItem> 
                                
                                 
                                 
                            
                          </Select></FormControl>
                      </TableCell> 
 

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );

}









