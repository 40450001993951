import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listApp from './list';  
import register from './register';  
import update from './update';    

export default class Benefit_4 extends React.Component {
  
     render() {

        return (
            <Switch>  
            <Route exact path='/setup/benefit_4/register' component={register} /> 
            <Route exact path='/setup/benefit_4/update' component={update} />
            <Route exact path='/setup/benefit_4/**' component={listApp} /> 
            </Switch>
        );
    }

};