export const SaveItem = (key,value) => {
    sessionStorage.setItem(key, value);
   // localStorage.setItem(key, value);

}

export const GetItem = (key) => {
   // if (localStorage.getItem(key)) return localStorage.getItem(key); 
    if (sessionStorage.getItem(key)) return sessionStorage.getItem(key); 
    return '';
}


export const RemoveItem = (key) => {
    sessionStorage.removeItem(key);
 }
 

export const Rupiah = (number)=>{
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(number);
  }

export const ClearSession = () => {
    localStorage.clear();
    sessionStorage.clear();
}

export const ValidateEmail = (email) => {
    var emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
    return (email!="") && emailfilter.test(email)
}


export const ValidateData = (elements) => {
    for (var i = 0, element; element = elements[i++];) {
        if (element.required) {
            if (element.value === '') {
                // console.log(element.name + ' is blank');
                return false;
            }
            
            // if ("email" === element.type) {
            //     if (ValidateEmail(element.value)===false) {
            //         console.log(element.name + ' invalid email format');
            //         return false;    
            //     }   
            // }
        }
    }
    // console.log('valid');
    return true;
}


export const ValidateDataEmail = (elements) => {
    for (var i = 0, element; element = elements[i++];) {
        if (element.required) {
            if ("email" === element.type) {
                if (ValidateEmail(element.value)===false) {
                    // console.log(element.name + ' invalid email format');
                    return false;    
                }   
            }
        }
    }
    return true;
}


