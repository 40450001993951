import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import companylist from './companylist';
import register from './register';
import update from './update';


export default class UserApp extends React.Component {

     render() {

        return (
            <Switch>
                <Route exact path='/setupcompany/register' component={register} />
                <Route exact path='/setupcompany/edit/:id' component={update} />
                <Route exact path='/setupcompany/**' component={companylist} />

            </Switch>
        );
    }

};