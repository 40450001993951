import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem, RemoveItem } from "../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import { UPLOAD_FILE } from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  label: {
    textAlign: "left",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class uploadAktualTransaksi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload_dokumen: "",
      isLic: false,
      closing_id: GetItem("closing_id"),
      bulan_laporan: GetItem("bulan_laporan"),
      tahun_laporan: GetItem("tahun_laporan"),
      jenis_file_read_only: false,
      jenis_file: "TRANSAKSI_AKTUAL",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      upload_ulang: "0",
      upload_id: "",
      btnWording: "Simpan",
    };
  }

  componentDidMount() {
    if (GetItem("upload_ulang") === "1") {
      this.setState({
        upload_ulang: GetItem("upload_ulang"),
        jenis_file: GetItem("jenis_file"),
        upload_id: GetItem("upload_id"),
        jenis_file_read_only: true,
      });
    }
  }

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    if (e.target.value === "LIC") {
      this.setState({
        isLic: true,
      });
    } else {
      this.setState({
        isLic: false,
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleChangeUpload = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");
    const valid = ValidateData(e.elements);

    if (this.state.jenis_file === "") {
      this.setState({
        message: "Silakan pilih jenis file terlebih.",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    } else if (this.state.upload_dokumen === "") {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }

    this.setState({
      btnWording: "sedang mengupload dokumen",
      isLoading: true,
    });

    const formData = new FormData();
    formData.append("closing_id", this.state.closing_id);
    formData.append("jenis_file", this.state.jenis_file);
    formData.append("upload_id", this.state.upload_id);
    formData.append("inserted_by", GetItem("user_id"));

    formData.append("file", this.state.upload_dokumen);

    this.setState({ openBackdrop: true });
    axios
      .post(UPLOAD_FILE, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
          loading: false,
        });
      });
  };

  downloadDataLastActualTransaction = (e) => {
    this.setState({ openBackdrop: true });

    axios
      .get("downloadReport/download_last_actual_transaction")
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" className={classes.pageTitle}>
                Upload File Transaksi Aktual
              </Typography>
              <form id="form1" name="form1" noValidate>
                {" "}
                <br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Jenis File
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.jenis_file}
                    onChange={this.handleChange}
                    size="small"
                    disabled={true}
                    name="jenis_file"
                    label="Jenis File"
                  >
                    <MenuItem value="TRANSAKSI_AKTUAL">
                      Data Transaksi Aktual
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormGroup
                  className={classes.m10}
                  style={{ display: this.state.isLic === true ? "none" : "" }}
                >
                  <br></br>
                  <label className={classes.label}>Upload Data</label>
                  <TextField
                    variant="outlined"
                    name="upload_dokumen"
                    type="file"
                    inputProps={{
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={this.handleChangeUpload}
                    fullWidth
                    size="small"
                  />
                </FormGroup>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    RemoveItem("upload_ulang");
                    RemoveItem("upload_id");
                    RemoveItem("jenis_file");
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
              </form>
            </Grid>
            <Grid item sm />
          </Grid>

          <Grid container>
            <Grid item sm className={classes.m10}>
              <Typography variant="h6" className={classes.pageTitle}>
                Catatan :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              1. Pastikan pilihan Jenis upload sama dengan format data yg
              diupload. Untuk contoh format data dapat didownload di bagian
              bawah.
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.m20}
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Typography variant="h6" className={classes.pageTitle}>
                Download Template Upload
              </Typography>
              <Grid item xs={4}>
                <Div>
                  {" "}
                  <a href="#" onClick={this.downloadDataLastActualTransaction}>
                    {" "}
                    Download Data Transaksi Aktual
                  </a>
                </Div>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(uploadAktualTransaksi);
