import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, ValidateEmail, GetItem } from "../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { USER_REGISTER } from "../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "5px auto 5px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class UserRegisterApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      username: "",
      user_id: "",
      name: "",
      password: "",
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openBackdrop: false,
      message: "",
    };
    // console.log(this.state);
  }

  componentDidMount() {}

  handleSubmit = () => {
    const e = document.getElementById("form1");

    const valid = ValidateData(e.elements);

    if (!valid) {
      this.setState({
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    var postData = new FormData();
    postData.append("email", this.state.email);
    postData.append("username", this.state.username);
    postData.append("password", this.state.password);
    postData.append("name", this.state.name);
    postData.append("created_by", GetItem("user_id"));
    postData.append("url", USER_REGISTER);

    this.setState({ openBackdrop: true });
    axios
      .post(USER_REGISTER, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          message: res.data.data,
          openSnack: true,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnack: true,
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    if (!error && e.target.type === "email") {
      if (!ValidateEmail(e.target.value)) {
        this.state.errors[e.target.name] = true;
        this.state.helpers[e.target.name] = "format email salah";
      }
    }

    if (e.target.name == "username") {
      const inputValueWithoutSpaces = e.target.value.replace(/\s/g, "");
      e.target.value = inputValueWithoutSpaces;
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Grid item sm />
        <Grid item sm>
          <Typography variant="h4" className={classes.pageTitle}>
            Tambah User
          </Typography>
          {/* <form id="form1" noValidate onSubmit={this.handleSubmit}> */}
          <form id="form1" name="form1" noValidate>
            <TextField
              id="email"
              name="email"
              type="email"
              variant="outlined"
              size="small"
              label="Email"
              className={classes.textField}
              helperText={this.state.helpers["email"]}
              error={this.state.errors["email"]}
              value={this.state.email}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="username"
              name="username"
              type="text"
              label="Username"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["username"]}
              error={this.state.errors["username"]}
              value={this.state.username}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="username"
              name="name"
              type="text"
              variant="outlined"
              size="small"
              label="Nama Lengkap"
              className={classes.textField}
              helperText={this.state.helpers["name"]}
              error={this.state.errors["name"]}
              value={this.state.name}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              type="password"
              label="Password"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["password"]}
              error={this.state.errors["password"]}
              value={this.state.password}
              onChange={this.handleChange}
              autoComplete="false"
              required
              fullWidth
            />
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Simpan
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserRegisterApp);
