import React, { Component, Fragment, useEffect}  from 'react'; 
import { Link,useHistory } from 'react-router-dom'; 
import axios from 'axios';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses }  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'; 
import Button from '@mui/material/Button'; 
import SearchBar from "material-ui-search-bar"; 
import CircularProgress from '@mui/material/CircularProgress';
import {BRIDGEGET,BASE_URL} from '../../common/constantVariable';
import Select from '@mui/material/Select';  
import MenuItem from '@mui/material/MenuItem'; 
import FormControl from '@mui/material/FormControl';  
import { GetItem,SaveItem } from '../../common/util';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
 

class ProseslistApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            allClosed : 0,
            searched : '',
            errormessage: '', 
            openSnack : false,
            isLoading : false,
            openSnackError : false,
            message: '',
        }
    } 
    
    componentDidMount() {
        axios.get('prosesdata/list_closing_periode')
            .then( (res) => { 
                this.setState({
                    list: res.data.data.list,
                    allClosed : res.data.data.allClosed
                })
            })
            .catch( (err) => { 
                this.setState({
                    list: []
                })
            });
    };


    handleNewProses = () => { 
    }

 


    render() {

        const { classes } = this.props;

        return (
          <Fragment>
             
            <div> 
            {
               this.state.allClosed === 0 ?  
               <Button variant="contained" color="primary" onClick={this.handleNewProses} component={Link} to="/prosesdata/register">
              Set Periode Closing Baru
                </Button> : '' }  
            </div><br></br>

            <StickyHeadTable list={this.state.list} showProgress={this.state.isLoading} />

          </Fragment>
        )

    }
}

export default ProseslistApp; 

const columns = [ 
  { id: 'tahun_laporan', label: 'Tahun Laporan', minWidth: 80 },  
  { id: 'nama_bulan', label: 'Bulan Laporan', minWidth: 120 },  
  { id: 'tgl_closing', label: 'Tanggal Proses', minWidth: 80 },    
  { id: 'closing_status_', label: 'Status', minWidth: 80 },    
]

const StickyHeadTable = ({list,isLoading}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);  
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]); 
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [uploadId, setUploadId] = React.useState("");
  const [openFormMulaiProses, setOpenFormMulaiProses] = React.useState("");
  const [openFormUlangiProses, setOpenFormUlangiProses] = React.useState("");

  
  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = list.filter((row) => {
      return (row.no_polis.toLowerCase().includes(searchedVal.toLowerCase())
      || row.client_name.toLowerCase().includes(searchedVal.toLowerCase()));
    }); 
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  
  let history = useHistory();
   

  const handleOpenFormMulaiProses = (closing_id) =>
  {
    setUploadId(closing_id);
    setOpenFormMulaiProses(true);
  }

  const handleProsesDataMulaiProses = () =>{
    const closing_id = GetItem('closing_id')
    handleCloseFormMulaiProses() 
    alert('Simpan data berhasil');
  }

  const handleCloseFormMulaiProses = () => {
    setOpenFormMulaiProses(false);
  };

  const handleOpenFormUlangiProses = (closing_id) =>
  {
    setUploadId(closing_id);
    setOpenFormUlangiProses(true);
  }

  const handleProsesDataUlangiProses = () =>{
    const closing_id = GetItem('closing_id')
    handleCloseFormUlangiProses() 
    alert('Simpan data berhasil');
  }

  const handleCloseFormUlangiProses = () => {
    setOpenFormUlangiProses(false);
  };

   
  const handleOpenClosing = (e) =>{
    const baseVal = e.target.value.split('-');
    const closing_id = baseVal[0];  
    SaveItem('closing_id',closing_id) 
      // alert(baseVal[1])
          SaveItem('bulan_laporan',baseVal[3]) 
          SaveItem('tahun_laporan',baseVal[4]) 
      if(baseVal[1] == 'lihat_data')
        { 
          if(baseVal[2] == "O")
          {
            history.push("/prosesdata/detailOpen");
          }else if(baseVal[2] == "C")
          {
            history.push("/prosesdata/detailProsesUlang");
          }else
          {
            history.push("/prosesdata/detailProses");
          }
        }else if(baseVal[1] == 'mulai_proses')
        { 
          history.push("/prosesdata/detailMulaiProses");
        }else if(baseVal[1] == 'ulang_proses')
        { 
          history.push("/prosesdata/detailProsesUlang");
        } 
    
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#cdcdcd',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

  return (
 
    <Paper sx={{ overflow: 'hidden' }}> 
    <Paper elevation={0} justifyContent="right">
      <SearchBar
          value={searched}
          onChange={(searchVal) => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          placeholder="cari nama file"
          style={{
            textAlign: 'left',
            margin: '0 auto',
            // maxWidth: 400,
          }}
        /> 
        </Paper><br></br>
        {showProgress &&  <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress 
              sx={{ mt: 3, ml: 1 }} color="secondary" /></div>}

         
        
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <StyledTableCell
                  key='no'
                  align='center'
                  style={{ minWidth: 40 }}
                >
                  No
                </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
                 <StyledTableCell
                  key='edit'
                  align='center'
                  style={{ minWidth: 30 }}
                >
                  Aksi
                </StyledTableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => { 
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key='no' align='center'>
                        {idx + 1 + page * rowsPerPage}    
                      </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];
                      

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key='edit' align='center'>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}> 
                          <Select value='aksi' onChange={handleOpenClosing} label='Aksi'>
                            <MenuItem value='aksi'>Aksi</MenuItem> 
                               <MenuItem value={row.closing_id+'-lihat_data-'+row.closing_status+'-'+row.nama_bulan+'-'+row.tahun_laporan}>Lihat Data</MenuItem> 
                                
                                {
                                row.closing_status === 'O' ?  
                                <MenuItem value={row.closing_id+'-mulai_proses-'+row.closing_status+'-'+row.nama_bulan+'-'+row.tahun_laporan}>Mulai Proses Data</MenuItem>
                                : ''
                                } 
                                 
                            
                          </Select></FormControl>
                      </TableCell> 
 

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

}









