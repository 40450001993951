import React, { Component, useState}  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
// import AppIcon  from '.\www14.png';
import axios from 'axios';
import {Link} from 'react-router-dom';

import Grid  from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import TextField  from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import {ValidateData, GetItem,} from '../../common/util';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';  

const styles = {
    form: {
        textAlign: 'center'
    },
    image: {
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '10px auto 10px auto'
    },
    button: {
        marginTop: 20
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    }
};


class MenuUpdateApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            role_id: this.props.match.params.role_id,
            user_id: this.props.match.params.user_id,             
            listUser : [], 
            listRole : [], 
            valid: false,
            helpers: [],
            errors: [],
            errormessage: '', 
            openSnackError : false,
            message: '',
        }
    }
    
    componentDidMount() { 
        axios.get('roleuser/initialData')
            .then ( result => { 
                console.log(result.data.errorcode)
                if (result.data.errorcode === 0) {
                    
                    this.setState({
                        listUser: result.data.data.listUser,
                        listRole: result.data.data.listRole
                    });
                }

            })
    }
 
    handleSubmit = () => {

        const e = document.getElementById("form1");

        const valid = ValidateData(e.elements);

        if (!valid) {
            this.setState({
                errormessage: "Data tidak lengkap"
            });    
            return false;
        }

        const data = {
            user_id: this.state.user_id,
            role_id: this.state.role_id,
            inserted_by : GetItem('user_id'),
        }

        axios.put('roleuser/role/'+this.state.user_id, data)
            .then((res) => { 

                if(res.data.message == 'fail')
                {
                    this.setState({
                        message : res.data.data,
                        openSnackError : true
                    })
                }

                this.setState({
                    loading: false
                })
                window.history.back(-1)
            })
            .catch( (err) => {
                this.setState({
                    errors      : err.response.data.data,
                    message     : err.response.data.data,
                    openSnackError   : true,
                    loading     : false
                })
            });
    }

    handleChange = (e) => {
        const error = e.target.required && e.target.value==='';

        this.state.errors[e.target.name] = error;
        this.state.helpers[e.target.name] =  error ? 'required' : '';
        
      
        this.setState({
            [e.target.name]: e.target.value,
            errormessage: '',
            errors: this.state.errors, 
            helpers: this.state.helpers
        })

    };

    render() { 
        
        const { classes } = this.props;
        const { errors, loading } = this.state;

        return (
            <Grid container className={classes.form} >
 
                <Snackbar open={this.state.openSnackError} autoHideDuration={3000} onClose={() => {this.setState({openSnackError:false});}}>
                    <Alert severity="error" sx={{ width: '100%' }} variant="filled" onClose={() => {this.setState({openSnackError:false})}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Grid item sm />
                <Grid item sm>
                    <Typography variant="h4" className={classes.pageTitle}>
                        Update Role User
                    </Typography>
                    {/* <form id="form1" noValidate onSubmit={this.handleSubmit}> */}
                    <form id="form1" name="form1" noValidate >
                    <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">User ID</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.user_id}
                                error={this.state.errors['user_id']}
                                onChange={this.handleChange}
                                size="small"
                                disabled
                                name="user_id"
                                label="ID User" 
                                > 
                                {this.state.listUser.map((dt,idx) => (
                                <MenuItem value={dt.user_id}>{dt.user_id}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                            <FormControl variant="outlined" fullWidth>
                            <InputLabel id="demo-simple-select-helper-label">Role User</InputLabel>
                                <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={this.state.role_id}
                                error={this.state.errors['role_id']}
                                onChange={this.handleChange}
                                size="small"
                                name="role_id"
                                label="Role User" 
                                > 
                                {this.state.listRole.map((role,idx) => (
                                <MenuItem value={role.role_id}>{role.role_name}</MenuItem>
                                ))}
                                </Select>
                            </FormControl>
 
                        <Typography variant='body2' className={classes.customError} >
                            {this.state.errormessage}
                        </Typography>

                        <Button variant='contained' color='primary' className={classes.button} onClick={this.handleSubmit}>
                            Simpan
                        </Button> &nbsp;&nbsp;&nbsp;

                        <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                            Batal
                        </Button>

                     </form>
                </Grid>
                <Grid item sm />
            </Grid>
        )
    }
}

export default withStyles(styles) (MenuUpdateApp);




