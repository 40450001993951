import React, { Fragment } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

import InputLabel from "@mui/material/InputLabel";
import { GetItem } from "../../../common/util";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import TextField from "@material-ui/core/TextField";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import "@fontsource/roboto/400.css";

class listProfitandLostDetailApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      messageError: "",
      openSnackError: false,
      periode_laporan: "",
      closing_id: "",
      jenis_laporan: "",
      Backdrop: false,
      cohort: "",
      message: "",
      btnDownloadExcel: "Download Excel",
      btnDownloadPdf: "Download PDF",
    };
  }

  componentDidMount() {
    axios
      .get("prosesdata/list_procress_done")
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          list: [],
        });
      });
  }

  handleDownloadExcel = () => {
    const tahun_laporan = this.state.closing_id;
    const jenis_laporan = this.state.jenis_laporan;
    const cohort = this.state.cohort;

    if (tahun_laporan === "") {
      this.setState({
        messageError: "Silakan pilih periode laporan terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    } else if (jenis_laporan === "") {
      this.setState({
        messageError: "Silakan pilih Model IFRS terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    } else if (cohort.trim() === "") {
      this.setState({
        messageError: "Silakan isi cohort terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    }
    var postData = new FormData();
    postData.append("closing_id", this.state.closing_id);
    postData.append("jenis_laporan", this.state.jenis_laporan);
    postData.append("cohort", this.state.cohort);
    postData.append("user_id", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("downloadReport/download_report_pl_detail", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4">Report Profit and Lost Detail</Typography>
        <br></br>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.messageError}
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item sm>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Pilih Periode Laporan
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.closing_id}
                    onChange={this.handleChange}
                    size="small"
                    label="Pilih Periode Laporan"
                    name="closing_id"
                  >
                    {this.state.list.map((lst, idx) => (
                      <MenuItem value={lst.closing_id}>
                        {lst.tahun_laporan}-{lst.nama_bulan}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Pilih Model IFRS
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.jenis_laporan}
                    onChange={this.handleChange}
                    size="small"
                    label="Pilih Model IFRS"
                    name="jenis_laporan"
                  >
                    <MenuItem value="GMM">GMM</MenuItem>
                    <MenuItem value="PAA">PAA</MenuItem>
                    <MenuItem value="VFA">VFA</MenuItem>
                    <MenuItem value="LIC">LIC</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    id="cohort"
                    name="cohort"
                    type="text"
                    label="Cohort"
                    variant="outlined"
                    size="small"
                    value={this.state.nocohort_polis}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownloadExcel}
                >
                  {this.state.btnDownloadExcel}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default listProfitandLostDetailApp;
