import React, { Component, useState}  from 'react';
import axios from 'axios';

import StickyHeadTable from '../components/StickyHeadTable';
import { Thermostat } from '@mui/icons-material';


class AboutApp extends React.Component {

    columns = [
      { id: 'name', label: 'Name', minWidth: 170 },
      { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
      {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toLocaleString('en-US'),
      },
      {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value) => value.toFixed(2),
      },
    ];

    constructor(props) {
        super(props);
        this.state = {
            total: '0',
            listuser: [],
        }
    }
    
    componentDidMount() {
        // axios.get('/pub/user/list/36')
        //     .then( (res) => {
        //         this.setState({
        //             listuser: res.data.data
        //         })
        //     })
        //     .catch( (err) => {
        //         console.error(err.message);
        //         this.setState({
        //             listuser: []
        //         })
        //     });
    }

    handleSubmit = (e) => {
      e.preventDefault();

      let f = e.target;

      // console.log(this.state);

      axios.get(`/pub/user/list/${this.state.total}`)
            .then( (res) => {
                this.setState({
                    listuser: res.data.data
                })
            })
            .catch( (err) => {
                console.error(err.message);
                this.setState({
                    listuser: []
                })
            });

  }

  handleChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })

  };

    
    render() {

        const { classes } = this.props;

        return (
          <div>
            <form noValidate onSubmit={this.handleSubmit} >
              <input type='text' id="total" name="total"  onChange={this.handleChange} />
              <button type='submit'>Get !!!</button>
            </form>

            <StickyHeadTable listuser={this.state.listuser} columns={this.columns} />
          </div>
        )

    }
}

export default AboutApp;

