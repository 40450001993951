import React, { Component, useState}  from 'react';
import withStyles from '@material-ui/core/styles/withStyles'; 
import axios from 'axios';
    
import Grid  from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import TextField  from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import {ValidateData, GetItem} from '../../../common/util';
import {BRIDGEGET,BRIDGEPUT,BASE_URL} from '../../../common/constantVariable'; 

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'; 
   
const styles = {
    form: {
        textAlign: 'center'
    },
    image: {
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '5px auto 5px auto'
    },
    button: {
        marginTop: 20
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    }
};


class UserUpdateApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            CODE: GetItem('CODE'), 
            PL_DESC: '',  
            PL_REPORT: '',  
            PL_MODEL: '',  
            PL_DESC_PARTNER: '',  
            valid: false,
            helpers: [],
            errors: [],
            errormessage: '', 
            openSnack : false, 
            openSnackError : false,
            Backdrop : false,
            message: '',
            inserted_by : GetItem('user_id'),
        } 
    }
     
    componentDidMount() {
        this.setState({openBackdrop:true})
        axios.get('setupparameter/list_profitandlost_param/' + this.state.CODE)
            .then ( result => { 
                this.setState({openBackdrop:false})
                if (Object.keys(result.data.data).length>0) {
                    const user = result.data.data[0];  
                    this.setState({ 
                        PL_DESC: user.PL_DESC, 
                        PL_REPORT: user.PL_REPORT,
                        PL_MODEL: user.PL_MODEL,
                        PL_DESC_PARTNER: user.PL_DESC_PARTNER,
                    });
                }
            })
    }

    handleSubmit = () => {

        
        const e = document.getElementById("form1");
        

        if (ValidateData(e.elements)===false) {
            this.setState({
                errormessage: "Data tidak lengkap"
            });    
            return false;
        }
 
        this.setState({openBackdrop:true})
        axios.put('setupparameter/paramprofitandlost/'+this.state.CODE, this.state)
        .then((res) => {
                this.setState({openBackdrop:false})
  
                if(res.data.message != 'success'){
                    this.setState({
                        message : res.data.data,
                        openSnackError : true
                    })
                }else
                {
                    this.setState({
                        message : res.data.data,
                        openSnack : true
                    })
                } 
            })
            .catch( (err) => {
                this.setState({openBackdrop:false})
                this.setState({
                    errormessage: 'Gagal simpan data'
                })
            });
        
    }

    handleChange = (e) => {
        const error = e.target.required && e.target.value==='';

        this.state.errors[e.target.name] = error;
        this.state.helpers[e.target.name] =  error ? 'required' : '';
        
       

        this.setState({
            [e.target.name]: e.target.value,
            errormessage: '',
            errors: this.state.errors, 
            helpers: this.state.helpers
        })

    };

    handleClose = (event, reason) => {
        this.setState({openSnack:false});
        if (reason === 'timeout') {
            window.history.back(-1);
          }
      };
    

    render() { 
        const { classes } = this.props;
        const { errors, loading } = this.state;

        return (
            <Grid container className={classes.form} >
           <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.openBackdrop}
                onClick={this.handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
           
                <Snackbar open={this.state.openSnack} autoHideDuration={1000} 
                    onClose={(event, reason) => this.handleClose(event, reason)}>
                    <Alert severity="success" sx={{ width: '100%' }} variant="filled" 
                    onClose={() => {this.setState({openSnack:false}); window.history.back(-1)}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Snackbar open={this.state.openSnackError} autoHideDuration={2000} >
                    <Alert severity="error" sx={{ width: '100%' }} variant="filled" 
                    onClose={() => {this.setState({openSnackError:false})}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>

                <Grid item sm />
                <Grid item sm>
                    
                    <Typography variant="h6" className={classes.pageTitle}>
                        Update Lapse Benefit
                    </Typography>

                    <form id="form1" name="form1" noValidate >
                    <TextField
                                        id="PL_CODE" name="PL_CODE"  
                                        type="text"
                                        label="Profit and Lost Code"
                                        size='small'
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                        variant='filled'
                                        className={classes.textField} 
                                        value={this.state.CODE}
                                        onChange={this.handleChange}
                                        required fullWidth />
                                    <TextField
                                        id="PL_DESC" name="PL_DESC"  
                                        type="text"
                                        label="Desc Default"
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                        size='small'
                                        variant='filled'
                                        className={classes.textField} 
                                        value={this.state.PL_DESC}
                                        onChange={this.handleChange}
                                        required fullWidth />
                                        
                                    <TextField
                                        id="PL_MODEL" name="PL_MODEL"  
                                        type="text"
                                        label="Model"
                                        InputProps={{
                                            readOnly: true,
                                          }}
                                        size='small'
                                        variant='filled'
                                        className={classes.textField} 
                                        value={this.state.PL_MODEL}
                                        onChange={this.handleChange}
                                        required fullWidth />

                                    <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Report Type</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.PL_REPORT} 
                                        onChange={this.handleChange}
                                        size="small" 
                                        name="PL_REPORT"
                                        label="Report Type" 
                                        > 
                                        <MenuItem value="S2">S2 - Heading</MenuItem>
                                        <MenuItem value="S1">S1 - Sub Heading</MenuItem>
                                        <MenuItem value="D">D - Detail</MenuItem>
                                        
                                        </Select>
                                    </FormControl> 
 
                                            <TextField
                                                id="PL_DESC_PARTNER" name="PL_DESC_PARTNER"  
                                                type="text"
                                                label="Desc Partner"
                                                size='small'
                                                variant='outlined'
                                                className={classes.textField} 
                                                value={this.state.PL_DESC_PARTNER}
                                                onChange={this.handleChange}
                                                required fullWidth /> 


                        <Typography variant='body2' className={classes.customError} >
                            {this.state.errormessage}
                        </Typography>

                        <Button variant='contained' color='primary' className={classes.button} onClick={this.handleSubmit}>
                            Simpan
                        </Button> &nbsp;&nbsp;&nbsp;

                        <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                            Batal
                        </Button>

                     </form>
                </Grid>
                <Grid item sm />
            </Grid>
        )
    }
}

export default withStyles(styles) (UserUpdateApp);




