import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { Switch, useHistory } from "react-router";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { GetItem, SaveItem } from "../../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Link } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class MenuRegisterApp extends React.Component {
  constructor(props) {
    super(props);
    this.cohortCheckboxRef = React.createRef();

    this.state = {
      PERIOD_DATA: GetItem("PERIOD_DATA"),
      VALUATION_DATA: "",
      closing_period: "",
      valuation_date: "",
      prm_model: "",
      rate: "",
      cohort: "",
      showButtonDetail: false,
      list_cohort: [],
      list_rate: [],

      checked: [],
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
      statusChecklist: false,
    };
  }

  componentDidMount() {
    this.setState({ prm_model: "", showButtonDetail: false });
    this.setState({ openBackdrop: true });
    axios.get("monitorCohort/" + this.state.PERIOD_DATA).then((result) => {
      this.setState({ openBackdrop: false });
      if (result.data.errorcode === 0) {
        this.setState({
          list_cohort: result.data.data.listCohort,
          list_rate: result.data.data.listAsumptionRate,
          VALUATION_DATA: result.data.data.listCohort[0].VALUATION_DATA,
        });
      }
    });
  }

  handleSubmit = () => {
    if (this.state.rate === "" || this.state.checked.length < 1) {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }

    const dt = JSON.stringify(this.state.checked);

    var postData = new FormData();
    postData.append("rate", this.state.rate);
    postData.append("period_data", this.state.PERIOD_DATA);
    postData.append("cohort", dt);
    postData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("monitorCohort/register_asumption_rate", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });

        if (res.data.message === "fail") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          loading: false,
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    this.setState({
      showButtonDetail: true,
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleChangeCohort = (e) => {
    if (e.target.checked) {
      this.setState({ checked: [...this.state.checked, e.target.value] });
    } else {
      const data = this.state.checked.filter((item) => item !== e.target.value);
      this.setState({ checked: data });
    }
  };

  handleChangeJenisModel = (event, newValue) => {
    const value = newValue.PRM_MODEL;
    if (value === "") {
      this.setState({ rate: "" });
    } else {
      this.setState({ rate: value });
    }
  };
  checklistSemua = (e) => {
    if (!this.state.statusChecklist) {
      const allCohorts = this.state.list_cohort.map(
        (cohort) =>
          cohort.JENIS + "#" + cohort.COHORT + "#" + cohort.PERIOD_DATA
      );
      this.setState({ checked: allCohorts, statusChecklist: true });
    } else {
      this.setState({ checked: [], statusChecklist: false });
    }
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  saveItem = (e) => {
    SaveItem("prm_model", this.state.rate);
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Paper
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Grid container rowSpacing={0.1}>
                <br></br>
                <Grid item xs={2}>
                  Periode Data
                </Grid>
                <Grid item xs={10}>
                  : {this.state.PERIOD_DATA}
                </Grid>
                <Grid item xs={2}>
                  Valuation Data
                </Grid>
                <Grid item xs={10}>
                  : {this.state.VALUATION_DATA}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Setup Asumption Rate
              </Typography>
              <form id="form1" name="form1" noValidate>
                {" "}
                <br></br>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={this.state.list_rate}
                    className={classes.pageTitle}
                    onChange={this.handleChangeJenisModel}
                    name="rate"
                    getOptionLabel={(option) => option.PRM_MODEL}
                    value={
                      this.state.list_rate.find(
                        (option) => option.PRM_MODEL === this.state.rate
                      ) || null
                    }
                    style={{ width: 380, alignItems: true }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asumption Rate"
                        variant="outlined"
                      />
                    )}
                  />
                  {this.state.showButtonDetail && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: 380, marginLeft: 40 }}
                      onClick={this.saveItem()}
                      className={classes.button}
                      component={Link}
                      to="/prosesdata/detailAsumptionRate"
                    >
                      Detail Asumption Rate
                    </Button>
                  )}{" "}
                  <br></br>
                  <Typography variant="h6" className={classes.pageTitle}>
                    Pilih Cohort
                  </Typography>
                  <FormGroup style={{ width: 1100, marginLeft: -30 }}>
                    {this.state.list_cohort.map((cohort, idx) => (
                      <FormControlLabel
                        key={idx}
                        control={
                          <Checkbox
                            checked={this.state.checked.includes(
                              cohort.JENIS +
                                "#" +
                                cohort.COHORT +
                                "#" +
                                cohort.PERIOD_DATA
                            )}
                            onChange={this.handleChangeCohort}
                            name="cohort"
                            value={
                              cohort.JENIS +
                              "#" +
                              cohort.COHORT +
                              "#" +
                              cohort.PERIOD_DATA
                            }
                          />
                        }
                        label={
                          cohort.COHORT +
                          " ( " +
                          cohort.PRODUCT_NAME +
                          " ISSUED " +
                          cohort.PERIOD_DATA +
                          " TYPE Cohort " +
                          cohort.COHORT_TYPE +
                          " ). Mapping Existing : " +
                          (cohort.ASUMPTION_RATE === null
                            ? ""
                            : cohort.ASUMPTION_RATE)
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </form>
              <Button
                style={{ marginLeft: -20 }}
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.checklistSemua}
              >
                Checklist Semua
              </Button>{" "}
              &nbsp;
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={this.handleSubmit}
              >
                Simpan
              </Button>{" "}
              &nbsp;
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => {
                  window.history.back(-1);
                }}
              >
                Batal
              </Button>
            </Grid>
            <Grid item sm />
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(MenuRegisterApp);
