import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Aside from './Aside';
import Main from './Main';
import axios from 'axios';
import LoginApp from '../pages/login';
import { GetItem, ClearSession } from '../common/util';
import { bgcolor } from '@mui/system';

function Layout({ setLocale }) {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);

  const [token, setToken] = useState(GetItem('token'));
  
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    setLocale(checked ? 'ar' : 'en');
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleLogin = (token) => {
    setToken(token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  const handleLogout = () => {
    setToken(null);
    ClearSession();
    axios.defaults.headers.common['Authorization'] = '';
  }

  if (token) {

    return (
      <Router>
        <div style={{backgroundColor:'#F2F2F2'}} className={`app ${rtl ? 'rtl' : ''} ${toggled ? 'toggled' : ''}`}>
          <Aside
            image={image}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleLogout={handleLogout}
          />
          <Main
            image={image}
            toggled={toggled}
            collapsed={collapsed}
            rtl={rtl}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
            handleRtlChange={handleRtlChange}
            handleImageChange={handleImageChange}
          />
        </div>
      </Router>
    )
  } else {
    return (
      <LoginApp handleLogin={handleLogin} />
    )
  }
}

export default Layout;
