import React from "react";
import axios from "axios";
import { styled } from "@mui/material/styles";
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";

import Box from "@mui/material/Box";
import { BRIDGEGET, BASE_URL, BRIDGEDELETE } from "../common/constantVariable";
import Title from "../components/title";

const styles = {
  form: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  bgColorGrey: {
    backgroundColor: "#cdcdcd",
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default class HomeApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      closing_status: "",
      bulan_laporan: "",
      tahun_laporan: "",
      valid: false,
      messageError: "",
      openSnackError: false,
      message: "",
      showForm: false,
      openSnack: false,
      total_record: "0",
      total_gmm: "0",
      total_vfa: "0",
      total_paa: "0",
      total_lic: "0",
      total_reasgmm: "0",
      total_reaspaa: "0",

      total_record_done: "0",
      total_gmm_done: "0",
      total_vfa_done: "0",
      total_paa_done: "0",
      total_lic_done: "0",
      total_reaspaa_done: "0",
      total_reasgmm_done: "0",

      disabled_option: false,
      btnWording: "Simpan",
      total_period_close: 0,
      total_period: 0,
    };
  }

  componentDidMount() {
    axios
      .get(BASE_URL + "dataSummary/lastSummaryRecord")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          bulan_laporan: res.data.data.list.nama_bulan,
          tahun_laporan: res.data.data.list.tahun_laporan,
          closing_status: res.data.data.list.closing_status,
          total_record: res.data.data.list.total_record,
          total_gmm: res.data.data.list.total_gmm,
          total_vfa: res.data.data.list.total_vfa,
          total_paa: res.data.data.list.total_paa,
          total_lic: res.data.data.list.total_lic,

          total_period: res.data.data.total_status.total_period,
          total_period_close: res.data.data.total_status.total_period_close,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <div id="content">
        <Grid item xs={12}>
          <Typography variant="h6">
            Selamat datang di INSURA17 - IFRS (International Financial Reporting
            Standard) 17 Aplication
          </Typography>
        </Grid>
        <br></br>
        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item sm={8}>
              <Paper
                sx={{
                  p: 2,
                  height: 170,
                }}
              >
                <span>Last Month end Closing Period</span>
                <span>
                  : {this.state.tahun_laporan} - {this.state.bulan_laporan}
                </span>
                <br></br>
                <span>
                  Status
                  Process&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.closing_status}</span>
                <br></br>
                <span>
                  Total
                  Record&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_record}</span>
                <br></br>
                <span>
                  Total
                  GMM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_gmm}</span>
                <br></br>
                <span>
                  Total
                  VFA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_vfa}</span>
                <br></br>
                <span>
                  Total
                  PAA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_paa}</span> <br></br>
                <span>
                  Total
                  LIC&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_lic}</span>
                {/* <br></br>
                <span>
                  Total
                  REASGMM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_reasgmm}</span>
                <br></br>
                <span>
                  Total
                  REASPAA&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                <span>: {this.state.total_reaspaa}</span> */}
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 170,
                }}
              >
                <Title>Total Status Close</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_period_close} dari {this.state.total_period}{" "}
                  Periode
                </Typography>
                {/* <Box sx={{ width: '100%', marginTop:11 }}>
                                                    <LinearProgressWithLabel value='70' />
                                                </Box> */}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
