import React from "react";
import { Route, Switch } from "react-router-dom";

import PLListApp from "./listpl";
import profitandloss_detail from "./profitandloss_detail";

export default class ProsesDataApp extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/reportprofitloss/detail"
          component={profitandloss_detail}
        />
        <Route exact path="/reportprofitloss/**" component={PLListApp} />
      </Switch>
    );
  }
}
