import React from "react";
import { Route, Switch } from "react-router-dom";

import ProseslistApp from "./list";
import listPeriodeClosing from "./listPeriodeClosing";
import ProsesRegisterApp from "./register";
import ProsesUploadApp from "./registerUpload";
// import DetailOpenApp from './detailOpen';
import DetailProsesApp from "./detailProses";
import detailMulaiProsesApp from "./detailMulaiProses";
import detailProsesUlangApp from "./detailProsesUlang";
import cekKualitasData from "./cekKualitasData";
import uploadData from "./uploadData";
import penggabunganTransaksi from "./penggabungan_transaksi/list";
import uploadAktualTransaksi from "./penggabungan_transaksi/uploadAktualTransaksi";
import tambahAdjustment from "./tambahAdjustment";
import detailKualitasData from "./detailKualitasData";
import monitorProses from "./monitorProses";
import prosesUlang from "./prosesUlang";
import detailMonitorCohort from "./monitorCohort/detailMonitorCohort";
import setupMortalityRateCohort from "./monitorCohort/mortalityrate/setupMortailtyRate";
import detailMortalityRate from "./monitorCohort/mortalityrate/detailMortalityRate";

import setupRider1RateCohort from "./monitorCohort/rider1rate/setupRider1Rate";
import detailRider1Rate from "./monitorCohort/rider1rate/detailRider1Rate";

import setupRider2RateCohort from "./monitorCohort/rider2rate/setupRider2Rate";
import detailRider2Rate from "./monitorCohort/rider2rate/detailRider2Rate";

import setupRider3RateCohort from "./monitorCohort/rider3rate/setupRider3Rate";
import detailRider3Rate from "./monitorCohort/rider3rate/detailRider3Rate";

import setupLapseRateCohort from "./monitorCohort/lapserate/setupLapseRate";
import detailLapseRate from "./monitorCohort/lapserate/detailLapseRate";

import setupAsumptionRateCohort from "./monitorCohort/asumptionrate/setupAsumptionRate";
import detailAsumptionRate from "./monitorCohort/asumptionrate/detailAsumptionRate";

export default class ProsesDataApp extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/saldoawaltransisi/detailMulaiProses"
          component={detailMulaiProsesApp}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailProsesUlang"
          component={detailProsesUlangApp}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailProses"
          component={DetailProsesApp}
        />
        <Route exact path="/saldoawaltransisi/detailOpen" component={uploadData} />
        <Route
          exact
          path="/saldoawaltransisi/register"
          component={ProsesRegisterApp}
        />
        <Route
          exact
          path="/saldoawaltransisi/tambahAdjustment"
          component={tambahAdjustment}
        />
        <Route exact path="/saldoawaltransisi/upload" component={ProsesUploadApp} />
        <Route
          exact
          path="/saldoawaltransisi/penggabungan_transaksi"
          component={penggabunganTransaksi}
        />
        <Route
          exact
          path="/saldoawaltransisi/penggabungan_transaksi/upload"
          component={uploadAktualTransaksi}
        />

        <Route
          exact
          path="/saldoawaltransisi/listPeriodeClosing"
          component={listPeriodeClosing}
        />
        <Route exact path="/saldoawaltransisi/uploadData" component={uploadData} />
        <Route
          exact
          path="/saldoawaltransisi/cekKualitasData"
          component={cekKualitasData}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailKualitasData"
          component={detailKualitasData}
        />
        <Route
          exact
          path="/saldoawaltransisi/monitorProses"
          component={monitorProses}
        />
        <Route exact path="/saldoawaltransisi/prosesUlang" component={prosesUlang} />
        <Route
          exact
          path="/saldoawaltransisi/monitorCohort"
          component={detailMonitorCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailMonitorCohort"
          component={detailMonitorCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/setupMortalityRateCohort"
          component={setupMortalityRateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailMortalityRate"
          component={detailMortalityRate}
        />

        <Route
          exact
          path="/saldoawaltransisi/setupLapseRateCohort"
          component={setupLapseRateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailLapseRate"
          component={detailLapseRate}
        />

        <Route
          exact
          path="/saldoawaltransisi/setupAsumptionRateCohort"
          component={setupAsumptionRateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailAsumptionRate"
          component={detailAsumptionRate}
        />

        <Route
          exact
          path="/saldoawaltransisi/setupRider1RateCohort"
          component={setupRider1RateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/setupRider2RateCohort"
          component={setupRider2RateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/setupRider3RateCohort"
          component={setupRider3RateCohort}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailRider1Rate"
          component={detailRider1Rate}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailRider2Rate"
          component={detailRider2Rate}
        />
        <Route
          exact
          path="/saldoawaltransisi/detailRider3Rate"
          component={detailRider3Rate}
        />

        <Route exact path="/saldoawaltransisi/**" component={ProseslistApp} />
      </Switch>
    );
  }
}
