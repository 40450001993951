import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import SearchBar from "material-ui-search-bar";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { SaveItem, GetItem } from "../../../common/util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@mui/material/Backdrop";

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      jenis_model: "",
      message: "",
      openBackdrop: false,
    };
  }

  componentDidMount() {}

  handleChangeJenisModel = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  setDataModel = () => {
    let jenis_model = this.state.jenis_model;
    this.setState({ openBackdrop: true });
    axios
      .get(BASE_URL + "setupparameter/list_accounting/" + jenis_model)
      .then((res) => {
        this.setState({
          list: res.data.data,
          openBackdrop: false,
        });
      })
      .catch((err) => {
        this.setState({
          list: [],
          openBackdrop: false,
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={this.handleNewProses}
            component={Link}
            to="/setup/journal/register"
          >
            Tambah Journal
          </Button> */}
        </div>
        <br></br>
        <Grid container>
          <Grid item sm>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Pilih Model
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.jenis_model}
                    onChange={this.handleChangeJenisModel}
                    size="small"
                    label="Pilih Jenis Model"
                    name="jenis_model"
                  >
                    <MenuItem value="GENERAL">GENERAL</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.setDataModel}
                >
                  Cari
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br></br>

        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
        />
      </Fragment>
    );
  }
}

export default ProseslistApp;

const columns = [
  { id: "SORT", label: "Sort", minWidth: 80 },
  { id: "ACCT_CODE", label: "ACCT Code", minWidth: 80 },
  { id: "ACCT_DESC", label: "Account Description", minWidth: 120 },
  { id: "ACCT_CATEGORY", label: "Category", minWidth: 120 },
  { id: "CR_OR_DR", label: "CR / DR", minWidth: 80 },
  { id: "ACCT_MODEL", label: "Account Model", minWidth: 80 },
  { id: "COA_PARTNER", label: "ACCT Code Partner", minWidth: 80 },
  { id: "acct_desc2", label: "Account Partner Description", minWidth: 80 },
];

const StickyHeadTable = ({ list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const deleteData = () => {
    axios
      .delete(
        "setupparameter/accounting/" +
          GetItem("ACCT_MODEL") +
          "/" +
          GetItem("ACCT_CODE")
      )
      .then((res) => {
        setRows([]);
        setOpenDialog(false);
        axios
          .get(BASE_URL + "setupparameter/list_accounting")
          .then((res) => {
            setRows(res.data.data);
          })
          .catch((err) => {
            setOpenDialog(false);
          });
      })
      .catch((err) => {
        setOpenDialog(false);
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("-");
    const ACCT_CODE = baseVal[0];
    const ACCT_MODEL = baseVal[1];
    SaveItem("ACCT_CODE", ACCT_CODE);
    SaveItem("ACCT_MODEL", ACCT_MODEL);
    if (baseVal[2] == "update") {
      history.push("/setup/journal/update");
    } else if (baseVal[2] == "delete") {
      setOpenDialog(true);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin ingin menghapus data ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Batal</Button>
          <Button onClick={deleteData} autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem
                            value={
                              row.ACCT_CODE + "-" + row.ACCT_MODEL + "-update"
                            }
                          >
                            Ubah Parameter
                          </MenuItem>
                          {/* <MenuItem
                            value={
                              row.ACCT_CODE + "-" + row.ACCT_MODEL + "-delete"
                            }
                          >
                            Hapus Parameter
                          </MenuItem> */}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
