// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/bg_login.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container {\r\n    height: 100%;\r\n    position: relative;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n\r\n.vertical-center {\r\n    margin: 0;\r\n    width: 100%;\r\n    position: absolute;\r\n    top: 30%;\r\n    transform: translateY(-50%);\r\n}\r\n.recaptcha-container {\r\n    display: inline-grid;\r\n    align-items: center;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,yDAA+C;AACnD;;AAEA;IACI,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,QAAQ;IAER,2BAA2B;AAC/B;AACA;IACI,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".container {\r\n    height: 100%;\r\n    position: relative;\r\n    background-image: url(\"../assets/bg_login.jpg\");\r\n}\r\n\r\n.vertical-center {\r\n    margin: 0;\r\n    width: 100%;\r\n    position: absolute;\r\n    top: 30%;\r\n    -ms-transform: translateY(-50%);\r\n    transform: translateY(-50%);\r\n}\r\n.recaptcha-container {\r\n    display: inline-grid;\r\n    align-items: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
