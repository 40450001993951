import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {
  ValidateData,
  ValidateDataEmail,
  ValidateEmail,
  GetItem,
} from "../../common/util";
import { BRIDGEGET, BRIDGEPUT, BASE_URL } from "../../common/constantVariable";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "5px auto 5px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class UserUpdateApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      host: "",
      port: "",
      protocol: "",
      username: "",
      name: "",
      smtp_crypto: "",
      password: "",
      sender: "",
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
      showPassword: false,
    };
  }

  handleClickShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({
        showPassword: false,
      });
    } else {
      this.setState({
        showPassword: true,
      });
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  componentDidMount() {
    this.setState({ openBackdrop: true });
    this.getData();
  }

  getData = () => {
    axios.get(BASE_URL + "configmail/").then((result) => {
      this.setState({ openBackdrop: false });
      if (Object.keys(result.data.data).length > 0) {
        const data = result.data.data;
        this.setState({
          protocol: data.PROTOCOL,
          host: data.HOST,
          port: data.PORT,
          username: data.USERNAME,
          password: data.PASSWORD,
          sender: data.SENDER,
          smtp_crypto: data.SMTP_CRYPTO,
        });
      }
    });
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");

    if (ValidateData(e.elements) === false) {
      this.setState({
        openSnackError: true,
        message: "Data tidak lengkap",
      });
      return false;
    }

    if (ValidateDataEmail(e.elements) === false) {
      this.setState({
        openSnackError: true,
        message: "Format Email salah",
      });
      return false;
    }

    this.setState({ openBackdrop: true });
    axios
      .put("configmail/mail/" + this.state.host, this.state)
      .then((res) => {
        this.setState({ openBackdrop: false });

        if (res.data.message != "success") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
          this.getData();
        } else {
          this.setState({
            message: res.data.data,
            openSnack: true,
          });
          this.getData();
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errormessage: "Gagal simpan data",
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    if (!error && e.target.type === "email") {
      if (!ValidateEmail(e.target.value)) {
        this.state.errors[e.target.name] = true;
        this.state.helpers[e.target.name] = "format email salah";
      }
    }

    if (e.target.name == "username") {
      const inputValueWithoutSpaces = e.target.value.replace(/\s/g, "");
      e.target.value = inputValueWithoutSpaces;
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Grid item sm />
        <Grid item sm>
          <Typography variant="h6" className={classes.pageTitle}>
            Setup SMTP Mail
          </Typography>

          <form id="form1" name="form1" noValidate>
            <TextField
              id="protocol"
              name="protocol"
              type="text"
              label="Protocol Mail Server"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["protocol"]}
              error={this.state.errors["protocol"]}
              value={this.state.protocol}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="host"
              name="host"
              type="text"
              label="Host Mail Server"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["host"]}
              error={this.state.errors["host"]}
              value={this.state.host}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="port"
              name="port"
              type="number"
              variant="outlined"
              size="small"
              label="Port"
              className={classes.textField}
              helperText={this.state.helpers["port"]}
              error={this.state.errors["port"]}
              value={this.state.port}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                SMTP Crypto
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                required
                value={this.state.smtp_crypto}
                onChange={this.handleChange}
                error={this.state.errors["smtp_crypto"]}
                size="small"
                name="smtp_crypto"
                label="SMTP Crypto"
              >
                <MenuItem value="tls">TLS</MenuItem>
                <MenuItem value="ssl">SSL</MenuItem>
              </Select>
            </FormControl>
            {/* <TextField
              id="smtp_crypto"
              name="smtp_crypto"
              type="text"
              variant="outlined"
              size="small"
              label="smtp_crypto"
              className={classes.textField}
              helperText={this.state.helpers["smtp_crypto"]}
              error={this.state.errors["smtp_crypto"]}
              value={this.state.smtp_crypto}
              onChange={this.handleChange}
              required
              fullWidth
            /> */}
            <TextField
              id="sender"
              name="sender"
              type="text"
              label="Sender"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["sender"]}
              error={this.state.errors["sender"]}
              value={this.state.sender}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="username"
              name="username"
              type="text"
              label="Username"
              variant="outlined"
              size="small"
              className={classes.textField}
              helperText={this.state.helpers["username"]}
              error={this.state.errors["username"]}
              value={this.state.username}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="password"
              name="password"
              variant="outlined"
              size="small"
              label="Password"
              className={classes.textField}
              helperText={this.state.helpers["password"]}
              error={this.state.errors["password"]}
              value={this.state.password}
              onChange={this.handleChange}
              autoComplete="false"
              fullWidth
              type={this.state.showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Update Config
            </Button>{" "}
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserUpdateApp);
