import React, { Component, Fragment, useEffect } from "react";
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { MENU_LIST } from "../../common/constantVariable";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GetItem, SaveItem, RemoveItem } from "../../common/util";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class MenuListApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      errormessage: "",
      openDialog: false,
      openSnack: false,
      openSnackError: false,
      openBackdrop: false,
      message: "",
    };
  }

  componentDidMount() {
    axios
      .get(MENU_LIST)
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err.message);
        this.setState({
          list: [],
        });
      });
  }

  handleDelete = (id) => {
    SaveItem("delete_id", id);
    this.setState({ openDialog: true });
  };

  deleteData = () => {
    this.setState({ openBackdrop: true, openDialog: false });
    axios
      .delete("menu/" + GetItem("delete_id"))
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message === "success") {
          const data = this.state.list.filter(
            (item) => item.menu_id !== GetItem("delete_id")
          );
          this.setState({
            list: data,
          });
          RemoveItem("delete_id");
        }
      })
      .catch((err) => {});
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/setupmenu/register"
          >
            Tambah Menu
          </Button>
        </div>
        <br></br>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda yakin ingin menghapus data ini ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Batal</Button>
            <Button onClick={this.deleteData} autoFocus>
              Hapus
            </Button>
          </DialogActions>
        </Dialog>

        <StickyHeadTable
          openBackdrop={this.state.openBackdrop}
          list={this.state.list}
          handleDelete={this.handleDelete}
          classes={classes}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(MenuListApp);

const columns = [
  { id: "menu_id", label: "ID Menu", minWidth: 80 },
  { id: "parent_menu_name", label: "Nama Menu Induk", minWidth: 80 },
  { id: "menu_name", label: "Nama Menu", minWidth: 80 },
  { id: "menu_link", label: "Link Menu", minWidth: 80 },
  { id: "sort_id", label: "Urutan", minWidth: 80 },
];

const StickyHeadTable = ({ openBackdrop, list, handleDelete, classes }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [searched, setSearched] = React.useState("");

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  // const {list, handleDelete} = props;

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleSelectOption = (e) => {
    const value = e.target.value;
    if (value === "reset") {
      setKeyword_cari("");
      setSearched("");
      requestSearch("");
    } else {
      setKeyword_cari(value);
    }
  };

  const requestSearch = (searchedVal) => {
    if (keyword_cari !== "") {
      const filteredRows = list.filter((row) => {
        if (keyword_cari === "id_menu") {
          return row.menu_id
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        } else if (keyword_cari === "nama_menu_induk") {
          return row.parent_menu_name
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        } else if (keyword_cari === "nama_menu") {
          return row.menu_name
            .toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        }
      });
      setRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    setKeyword_cari("");
    requestSearch(searched);
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Cari Berdasarkan
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={keyword_cari}
              onChange={handleSelectOption}
              size="small"
              name="keyword_cari"
              label="Cari Berdasarkan"
            >
              <MenuItem value="id_menu">ID Menu</MenuItem>
              <MenuItem value="nama_menu_induk">Nama Menu Induk</MenuItem>
              <MenuItem value="nama_menu">Nama Menu</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <SearchBar
            className={classes.search}
            value={searched}
            name="searched"
            onChange={(searchedVal) => requestSearch(searchedVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="cari disini.."
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Edit
              </StyledTableCell>
              <StyledTableCell
                key="delete"
                align="center"
                style={{ minWidth: 30 }}
              >
                Hapus
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                // console.log(row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <Link to={`/setupmenu/edit/${row.menu_id}`}>edit</Link>
                    </TableCell>

                    <TableCell key="delete" align="center">
                      <Link
                        to="#"
                        onClick={() => {
                          handleDelete(row.menu_id);
                        }}
                      >
                        Hapus
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 75, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
