import React, { Component, Fragment, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { GetItem, SaveItem } from "../../../common/util";
import Paper from "@mui/material/Paper";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import { Link, useHistory } from "react-router-dom";
import {
  BRIDGEGET,
  BASE_URL,
  MULAI_PROSES,
  BRIDGEPOST,
} from "../../../common/constantVariable";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const styles = {
  form: {
    textAlign: "left",
  },
  pageTitle: {
    textAlign: "center",
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class detailMulaiProsesApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      PERIOD_DATA: "",
      valid: false,
      listCohort: [],
      messageError: "",
      openSnackError: false,
      message: "",
      showForm: false,
      openSnack: false,
      disabled_option: false,
      bukaDialog: false,
      openBackdrop: false,
      hasCheckData: false,
      btnWording: "Simpan",
      hasGenerateCohort: false,
      done_actuarial_process: "0",
      statusLastPeriod: "",
    };
  }

  componentDidMount() {
    this.ambilData();
  }

  ambilData() {
    this.setState({ openBackdrop: true });
    axios
      .get("monitorCohort/detailCohort")
      .then((res) => {
        this.setState({ openBackdrop: false });
        SaveItem("PERIOD_DATA", res.data.data.PERIOD_DATA);
        this.setState({
          listCohort: res.data.data.list,
          PERIOD_DATA: res.data.data.periode_laporan,
          statusLastPeriod: res.data.data.statusLastPeriod,
          done_actuarial_process: res.data.data.done_actuarial_process,
        });
        if (res.data.data.cohort_complete === 0) {
          this.setState({ hasCheckData: true, hasGenerateCohort: false });
        } else {
          this.setState({ hasCheckData: false, hasGenerateCohort: true });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
      });
  }

  handleGenerateCohort = () => {
    this.setState({ openBackdrop1: true });

    var postData = new FormData();
    postData.append("mode", "1");
    postData.append("inserted_by", GetItem("user_id"));

    axios
      .post("prosesdata/generateCohort", postData)
      .then((res) => {
        this.setState({ openBackdrop1: false });
        alert(res.data.data);
        this.ambilData();
      })
      .catch((err) => {
        this.setState({ openBackdrop1: false });
        alert("Terjadi kesalahan");
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.pageTitle}>
            Mapping Data Cohort Periode {this.state.PERIOD_DATA}
          </Typography>{" "}
          <br></br>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.openBackdrop}
            onClick={this.handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Paper
            variant="outlined"
            style={{
              display:
                this.state.hasCheckData == true &&
                this.state.statusLastPeriod === "O"
                  ? ""
                  : "none",
            }}
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" className={classes.pageTitle}>
              Cohort belum digenerate, klik tombol Generate Cohort untuk
              mengenerate Cohort
            </Typography>
            <br></br>
          </Paper>
          <br></br>
          <Button
            style={{
              display:
                // this.state.hasCheckData === true ||
                this.state.statusLastPeriod === "O" ? "" : "none",
            }}
            variant="contained"
            color="primary"
            onClick={this.handleGenerateCohort}
          >
            {this.state.hasGenerateCohort === true
              ? "Generate Ulang Cohort"
              : "Generate Cohort"}
          </Button>
          <br></br>
          <br></br>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display:
              (this.state.hasCheckData === true &&
                this.state.statusLastPeriod === "O") ||
              (this.state.statusLastPeriod === "P" &&
                this.state.done_actuarial_process === "0") ||
              this.state.statusLastPeriod === "C"
                ? "none"
                : "",
          }}
        >
          <Grid container rowSpacing={0.5}>
            <br></br>
            <br></br>

            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupMortalityRateCohort"
                >
                  Set Cohort Mortality Rate
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupLapseRateCohort"
                >
                  Set Cohort Lapse Rate
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupAsumptionRateCohort"
                >
                  Set Cohort Asumption Rate
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupRider1RateCohort"
                >
                  Set Cohort Benefit 1 Rate
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupRider2RateCohort"
                >
                  Set Cohort Benefit 2 Rate
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  style={{ width: 250 }}
                  to="/prosesdata/setupRider3RateCohort"
                >
                  Set Cohort Benefit 3 Rate
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: this.state.hasCheckData == true ? "none" : "",
          }}
        >
          <Grid container rowSpacing={0.5}>
            <br></br>
            <br></br>

            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.listCohort}
              showProgress={this.state.isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(detailMulaiProsesApp);

const columns = [
  { id: "JENIS", label: "Jenis Data", minWidth: 80 },
  { id: "PERIODE", label: "Period Data", minWidth: 80 },
  { id: "VALUATION_DATA_", label: "Valuation Data", minWidth: 80 },
  { id: "COHORT_TYPE", label: "Tipe Cohort", minWidth: 80 },
  { id: "COHORT", label: "Cohort", minWidth: 120 },
  { id: "PRODUCT_NAME", label: "Nama Produk", minWidth: 120 },
  { id: "MORTALITY_RATE", label: "Mortality Rate", minWidth: 120 },
  { id: "LAPSE_RATE", label: "Lapse Rate", minWidth: 120 },
  { id: "ASUMPTION_RATE", label: "Asumption Rate", minWidth: 120 },
  { id: "RIDER1_RATE", label: "Benefit 1 Rate", minWidth: 120 },
  { id: "RIDER2_RATE", label: "Benefit 12Rate", minWidth: 120 },
  { id: "RIDER3_RATE", label: "Benefit 3 Rate", minWidth: 120 },
];

const StickyHeadTable = ({ openBackdrop, list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(75);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [obackDrop, setObackDrop] = React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setObackDrop(obackDrop), [obackDrop]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const handleOpenDetail = (upload_id) => {
    SaveItem("upload_id", upload_id);
    history.push("/prosesdata/detailKualitasData");
  };

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center">
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "kualitas_Data" ? (
                            value === "100" ? (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#10AC50",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            ) : (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#FB0101",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[75, 100, 150]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
