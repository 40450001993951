import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import PLListApp from './listpl'; 
import movement_detail from './movement_detail';     

export default class ProsesDataApp extends React.Component {
 
     render() {

        return (
            <Switch>  
            <Route exact path='/reportmovement/detail' component={movement_detail}/>
            <Route exact path='/reportmovement/**' component={PLListApp} />  
            </Switch>
        );
    }

};