import React, { Fragment } from "react";
import axios from "axios";
import Typography from "@material-ui/core/Typography";

import InputLabel from "@mui/material/InputLabel";
import { GetItem } from "../../../common/util";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "@fontsource/roboto/400.css";

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      list_cohort: [],
      searched: "",
      cohort: "",
      messageError: "",
      openSnackError: false,
      periode_laporan: "",
      closing_period: "",
      jenis_laporan: "",
      Backdrop: false,
      message: "",
      btnDownloadExcel: "Download Excel",
      btnDownloadPdf: "Download PDF",
    };
  }

  componentDidMount() {
    axios
      .get("downloadreport/list_period_data/movement")
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          list: [],
        });
      });
  }

  handleDownloadExcel = () => {
    const closing_period = this.state.closing_period;
    const cohort = this.state.cohort;

    if (closing_period === "") {
      this.setState({
        messageError: "Silakan pilih periode laporan terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    } /*else if (cohort === "") {
      this.setState({
        messageError: "Silakan pilih Cohort terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    }*/
    var postData = new FormData();
    postData.append("closing_period", this.state.closing_period);
    postData.append("cohort", this.state.cohort);
    postData.append("user_id", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("downloadReport/download_report_movement_excel", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server",
          openSnackError: true,
        });
      });
  };

  handleDownloadPdf = () => {
    const closing_period = this.state.closing_period;
    const cohort = this.state.cohort;

    if (closing_period === "") {
      this.setState({
        messageError: "Silakan pilih periode laporan terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    } /*
    if (cohort === "") {
      this.setState({
        messageError: "Silakan pilih Cohort terlebih dahulu.",
        openSnackError: true,
      });
      return false;
    }*/

    var postData = new FormData();
    postData.append("closing_period", this.state.closing_period);
    postData.append("cohort", this.state.cohort);
    postData.append("user_id", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post("downloadReport/download_report_movement_pdf", postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            messageError: res.data.data,
            openSnackError: true,
          });
        } else {
          window.open(res.data.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server.",
          openSnackError: true,
        });
      });
  };

  handleChangeAutoComplete = (event, newValue) => {
    const value = newValue.closing_period;
    if (value != "") {
      this.setState({
        closing_period: value,
      });
      this.getCohort(value);
    }
  };
  handleChangeAutoCompleteCohort = (event, newValue) => {
    const value = newValue.COHORT + "#" + newValue.IFRS_MODEL;
    console.log("cohort");
    console.log(value);
    if (value != "") {
      this.setState({
        cohort: value,
      });
    }
  };
  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    if (e.target.name === "closing_period") {
      this.getCohort(e.target.value);
    }
  };

  getCohort = (period_data) => {
    this.setState({ openBackdrop: true });
    axios
      .get("downloadReport/list_cohort_done/" + period_data)
      .then((res) => {
        this.setState({ openBackdrop: false, list_cohort: res.data.data });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          messageError: "terjadi kesalahan server",
          openSnackError: true,
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Typography variant="h4">Report Movement</Typography>
        <br></br>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.messageError}
          </Alert>
        </Snackbar>
        <Grid container>
          <Grid item sm>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={this.state.list}
                  onChange={this.handleChangeAutoComplete}
                  name="closing_period"
                  getOptionLabel={(option) =>
                    option.tahun_laporan + " - " + option.nama_bulan
                  }
                  value={
                    this.state.list.find(
                      (option) =>
                        option.closing_period === this.state.closing_period
                    ) || null
                  }
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pilih Periode"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={this.state.list_cohort}
                  onChange={this.handleChangeAutoCompleteCohort}
                  name="cohort"
                  getOptionLabel={(option) =>
                    option.COHORT +
                    "(" +
                    option.PERIOD_DATA +
                    " " +
                    option.IFRS_MODEL +
                    "-" +
                    option.PRODUCT_NAME +
                    ")"
                  }
                  value={
                    this.state.list_cohort.find(
                      (option) =>
                        option.COHORT + "#" + option.IFRS_MODEL ===
                        this.state.cohort
                    ) || null
                  }
                  sx={{ width: 250 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pilih Cohort"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownloadExcel}
                >
                  {this.state.btnDownloadExcel}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleDownloadPdf}
                >
                  {this.state.btnDownloadPdf}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

export default ProseslistApp;
