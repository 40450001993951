import React, { Component, useState}  from 'react';
// import AppIcon  from '.\www14.png';
import axios from 'axios'; 
import { styled } from '@mui/material/styles'; 
import withStyles from '@material-ui/core/styles/withStyles';

import Grid  from '@material-ui/core/Grid'; 
import Typography  from '@material-ui/core/Typography'; 
import Button  from '@material-ui/core/Button';
import {ValidateData,GetItem} from '../../common/util'; 
import Paper from '@mui/material/Paper';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
 
import { Link,useHistory } from 'react-router-dom'; 
import Box from '@mui/material/Box';
import {BRIDGEGET,BASE_URL,BRIDGEDELETE} from '../../common/constantVariable';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Title from '../../components/title';  

const styles = {
    form: {
        textAlign: 'left'
    },
    textCenter : {
        textAlign : 'center'
    },
    bgColorGrey : {
        backgroundColor : '#cdcdcd'
    }
};

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  }));

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  
  


class ProsesUlangDetailApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
           
            closing_id : GetItem('closing_id'), 
            bulan_laporan : GetItem('bulan_laporan'), 
            tahun_laporan : GetItem('tahun_laporan'),  
            valid: false, 
            messageError : '', 
            openSnackError : false,
            message : '',
            showForm : false,
            openSnack : false,
            total_record : '50.000',
            total_gmm : '20.000',
            total_vfa : '10.000',
            total_paa : '10.000',
            total_lic : '10.000',

            
            total_record_done : '50.000',
            total_gmm_done : '20.000',
            total_vfa_done : '10.000',
            total_paa_done : '10.000',
            total_lic_done : '10.000',
            
            persentase_total_record_done : 100,
            persentase_total_gmm_done : 100,
            persentase_total_vfa_done : 100,
            persentase_total_paa_done : 100,
            persentase_total_lic_done : 100,

            disabled_option : false,
            btnWording : 'Simpan'
        }
    }
    
    componentDidMount() {  
        axios.get('prosesdata/summaryProsesData/'+this.state.closing_id)
        .then( (res) => {
            this.setState({
                list: res.data.data
            })
        })
        .catch( (err) => { 
            this.setState({
                list: []
            })
        });    
        
    }

    
    handleTutupDialog = () => {
        this.setState({
            bukaDialog: false
        })
    };
    handleBukaDialog = () => {
        this.setState({
            bukaDialog: true
        })
    };

 

    mulaiProses = () =>{ 
        alert("Proses periode "+this.state.bulan_laporan+" "+this.state.tahun_laporan+" sudah di mulai. Utk memonitor progress proses dapat dilihat di menu proses data.")
        this.handleTutupDialog()  
        window.history.back(-1)
  }
     
    render() { 
        const { classes } = this.props; 

        return (
            <Grid container   >
                 <Dialog 
                                open={this.state.bukaDialog}
                                onClose={this.handleTutupDialog} 
                            >
                                <DialogTitle id="alert-dialog-title">
                                    Ulangi proses Data?
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        Apakah anda yakin untuk pengulang proses?
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={this.mulaiProses} autoFocus> Ya  </Button>
                                    <Button onClick={this.handleTutupDialog}>Tidak</Button>
                                </DialogActions>
                            </Dialog>


                            <Grid item xs={12}>
                                <Typography variant="h6" className={classes.textCenter}>
                                        Informasi Proses Data   
                                </Typography> 
                            
                            </Grid><br></br>
                             
                            <Grid item xs={2}>
                                Periode
                            </Grid>
                            <Grid item xs={10}>
                                : {this.state.bulan_laporan} - {this.state.tahun_laporan} 
                            </Grid> 
                            <Grid item xs={2}>
                                Tanggal mulai proses
                            </Grid>
                            <Grid item xs={10}>
                                : 2023-03-01 16.00.00
                            </Grid> 
                            <Grid item xs={2}>
                                Tanggal akhir proses
                            </Grid>
                            <Grid item xs={10}>
                                : 2023-03-01 18.31.10
                            </Grid> 
                            <Grid item xs={2}>
                                durasi proses data
                            </Grid>
                            <Grid item xs={10}>
                                : 02:01:10
                            </Grid> 
                            <Grid item xs={2}>
                                Status Proses
                            </Grid>
                            <Grid item xs={10}>
                                : DONE
                            </Grid>  
                
                            <Grid item xs={12} style={{marginTop: 20}}>
                            <Grid container spacing={2}>
                           
                                    <Grid item sm={4}>
                                        <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 85,
                                        }}
                                        >
                                            <Title>Progress Total Record</Title>
                                            <Typography component="p" variant="h6">
                                                {this.state.total_record_done} dari {this.state.total_record}
                                            </Typography> 
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={this.state.persentase_total_record_done} />
                                                </Box>
                                        </Paper>
                                    </Grid>
                                    <Grid item sm={4}  >
                                        <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 85,
                                        }}
                                        >
                                            <Title>Progress GMM</Title>
                                            <Typography component="p" variant="h6">
                                                {this.state.total_gmm_done} dari {this.state.total_gmm}
                                            </Typography> 
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={this.state.persentase_total_gmm_done} />
                                                </Box>
                                        </Paper>
                                    </Grid>
                                    
                                    <Grid item sm={4} >
                                        <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 85,
                                        }}
                                        >
                                            <Title>Progress VFA</Title>
                                            <Typography component="p" variant="h6">
                                                {this.state.total_vfa_done} dari {this.state.total_vfa}
                                            </Typography> 
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={this.state.persentase_total_vfa_done} />
                                                </Box>
                                        </Paper>
                                    </Grid>
                                    
                                    <Grid item sm={4} >
                                        <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 85,
                                        }}
                                        >
                                            <Title>Progress PAA</Title>
                                            <Typography component="p" variant="h6">
                                                {this.state.total_paa_done} dari {this.state.total_paa}
                                            </Typography> 
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={this.state.persentase_total_paa_done} />
                                                </Box>
                                        </Paper>
                                    </Grid>

                                    <Grid item sm={4} >
                                        <Paper
                                        sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: 85,
                                        }}
                                        >
                                            <Title>Progress LIC</Title>
                                            <Typography component="p" variant="h6">
                                                {this.state.total_lic_done} dari {this.state.total_lic}
                                            </Typography> 
                                                <Box sx={{ width: '100%' }}>
                                                    <LinearProgressWithLabel value={this.state.persentase_total_lic_done} />
                                                </Box>
                                        </Paper>
                                    </Grid>
                                        
                                   
                                </Grid> 
                            </Grid>
                 
                            <Grid item xs={12}><br></br> Catatan :
                            </Grid> 
                            <Grid item xs={12}> 
                                 Setelah Klik tombol "Ulangi Proses", data periode {this.state.bulan_laporan} {this.state.tahun_laporan} yang sudah diproses akan dihapus dan akan memulai pengulangi proses data.<br></br> 
                                 klik tombol "Ulangi Proses" untuk memulai mengulangi proses data."
                            </Grid>

                            <Grid item xs={12}><br></br><br></br>
                        <Grid container rowSpacing={0.5}>
                            
                            <Grid item xs={12}>
                                <Box sx={{ width: '100%' }}>
                                <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                                    Kembali
                                </Button>
                                 &nbsp;
                                 &nbsp;
                                 &nbsp;
                                <Button variant='contained' color='primary' className={classes.button} onClick={ () => {this.handleBukaDialog() }}  >
                                    Ulangi Proses
                                </Button>
                                 &nbsp;
                                 &nbsp;
                                 &nbsp;
                                <Button variant='contained' color='primary' 
                                className={classes.button} 
                                component={Link} to="/prosesdata/tambahAdjustment"  >
                                    Tambah Adjustment
                                </Button>
                                </Box>
                            </Grid> 
                        
                        </Grid> 
                </Grid>
            </Grid>
            
        )
    }
}

export default withStyles(styles) (ProsesUlangDetailApp);




