import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { BASE_URL } from "../../common/constantVariable";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@mui/material/Backdrop";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { SaveItem, GetItem, RemoveItem } from "../../common/util";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
const styles = {
  pageTitle: {
    textAlign: "center",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      bulanLaporan: "",
      tahunLaporan: "",
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openBackdrop: false,
      openSnackError: false,
      openDialog: false,
      wordingDialog: "",
      message: "",
    };
  }

  componentDidMount() {
    this.ambilData();
  }

  ambilData() {
    this.setState({ openBackdrop: true });
    axios
      .get("prosesdata/list_proses_ulang/insepsi")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  handleOpenClosing = (id) => {
    const baseVal = id.target.value.split("-");
    const closing_id = baseVal[0];
    const aksi = baseVal[1];

    this.setState({ openBackdrop: true });
    const formData = new FormData();
    formData.append("closing_id", closing_id);
    formData.append("tipe", "insepsi");
    formData.append("inserted_by", GetItem("user_id"));

    if (aksi === "proses_ulang") {
      // reset tanpa hapus data
      axios.post("prosesdata/proses_ulang", formData).then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          wordingDialog: res.data.data,
          openDialog: true,
        });
        this.ambilData();
      });
    } else if (aksi === "mulai_ulang") {
      // reset dengan hapus data
      axios.post("prosesdata/mulai_ulang/insepsi", formData).then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message == "success") {
          this.setState({
            wordingDialog: res.data.data,
            openDialog: true,
          });
          this.ambilData();
        } else {
          this.setState({ openBackdrop: false });
          alert(res.data.message);
        }
      });
    }
  };

  handleCloseDialog = () => {
    // let history = useHistory();
    this.setState({ openDialog: false });
    // history.push("/prosesdata/uploadData");
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Informasi"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.wordingDialog}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialog}>OK</Button>
            </DialogActions>
          </Dialog>

          <Typography>
            <Box sx={{ color: "#FB0101", fontWeight: "bold", m: 1 }}>
              Perhatian!
            </Box>
            <Box sx={{ color: "#FB0101", fontWeight: "bold", m: 1 }}>
              Menu "Reset Periode" digunakan untuk Closing Insepsi, konsekuensi
              dari menjalankan menu ini adalah data yang sudah diupload dan
              diproses pada saldo awal Inspesi akan dihapus sehingga harus mulai
              dari upload data kembali.
            </Box>
            <Box sx={{ color: "#FB0101", fontWeight: "bold", m: 1 }}>
              Menu "Reset Proses Tanpa Hapus Data" akan mengembalikan status
              Insepsi yang sudah close menjadi Open dan bisa melakukan proses
              ulang.
            </Box>
          </Typography>

          <Paper variant="outlined">
            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.list}
              showProgress={this.state.isLoading}
              handleOpenClosing={this.handleOpenClosing}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "tgl_mulai_proses_", label: "Tanggal Proses", minWidth: 80 },
  { id: "tgl_proses_selesai_", label: "Tanggal Selesai Proses", minWidth: 80 },
  { id: "closing_status_", label: "Status", minWidth: 80 },
];

const StickyHeadTable = ({
  openBackdrop,
  list,
  isLoading,
  handleOpenClosing,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  // const handleOpenAksi = () =>{
  //   const baseVal = e.target.value.split("-");
  //   const closing_id = baseVal[0];
  //   const aksi = baseVal[1];
  //   if (aksi == "reset") {
  //     handleOpenClosing(closing_id);
  //   } else if (baseVal[3] == "delete") {
  //     setOpenDialog(true);
  //   }
  // }

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "kualitas_data" ? (
                            value === "100%" ? (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#10AC50",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            ) : (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#FB0101",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          {/* <MenuItem value={row.closing_id + "-mulai_ulang"}>
                            Reset Periode Insepsi
                          </MenuItem> */}
                          {/* untuk  insepsi reset periode ini dihilangkan, case by case aja */}
                          <MenuItem value={row.closing_id + "-proses_ulang"}>
                            Reset Proses Tanpa Hapus Data
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
