import React, { Component, Fragment, useState } from "react";
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { BRIDGEGET, BASE_URL } from "../../common/constantVariable";

class ListApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    axios
      .get("rolemenu/list")
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err.message);
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/setuprolemenu/register"
          >
            Tambah Role Menu
          </Button>
        </div>

        <StickyHeadTable list={this.state.list} />
      </Fragment>
    );
  }
}

export default ListApp;
// export default withStyles(styles) (ListApp);

const columns = [
  { id: "role_name", label: "Role Name", minWidth: 80 },
  // { id: 'menu_name', label: 'Menu Name', minWidth: 80 },
];

const StickyHeadTable = ({ list, handleDelete }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="edit" align="center" style={{ minWidth: 30 }}>
                Edit / Detail
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                // console.log(row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <Link to={`/setuprolemenu/edit/${row.role_id}`}>
                        edit / detail
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
