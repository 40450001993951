import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import PLListApp from './listpl';   

export default class ProsesDataApp extends React.Component {
 
     render() {

        return (
            <Switch>   
            <Route exact path='/reportcashflow/*' component={PLListApp} />  
            </Switch>
        );
    }

};