import React, { Component, Fragment, useState}  from 'react';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {COMPANY_LIST,BRIDGEGET,BASE_URL,BRIDGEDELETE} from '../../common/constantVariable';
import axios from 'axios';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'; 
import Button from '@mui/material/Button'; 

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 
 
class UserListApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listData: [],
            errormessage: '',  
            showButtonAdd : false,
            openSnack : false,
            openSnackError : false,
            openBackdrop : false,
            message: '',
        }
    }
    
    

    componentDidMount() {
      
    var postData = new FormData();
    postData.append("url", 'user');

        axios.get(COMPANY_LIST)
            .then( (res) => {
                this.setState({
                    listData: res.data.data
                })

                if(res.data.data.length < 1)
                {
                  this.setState({
                      showButtonAdd: true
                  })
                }
            })
            .catch( (err) => {
                console.error(err.message);
                this.setState({
                    listData: []
                })
            });
    }

   

    render() {

        const { classes } = this.props;

        return (
          <Fragment>
            
            <div>
                
              <Button style={{ display: this.state.showButtonAdd  == false ? 'none' : '' }}  variant="contained" color="primary" component={Link} to="/setupcompany/register">
              Tambah Identitas Perusahaan
                </Button>
            </div>
            <br></br>

            <StickyHeadTable openBackdrop={this.state.openBackdrop} listData={this.state.listData} />

          </Fragment>
        )

    }
}

export default UserListApp;
// export default withStyles(styles) (UserListApp);

const columns = [
  { id: 'ID_COMPANY', label: 'ID Perusahaan', minWidth: 80 },
  { id: 'COMPANY_NAME', label: 'Nama Perusahaan', minWidth: 180 },
  { id: 'EFFECTIVE_DATE', label: 'Tanggal Update', minWidth: 80 }, 
]

const StickyHeadTable = ({openBackdrop,listData, handleDelete}) => {
  const [page, setPage] = React.useState(0); 
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  // const {listData, handleDelete} = props;

  return (

    // <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <Paper sx={{ overflow: 'hidden' }}>
      <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
    
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell
                  key='no'
                  align='center'
                  style={{ minWidth: 40 }}
                >
                  No
                </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
                <TableCell
                  key='edit'
                  align='center'
                  style={{ minWidth: 30 }}
                >
                  
                </TableCell>
                
            </TableRow>
          </TableHead>
          <TableBody>
            {listData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                // console.log(row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key='no' align='center'>
                        {idx + 1 + page * rowsPerPage}    
                      </TableCell>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                      <TableCell key='edit' align='center'>
                          <Link to={`/setupcompany/edit/${row.ID_COMPANY}`}>edit</Link>
                      </TableCell>
 

                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={listData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

}









