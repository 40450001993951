import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listBsApp from './listbs';     
import balancesheet_detail from './balancesheet_detail';     

export default class BalanceSheet extends React.Component {
 
     render() {

        return (
            <Switch>  
            <Route exact path='/reportbalancesheet/detail' component={balancesheet_detail} /> 
            <Route exact path='/reportbalancesheet/**' component={listBsApp} /> 
            </Switch>
        );
    }

};