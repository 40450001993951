import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { PAGINATION_RECORD_PER_PAGE } from "../../common/constantVariable";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GetItem, RemoveItem, SaveItem } from "../../common/util";

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      allClosed: 0,
      bulanLaporan: "",
      tahunLaporan: "",
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      openBackdrop: false,
      message: "",
      openDialog: false,
    };
  }

  componentDidMount() {
    this.ambilData();
  }

  ambilData() {
    this.setState({ openBackdrop: true });
    axios
      .get("prosesdata/list_closing_periode")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: res.data.data.list,
          allClosed: res.data.data.allClosed,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  handleDelete = (id) => {
    SaveItem("delete_id", id);
    this.setState({ openDialog: true });
  };

  deleteData = () => {
    this.setState({ openBackdrop: true, openDialog: false });
    axios
      .delete(
        "prosesdata/delete_closing_periode/" +
          GetItem("delete_id") +
          "/" +
          GetItem("user_id")
      )
      .then((res) => {
        this.setState({ openBackdrop: false });
        RemoveItem("delete_id");
        this.ambilData();
      })
      .catch((err) => {
        RemoveItem("delete_id");
        this.setState({ openBackdrop: false });
      });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          {this.state.allClosed === 0 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleNewProses}
              component={Link}
              to="/prosesdata/register"
            >
              Set Periode Closing Baru
            </Button>
          ) : (
            ""
          )}
        </div>
        <br></br>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda yakin ingin menghapus data ini ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Batal</Button>
            <Button onClick={this.deleteData} autoFocus>
              Hapus
            </Button>
          </DialogActions>
        </Dialog>

        <StickyHeadTable
          openBackdrop={this.state.openBackdrop}
          list={this.state.list}
          showProgress={this.state.isLoading}
          handleDelete={this.handleDelete}
        />
      </Fragment>
    );
  }
}

export default ProseslistApp;

const columns = [
  { id: "tahun_laporan", label: "Tahun Laporan", minWidth: 80 },
  { id: "nama_bulan", label: "Bulan Laporan", minWidth: 120 },
  { id: "tgl_proses_selesai", label: "Tanggal Proses", minWidth: 80 },
  { id: "closing_transisi_", label: "Closing Transisi", minWidth: 80 },
  { id: "closing_status_", label: "Status", minWidth: 80 },
];

const StickyHeadTable = ({ openBackdrop, list, isLoading, handleDelete }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = list.filter((row) => {
      return (
        row.no_polis.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.client_name.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  let history = useHistory();

  const handleAksi = (e) => {
    const baseVal = e.target.value.split("-");
    const closing_id = baseVal[0];

    axios
      .delete("prosesdata/delete_closing_periode/" + closing_id)
      .then((res) => {
        //if (res.data.message === "success") {
        alert("Berhasil Hapus Periode");
        // }else{
        //   alert(res.data.message);
        // }
      })
      .catch((err) => {
        console.log(err);
        alert("Gagal Hapus Periode");
      });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Paper sx={{ overflow: "hidden" }}>
      {showProgress && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ mt: 3, ml: 1 }} color="secondary" />
        </div>
      )}

      <TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    {row.closing_status === "O" ? (
                      <TableCell key="delete" align="center">
                        <Link
                          to="#"
                          onClick={() => {
                            handleDelete(row.closing_id);
                          }}
                        >
                          Hapus
                        </Link>
                      </TableCell>
                    ) : (
                      ""
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={PAGINATION_RECORD_PER_PAGE}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
