import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { CLOSING_PERIODE_REGISTER } from "../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class ProsesRegisterApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listBulanPeriode: [],
      listTahunPeriode: [],
      closing_transisi: "N",
      year: new Date().getFullYear(),
      minYear: 2010,
      policy_no: "",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      tahun_laporan: "",
      bulan_laporan: "",
      disabled_option: false,
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      btnWording: "Simpan",
    };
  }

  componentDidMount() {
    const currencies = [
      {
        value: "01",
        label: "Januari",
      },
      {
        value: "02",
        label: "Februari",
      },
      {
        value: "03",
        label: "Maret",
      },
      {
        value: "04",
        label: "April",
      },
      {
        value: "05",
        label: "Mei",
      },
      {
        value: "06",
        label: "Juni",
      },
      {
        value: "07",
        label: "Juli",
      },
      {
        value: "08",
        label: "Agustus",
      },
      {
        value: "09",
        label: "September",
      },
      {
        value: "10",
        label: "Oktober",
      },
      {
        value: "11",
        label: "November",
      },
      {
        value: "12",
        label: "Desember",
      },
    ];

    this.setState({ listBulanPeriode: currencies });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      helpers: this.state.helpers,
    });
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");
    const valid = ValidateData(e.elements);

    if (!valid) {
      this.setState({
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    this.setState({
      btnWording: "Sedang diproses",
    });

    var postData = new FormData();
    postData.append("bulan_laporan", this.state.bulan_laporan);
    postData.append("tahun_laporan", this.state.tahun_laporan);
    postData.append("closing_transisi", this.state.closing_transisi);
    postData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(CLOSING_PERIODE_REGISTER, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({ openBackdrop: false });
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
        });
      });
  };
  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Grid item sm />
        <Grid item sm>
          <Typography variant="h6" className={classes.pageTitle}>
            Closing Periode Baru
          </Typography>
          {/* <form id="form1" noValidate onSubmit={this.handleSubmit}> */}
          <form id="form1" name="form1" noValidate>
            {" "}
            <br></br>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Tahun Laporan
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                size="small"
                sx={{ width: "100%" }}
                value={this.state.tahun_laporan}
                onChange={this.handleChange}
                label="Tahun Laporan"
                required
                name="tahun_laporan"
              >
                {Array.from(
                  new Array(this.state.year - this.state.minYear),
                  (v, i) => (
                    <MenuItem value={this.state.year - i}>
                      {this.state.year - i}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <br /> <br />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Periode Bulan
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                required
                value={this.state.bulan_laporan}
                onChange={this.handleChange}
                size="small"
                name="bulan_laporan"
                label="Periode Bulan"
              >
                {this.state.listBulanPeriode.map((row, idx) => (
                  <MenuItem value={row.value}>{row.label}</MenuItem>
                ))}
              </Select>
              <br></br>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Closing Transisi
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.state.closing_transisi}
                  onChange={this.handleChange}
                  size="small"
                  name="closing_transisi"
                  label="Closing Transisi"
                >
                  <MenuItem value="N">No</MenuItem>
                  <MenuItem value="Y">Yes</MenuItem>
                </Select>
              </FormControl>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              {this.state.btnWording}
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(ProsesRegisterApp);
