import React, { Component, Fragment, useState } from "react";
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Link } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GetItem, SaveItem, RemoveItem } from "../../common/util";

class ListApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      errormessage: "",
      openDialog: false,
      openSnack: false,
      openSnackError: false,
      openBackdrop: false,
      message: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get("roleuser/list")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  handleDelete = (id, user_id) => {
    SaveItem("delete_id", id);
    SaveItem("user_id", user_id);
    this.setState({ openDialog: true });
  };

  deleteData = () => {
    this.setState({ openBackdrop: true, openDialog: false });
    axios
      .delete(
        "roleuser/role/" + GetItem("delete_id") + "/" + GetItem("user_id")
      )
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message === "success") {
          const data = this.state.list.filter(
            (item) =>
              item.role_id !== GetItem("delete_id") &&
              item.user_id !== GetItem("user_id")
          );
          this.setState({
            list: data,
          });
        }
        RemoveItem("delete_id");
        RemoveItem("user_id");
      })
      .catch((err) => {});
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/setuproleuser/register"
          >
            Tambah Role User
          </Button>
        </div>

        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Anda yakin ingin menghapus data ini ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>Batal</Button>
            <Button onClick={this.deleteData} autoFocus>
              Hapus
            </Button>
          </DialogActions>
        </Dialog>

        <StickyHeadTable
          openBackdrop={this.state.openBackdrop}
          list={this.state.list}
          handleDelete={this.handleDelete}
        />
      </Fragment>
    );
  }
}

export default ListApp;
// export default withStyles(styles) (ListApp);

const columns = [
  { id: "user_id", label: "ID User", minWidth: 80 },
  { id: "role_name", label: "Nama Role", minWidth: 80 },
];

const StickyHeadTable = ({ openBackdrop, list, handleDelete }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    // <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <Paper sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="edit" align="center" style={{ minWidth: 30 }}>
                Edit
              </TableCell>
              <TableCell key="delete" align="center" style={{ minWidth: 30 }}>
                Hapus
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                // console.log(row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <Link
                        to={`/setuproleuser/edit/${row.user_id}/${row.role_id}`}
                      >
                        edit
                      </Link>
                    </TableCell>

                    <TableCell key="delete" align="center">
                      <Link
                        to="#"
                        onClick={() => {
                          handleDelete(row.role_id, row.user_id);
                        }}
                      >
                        delete
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
