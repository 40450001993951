import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { BASE_URL } from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { SaveItem, GetItem, RemoveItem } from "../../../common/util";

import withStyles from "@material-ui/core/styles/withStyles";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class logUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      listUser: [],
      searched: "",
      errormessage: "",
      prm_model: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    axios
      .get(BASE_URL + "tools/list_user")
      .then((res) => {
        this.setState({
          listUser: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          listUser: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
          classes={classes}
          ListUser={this.state.listUser}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(logUser);

const columns = [
  { id: "USER_ID", label: "Username", minWidth: 80 },
  { id: "LOG_DATE", label: "Log Date", minWidth: 60 },
  { id: "FUNCTION_NAME", label: "Function Name", minWidth: 100 },
  { id: "NOTES", label: "Notes", minWidth: 160 },
];

const StickyHeadTable = ({ list, isLoading, classes, ListUser }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [pilihUser, setPilihUser] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [listUser, setListUser] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [tgl_awal, setTgl_awal] = React.useState("");
  const [tgl_akhir, setTgl_akhir] = React.useState("");

  useEffect(() => setListUser(ListUser), [ListUser]);
  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const resetDataModal = () => {
    setRows([]);
    setPilihUser("");
    setTgl_awal("");
    setTgl_akhir("");
  };

  const setDataModel = () => {
    if (pilihUser === "") {
      alert("Pilih user terlebih dahulu");
      return false;
    }

    if (tgl_awal !== "" && tgl_akhir === "") {
      alert("masukan tanggal akhir terlebih dahulu");
      return false;
    }
    if (tgl_akhir !== "" && tgl_awal === "") {
      alert("masukan tanggal awal terlebih dahulu");
      return false;
    }

    setOpenBackdrop(true);
    setRows([]);
    axios
      .get(
        "tools/list_log_user/" + pilihUser + "/" + tgl_awal + "/" + tgl_akhir
      )
      .then((res) => {
        setOpenBackdrop(false);
        setRows(res.data.data);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        setRows([]);
      });
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("#");
    const age = baseVal[0];
    const prm_model = baseVal[1];
    SaveItem("age", age);
    SaveItem("prm_model", prm_model);
    if (baseVal[2] == "update") {
      history.push("/setup/mortalityrate/update");
    } else if (baseVal[2] == "delete") {
      setOpenDialog(true);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChangeJenisModel = (e) => {
    const value = e.target.value;
    if (value === "") {
      setPilihUser("");
    } else {
      setPilihUser(value);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "tgl_awal") {
      setTgl_awal(e.target.value);
    } else if (e.target.name === "tgl_akhir") {
      setTgl_akhir(e.target.value);
    }
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Pilih User
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              onChange={handleChangeJenisModel}
              value={pilihUser}
              size="small"
              style={{ height: 49 }}
              label="Pilih User"
              name="pilihUser"
            >
              {listUser.map((dataPrm, idx) => (
                <MenuItem value={dataPrm.username}>{dataPrm.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="date"
              label="Tanggal Awal"
              type="date"
              name="tgl_awal"
              value={tgl_awal}
              onChange={handleChange}
              // className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <TextField
              id="date"
              label="Tanggal Akhir"
              name="tgl_akhir"
              value={tgl_akhir}
              onChange={handleChange}
              type="date"
              // className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={setDataModel}>
            Cari
          </Button>
          &nbsp; &nbsp;
          <Button variant="contained" color="primary" onClick={resetDataModal}>
            Reset
          </Button>
        </Grid>
      </Grid>
      <br />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[100, 150]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
