import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { FaList, FaStop, FaHome, FaCopyright } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import sidebarBg from "./assets/bg1.jpg";
import { GetItem } from "../common/util";
import { APPS_NAME } from "../common/constantVariable";

// https://react-icons.github.io/react-icons/icons?name=fc

const Aside = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
  handleLogout,
}) => {
  const intl = useIntl();

  const [dataMenu, setDataMenu] = useState([]);
  const [widthAside, setWidthAside] = useState(315);

  useEffect(() => {
    setDataMenu(JSON.parse(GetItem("dataMenu")));
  }, []);

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      width={widthAside}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            whiteSpace: "nowrap",
            textAlign: "center",
            marginTop: 15,
          }}
        >
          <img src={GetItem("logo_company")} width={50} />
        </div>
        <div
          style={{
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 12,
            letterSpacing: "1px",
            marginBottom: 10,
            overflow: "hidden",
            textOverflow: "ellipsis",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          {APPS_NAME} - IFRS17 Application
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          {dataMenu.map((data, idx) =>
            data.sub_menu.length === 0 ? (
              <MenuItem icon={<FaHome />}>
                <Link to={data.menu_link}>{data.menu_name}</Link>
              </MenuItem>
            ) : (
              // <Submenu submenu={data.sub_menu} parent_name={data.menu_name}/>
              <SubMenu title={data.menu_name} icon={<FaList />}>
                {data.sub_menu.map((data, idx) => (
                  <MenuItem icon={<FaStop />}>
                    <Link to={data.menu_link}>{data.menu_name}</Link>
                  </MenuItem>
                ))}
              </SubMenu>
            )
          )}
          <MenuItem icon={<CgProfile />}>
            <Link to={"/profile"}>PROFIL</Link>
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <Menu iconShape="circle">
          <MenuItem icon={<IoMdLogOut />}>
            <Link to={"/"} onClick={handleLogout}>
              Logout
            </Link>
          </MenuItem>

          <div style={{ textAlign: "center" }}>
            <FaCopyright size={13} style={{ color: "red" }} />{" "}
            <small> {APPS_NAME}</small>
            <small> {new Date().getFullYear()} </small>
          </div>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

const Submenu = (props) => {
  const sub = props.submenu;
  const name = props.parent_name;
  return (
    <SubMenu title={name} icon={<FaList />}>
      {sub.map((data, idx) => (
        <MenuItem icon={<FaStop />}>
          <Link to={data.menu_link}>{data.menu_name}</Link>
        </MenuItem>
      ))}
    </SubMenu>
  );
};

export default Aside;
