import React, { Component, useState } from "react";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";
import withStyles from "@material-ui/core/styles/withStyles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../common/util";
import Paper from "@mui/material/Paper";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  BRIDGEGET,
  BASE_URL,
  BRIDGEDELETE,
} from "../../common/constantVariable";
import Title from "../../components/title";

const styles = {
  form: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  bgColorGrey: {
    backgroundColor: "#cdcdcd",
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class ProsesDetailApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closing_id: GetItem("closing_id"),
      bulan_laporan: GetItem("bulan_laporan"),
      tahun_laporan: GetItem("tahun_laporan"),
      valid: false,
      tgl_mulai_proses: "",
      status_proses: "",
      tgl_akhir_proses: "",
      durasi_proses: "",
      messageError: "",
      openSnackError: false,
      message: "",
      showForm: false,
      openSnack: false,
      total_record: "",
      total_gmm: "",
      total_vfa: "",
      total_paa: "",
      total_lic: "",
      total_reasgmm: "",
      total_reaspaa: "",

      total_record_done: "",
      total_gmm_done: "",
      total_vfa_done: "",
      total_paa_done: "",
      total_lic_done: "",
      total_reasgmm_done: "",
      total_reaspaa_done: "",

      persentase_total_record_done: 0,
      persentase_total_gmm_done: 0,
      persentase_total_vfa_done: 0,
      persentase_total_paa_done: 0,
      persentase_total_lic_done: 0,
      persentase_total_reasgmm_done: 0,
      persentase_total_reaspaa_done: 0,

      disabled_option: false,
      btnWording: "Simpan",
    };
  }

  componentDidMount() {
    this.getData();

    this.interval = setInterval(this.runIntervalCheck, 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  runIntervalCheck = () => {
    this.getData();
  };
  getData = () => {
    axios
      .get("dataSummary/summaryInProgress/" + this.state.closing_id)
      .then((res) => {
        this.setState({
          tgl_mulai_proses: res.data.data.tgl_mulai_proses,
          tgl_akhir_proses: res.data.data.tgl_selesai_proses,
          status_proses: res.data.data.closing_status,
          durasi_proses: res.data.data.durasi_proses,
          total_record: res.data.data.total_record,
          total_gmm: res.data.data.total_gmm,
          total_vfa: res.data.data.total_vfa,
          total_paa: res.data.data.total_paa,
          total_lic: res.data.data.total_lic,
          total_reasgmm: res.data.data.total_reasgmm,
          total_reaspaa: res.data.data.total_reaspaa,

          total_record_done: res.data.data.total_record_done,
          total_gmm_done: res.data.data.total_gmm_done,
          total_vfa_done: res.data.data.total_vfa_done,
          total_paa_done: res.data.data.total_paa_done,
          total_lic_done: res.data.data.total_lic_done,
          total_reasgmm_done: res.data.data.total_reasgmm_done,
          total_reaspaa_done: res.data.data.total_reaspaa_done,

          persentase_total_record_done:
            res.data.data.persentase_total_record_done,
          persentase_total_gmm_done: res.data.data.persentase_total_gmm_done,
          persentase_total_vfa_done: res.data.data.persentase_total_vfa_done,
          persentase_total_paa_done: res.data.data.persentase_total_paa_done,
          persentase_total_lic_done: res.data.data.persentase_total_lic_done,
          persentase_total_reasgmm_done:
            res.data.data.persentase_total_reasgmm_done,
          persentase_total_reaspaa_done:
            res.data.data.persentase_total_reaspaa_done,
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.textCenter}>
            Informasi Proses Data
          </Typography>
        </Grid>
        <br></br>

        <Grid item xs={2}>
          Periode
        </Grid>
        <Grid item xs={10}>
          : {this.state.bulan_laporan} - {this.state.tahun_laporan}
        </Grid>
        <Grid item xs={2}>
          Tanggal mulai proses
        </Grid>
        <Grid item xs={10}>
          : {this.state.tgl_mulai_proses}
        </Grid>
        <Grid item xs={2}>
          Tanggal akhir proses
        </Grid>
        <Grid item xs={10}>
          : {this.state.tgl_akhir_proses}
        </Grid>
        <Grid item xs={2}>
          Durasi proses
        </Grid>
        <Grid item xs={10}>
          : {this.state.durasi_proses}
        </Grid>
        <Grid item xs={2}>
          Status Proses
        </Grid>
        <Grid item xs={10}>
          : {this.state.status_proses}
        </Grid>

        <Grid item xs={12} style={{ marginTop: 20 }}>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress Total Record</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_record_done} dari {this.state.total_record}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_record_done}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress GMM</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_gmm_done} dari {this.state.total_gmm}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_gmm_done}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress VFA</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_vfa_done} dari {this.state.total_vfa}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_vfa_done}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress PAA</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_paa_done} dari {this.state.total_paa}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_paa_done}
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress LIC</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_lic_done} dari {this.state.total_lic}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_lic_done}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress REAS GMM</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_reasgmm_done} dari{" "}
                  {this.state.total_reasgmm}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_reasgmm_done}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Progress REAS PAA</Title>
                <Typography component="p" variant="h6">
                  {this.state.total_reaspaa_done} dari{" "}
                  {this.state.total_reaspaa}
                </Typography>
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={this.state.persentase_total_reaspaa_done}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10 }}>
          <br></br>

          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Kembali
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProsesDetailApp);
