import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import format_mortality from "./assets/format upload.xlsx";

import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import {
  REGISTER_SETUP_ACCOUNTING_UPLOAD,
  REGISTER_SETUP_ACCOUNTING,
  BRIDGEPOSTFILE,
  BRIDGEPOST,
  BRIDGEGET,
  BASE_URL,
} from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload_dokumen: "",
      acct_model: "",
      acct_code: "",
      cr_or_dr: "",
      sort: "",
      coa_partner_desc: "",
      coa_partner_code: "",
      coa_partner_category: "",
      acct_desc: "",
      acct_category: "",
      listCategory: [],
      listModel: [],
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios.get(BASE_URL + "/setupparameter/accounting_init").then((result) => {
      this.setState({ openBackdrop: false });
      if (result.data.errorcode === 0) {
        this.setState({
          listCategory: result.data.data.listCategory,
          listModel: result.data.data.listModel,
        });
      }
    });
  }

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    if (e.target.name === "acct_model") {
      this.setState({ openBackdrop: true });
      axios
        .get(BASE_URL + "/setupparameter/accounting_init/" + e.target.value)
        .then((result) => {
          this.setState({ openBackdrop: false });
          if (result.data.errorcode === 0) {
            this.setState({
              listCategory: result.data.data.listCategory,
            });
          }
        });
    }
  };

  handleChangeUpload = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleSubmit = () => {
    console.log("submit");

    const e = document.getElementById("form1");

    if (
      this.state.acct_model === "" ||
      this.state.acct_category === "" ||
      this.state.acct_desc === "" ||
      this.state.sort === "" ||
      this.state.cr_or_dr === "" ||
      this.state.coa_partner_category === "" ||
      this.state.coa_partner_code === ""
    ) {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }
    const formData = new FormData();
    formData.append("acct_code", this.state.acct_code);
    formData.append("acct_category", this.state.acct_category);
    formData.append("acct_model", this.state.acct_model);
    formData.append("acct_desc", this.state.acct_desc);
    formData.append("sort", this.state.sort);
    formData.append("cr_or_dr", this.state.cr_or_dr);
    formData.append("coa_partner_code", this.state.coa_partner_code);
    formData.append("coa_partner_desc", this.state.coa_partner_desc);
    formData.append("coa_partner_category", this.state.coa_partner_category);
    formData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_ACCOUNTING, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
          loading: false,
        });
      });
  };

  handleSubmit_upload = () => {
    console.log("upload");

    const e = document.getElementById("form2");

    if (this.state.upload_dokumen === "") {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("file", this.state.upload_dokumen);
    formData.append("inserted_by", GetItem("user_id"));
    formData.append("url", REGISTER_SETUP_ACCOUNTING_UPLOAD);

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_ACCOUNTING_UPLOAD, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording1: "Upload dan Simpan",
          loading: false,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Upload Accounting
              </Typography>
              <form id="form2" name="form2" noValidate>
                {" "}
                <br></br>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    name="upload_dokumen"
                    type="file"
                    inputProps={{
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={this.handleChangeUpload}
                    fullWidth
                    size="small"
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit_upload}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
                <br></br>
                <Typography variant="h6" style={{ textAlign: "left" }}>
                  <br></br> Atau Melalui Isi Form
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Account Model
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.acct_model}
                    onChange={this.handleChange}
                    size="small"
                    name="acct_model"
                    label="Account Model"
                  >
                    {this.state.listModel.map((data, idx) => (
                      <MenuItem value={data.ACCT_MODEL}>
                        {data.ACCT_MODEL}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.acct_category}
                    onChange={this.handleChange}
                    size="small"
                    name="acct_category"
                    label="Category"
                  >
                    {this.state.listCategory.map((data, idx) => (
                      <MenuItem value={data.ACCT_CATEGORY}>
                        {data.ACCT_CATEGORY}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <TextField
                  id="user_id"
                  name="acct_code"
                  type="text"
                  label="Accounting Code"
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.acct_code}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
                <TextField
                  id="user_id"
                  name="acct_desc"
                  type="text"
                  label="Accounting Desc"
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.acct_desc}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
                <TextField
                  id="coa_partner_code"
                  name="coa_partner_code"
                  type="text"
                  label="COA Partner Code"
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.coa_partner_code}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
                <TextField
                  id="coa_partner_desc"
                  name="coa_partner_desc"
                  type="text"
                  label="COA Partner Description"
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.coa_partner_desc}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    COA Partner Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.coa_partner_category}
                    onChange={this.handleChange}
                    size="small"
                    name="coa_partner_category"
                    label="COA Partner Category"
                  >
                    {this.state.listCategory.map((data, idx) => (
                      <MenuItem value={data.ACCT_CATEGORY}>
                        {data.ACCT_CATEGORY}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-helper-label">
                    CR / DR
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.cr_or_dr}
                    onChange={this.handleChange}
                    size="small"
                    name="cr_or_dr"
                    label="CR / DR"
                  >
                    <MenuItem value="C">CR (Credit)</MenuItem>
                    <MenuItem value="D">DR (Debit)</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <TextField
                  id="user_id"
                  name="sort"
                  type="number"
                  label="Sort"
                  size="small"
                  variant="outlined"
                  className={classes.textField}
                  value={this.state.sort}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
              </form>
            </Grid>
            <Grid item sm />
          </Grid>

          <Grid container>
            <Grid item sm className={classes.m10}>
              <Typography variant="h6" className={classes.pageTitle}>
                Catatan :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              1. Pastikan format file yang diupload sama dengan format data
              upload. Untuk contoh format data dapat didownload di bagian bawah.
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.m20}
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Typography variant="h6" className={classes.pageTitle}>
                Download Template Upload
              </Typography>
              <Grid container rowSpacing={0.1}>
                <br></br>
                <Grid item xs={4}>
                  <Div>
                    {" "}
                    <a href={format_mortality} download="format_upload.xls">
                      {" "}
                      Download Template{" "}
                    </a>
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
