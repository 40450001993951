import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  BRIDGEGET,
  BASE_URL,
  BRIDGEDELETE,
} from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { GetItem, SaveItem } from "../../../common/util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    axios
      .get(BASE_URL + "setupparameter/list_map_product")
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNewProses}
            component={Link}
            to="/setup/mappingproduk/register"
          >
            Tambah Mapping Product
          </Button>
        </div>
        <br></br>

        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
        />
      </Fragment>
    );
  }
}

export default ProseslistApp;

const columns = [
  { id: "PRODUCT_CODE", label: "Product Code", minWidth: 120 },
  { id: "PRODUCT_NAME", label: "Product Name", minWidth: 80 },
  { id: "IFRS_MODEL", label: "IFRS Model", minWidth: 80 },
  { id: "COHORT_PREFIX", label: "Cohort Prefix", minWidth: 80 },
  { id: "PRODUCT_RISK", label: "Product Risk", minWidth: 80 },
  { id: "PRODUCT_KATEGORY", label: "Product Category", minWidth: 80 },
];

const StickyHeadTable = ({ list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const deleteData = () => {
    axios
      .delete(
        "setupparameter/map_product/" +
          GetItem("PRODUCT_CODE") +
          "/" +
          GetItem("IFRS_MODEL")
      )
      .then((res) => {
        handleCloseDialog();
        setRows([]);
        axios
          .get("setupparameter/list_map_product")
          .then((res) => {
            setRows(res.data.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        handleCloseDialog();
      });
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("-");
    const PRODUCT_CODE = baseVal[0];
    const IFRS_MODEL = baseVal[1];
    SaveItem("PRODUCT_CODE", PRODUCT_CODE);
    SaveItem("IFRS_MODEL", IFRS_MODEL);
    if (baseVal[2] == "update") {
      history.push("/setup/mappingproduk/update");
    } else if (baseVal[2] == "delete") {
      setOpenDialog(true);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin ingin menghapus data ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Batal</Button>
          <Button onClick={deleteData} autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem
                            value={
                              row.PRODUCT_CODE +
                              "-" +
                              row.IFRS_MODEL +
                              "-update"
                            }
                          >
                            Ubah Parameter
                          </MenuItem>
                          <MenuItem
                            value={
                              row.PRODUCT_CODE +
                              "-" +
                              row.IFRS_MODEL +
                              "-delete"
                            }
                          >
                            Hapus Parameter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
