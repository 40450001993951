import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import list from './list';      

export default class BalanceSheet extends React.Component {
 
     render() {

        return (
            <Switch>   
            <Route exact path='/report/acqcost' component={list} /> 
            </Switch>
        );
    }

};