import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { BASE_URL } from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { GetItem, SaveItem } from "../../../common/util";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import withStyles from "@material-ui/core/styles/withStyles";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Autocomplete from "@mui/material/Autocomplete";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      listPrmModel: [],
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    this.getParamModel();
  }

  getParamModel = () => {
    axios
      .get(BASE_URL + "setupparameter/list_prm_model_lapse_rate")
      .then((res) => {
        this.setState({
          listPrmModel: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          listPrmModel: [],
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNewProses}
            component={Link}
            to="/setup/lapserate/register"
          >
            Tambah Lapse Rate
          </Button>
        </div>
        <br></br>

        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
          classes={classes}
          listPrmModel={this.state.listPrmModel}
          getParamModel={this.getParamModel}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "AGE", label: "AGE", minWidth: 80 },
  { id: "Lapse_Rate", label: "Lapse Rate", minWidth: 80 },
  { id: "PRM_MODEL", label: "PRM Model", minWidth: 120 },
];

const StickyHeadTable = ({
  list,
  isLoading,
  classes,
  listPrmModel,
  getParamModel,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [searched, setSearched] = React.useState("");
  const [prm_model, setPrm_model] = React.useState("");
  const [listPRMModel, setListPRMModel] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [dialogOpenDuplikat, setDialogOpenDuplikat] = React.useState(false);
  const [inputTextDuplikat, setInputTextDuplikat] = React.useState("");
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnackError, setOpenSnackError] = React.useState(false);
  const [messageSnack, setMessageSnack] = React.useState("");
  const [messageSnackError, setMessageSnackError] = React.useState("");

  useEffect(() => setListPRMModel(listPrmModel), [listPrmModel]);
  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const deleteData = () => {
    axios
      .delete(
        "setupparameter/lapse_rate/" +
          GetItem("age") +
          "/" +
          GetItem("prm_model")
      )
      .then((res) => {
        setRows([]);
        handleCloseDialog();
        axios
          .get("setupparameter/list_lapse_rate")
          .then((res) => {
            setRows(res.data.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        handleCloseDialog();
      });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("#");
    const age = baseVal[0];
    const prm_model = baseVal[1];
    SaveItem("age", age);
    SaveItem("prm_model", prm_model);
    if (baseVal[2] === "update") {
      history.push("/setup/lapserate/update");
    } else if (baseVal[2] == "delete") {
      setOpenDialog(true);
    }
  };

  const handleSelectOption = (e) => {
    const value = e.target.value;
    if (value === "reset") {
      setKeyword_cari("");
      setSearched("");
      requestSearch("");
    } else {
      setKeyword_cari(value);
    }
  };
  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };
  const resetDataModal = () => {
    setRows([]);
    setPrm_model("");
    setKeyword_cari("");
    setSearched("");
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  const setDataModel = () => {
    if (prm_model === "") {
      alert("Pilih Jenis PRM Model terlebih dahulu");
      return false;
    }
    if (searched !== "" && keyword_cari === "") {
      alert("Pilih cari berdasarkan terlebih dahulu");
      return false;
    }
    setRows([]);
    setOpenBackdrop(true);
    axios
      .get(
        "setupparameter/list_lapse_rate/" +
          prm_model +
          "/" +
          keyword_cari +
          "/" +
          searched
      )
      .then((res) => {
        setOpenBackdrop(false);
        setRows(res.data.data);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        setRows([]);
      });
  };
  const handleChangeJenisModel = (event, newValue) => {
    const value = newValue.PRM_MODEL;
    if (value === "") {
      setPrm_model("");
    } else {
      setPrm_model(value);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleSaveDuplikat = () => {
    setOpenBackdrop(true);
    setDialogOpenDuplikat(false);

    const formData = new FormData();
    formData.append("new_param_model", inputTextDuplikat);
    formData.append("param_model", prm_model);
    formData.append("user_id", GetItem("user_id"));

    axios
      .post("setupparameter/lapse_rate_duplikat", formData)
      .then((res) => {
        if (res.data.message === "success") {
          setInputTextDuplikat("");
          setOpenBackdrop(false);
          getParamModel();
          setRows([]);
          setPrm_model("");
          setOpenSnack(true);
          setMessageSnack("Berhasil duplikat data");
        } else {
          setInputTextDuplikat("");
          setOpenSnackError(true);
          setMessageSnackError(res.data.data);
          setOpenBackdrop(false);
        }
      })
      .catch((err) => {
        setInputTextDuplikat("");
        setOpenBackdrop(false);
      });
  };

  const handleOpenDialogDuplikat = () => {
    if (prm_model === "") {
      alert("Pilih PRM Model dan klik cari terlebih dahulu");
      return false;
    }
    setDialogOpenDuplikat(true);
  };

  const handleCloseDialogDuplikat = () => {
    setDialogOpenDuplikat(false);
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={openSnack}
        autoHideDuration={1000}
        // onClose={(event, reason) => this.handleClose(event, reason)}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          variant="filled"
          onClose={() => {
            setOpenSnack(false);
          }}
        >
          {messageSnack}
        </Alert>
      </Snackbar>
      <Snackbar open={openSnackError} autoHideDuration={2000}>
        <Alert
          severity="error"
          sx={{ width: "100%" }}
          variant="filled"
          onClose={() => {
            setOpenSnackError(false);
          }}
        >
          {messageSnackError}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth="md"
        fullWidth
        style={{ maxWidth: 550, margin: "auto" }}
        open={dialogOpenDuplikat}
        onClose={handleCloseDialogDuplikat}
      >
        <DialogContent>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputLabel
              htmlFor="custom-text-field"
              style={{ marginBottom: 10 }}
            >
              Masukan nama model untuk duplikat data
            </InputLabel>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <InputLabel
              htmlFor="custom-text-field"
              style={{ fontWeight: "bold" }}
            >
              LAPSERATE-
            </InputLabel>
            <TextField
              value={inputTextDuplikat}
              label="Nama Model"
              variant="outlined"
              inputProps={{
                style: { textTransform: "uppercase" },
                maxLength: 15, // Jumlah maksimal karakter, sesuaikan dengan kebutuhan Anda
              }}
              size="small"
              style={{ width: "250px" }}
              onChange={(e) =>
                setInputTextDuplikat(
                  e.target.value
                    .replace(/\s/g, "")
                    .replace(/[^a-zA-Z0-9-]/g, "")
                )
              }
              fullWidth
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveDuplikat} color="primary">
            Simpan
          </Button>
          <Button onClick={handleCloseDialogDuplikat} color="secondary">
            Tutup
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={listPRMModel}
            onChange={handleChangeJenisModel}
            name="prm_model"
            getOptionLabel={(option) => option.PRM_MODEL}
            value={
              listPRMModel.find((option) => option.PRM_MODEL === prm_model) ||
              null
            }
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Pilih Model" variant="outlined" />
            )}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Cari Berdasarkan
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={keyword_cari}
              onChange={handleSelectOption}
              size="small"
              name="keyword_cari"
              label="Cari Berdasarkan"
            >
              <MenuItem value="age">Age</MenuItem>
              <MenuItem value="rate">Rate</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <SearchBar
            className={classes.search}
            value={searched}
            onChange={(searchedVal) => requestSearch(searchedVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="cari disini.."
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={setDataModel}>
            Cari
          </Button>
          &nbsp; &nbsp;
          <Button variant="contained" color="primary" onClick={resetDataModal}>
            Reset
          </Button>
          &nbsp; &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialogDuplikat}
          >
            Duplikat Data
          </Button>
        </Grid>
      </Grid>

      <br />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin ingin menghapus data ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Batal</Button>
          <Button onClick={deleteData} autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem
                            value={row.AGE + "#" + row.PRM_MODEL + "#update"}
                          >
                            Ubah Parameter
                          </MenuItem>
                          <MenuItem
                            value={row.AGE + "#" + row.PRM_MODEL + "#delete"}
                          >
                            Hapus Parameter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
