import React, { Component, Fragment, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";

import TablePagination from "@mui/material/TablePagination";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { SaveItem, GetItem, RemoveItem } from "../../common/util";
import Paper from "@mui/material/Paper";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const styles = {
  pageTitle: {
    textAlign: "center",
  },
};

class ProsesDetailApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      valid: false,
      progress: 83,
      messageError: "",
      openSnackError: false,
      message: "",
      closing_id: "",
      showForm: false,
      isLoading: false,
      openBackdrop: false,
      openSnack: false,
      disabled_option: false,
      btnWording: "Simpan",
      bulan_laporan: "",
      tahun_laporan: "",
      nama_bulan: "",
      isPeriodOpen: false,
      wordingPeriode: "",
    };
  }

  componentDidMount() {
    RemoveItem("upload_id");
    RemoveItem("jenis_file");
    RemoveItem("upload_ulang");
    this.setState({ openBackdrop: true });
    axios
      .get("prosesdata/listUpload/transisi")
      .then((res) => {
        this.setState({ openBackdrop: false });
        // SaveItem('isPeriodOpen',res.data.data.isOpen)
        SaveItem("closing_id", res.data.data.closing_id);
        SaveItem("bulan_laporan", res.data.data.nama_bulan);
        SaveItem("tahun_laporan", res.data.data.tahun_laporan);
        this.setState({
          list: res.data.data.list,
          isPeriodOpen: res.data.data.isOpen,
          closing_id: res.data.data.closing_id,
          bulan_laporan: res.data.data.bulan_laporan,
          tahun_laporan: res.data.data.tahun_laporan,
          nama_bulan: res.data.data.nama_bulan,
          wordingPeriode:
            "Periode " +
            res.data.data.tahun_laporan +
            " " +
            res.data.data.nama_bulan,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <div>
            <Button
              style={{
                display: this.state.isPeriodOpen == false ? "none" : "",
              }}
              variant="contained"
              color="primary"
              onClick={this.handleNewUpload}
              component={Link}
              to="/saldoawaltransisi/upload"
            >
              Upload File Baru
            </Button>
            <Typography variant="h6" className={classes.pageTitle}>
              List File Upload Periode Transisi
            </Typography>
          </div>
          <br></br>

          <Paper
            variant="outlined"
            style={{ display: this.state.isPeriodOpen == true ? "none" : "" }}
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" className={classes.pageTitle}>
              Periode Transisi belum dibuat
            </Typography>
          </Paper>

          <Paper
            variant="outlined"
            style={{ display: this.state.isPeriodOpen == false ? "none" : "" }}
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.list}
              showProgress={this.state.isLoading}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProsesDetailApp);

const columns = [
  // { id: 'jenis_file', label: 'Jenis File', minWidth: 50 },
  { id: "tahun_laporan", label: "Tahun Periode", minWidth: 80 },
  { id: "nama_bulan", label: "Bulan Periode", minWidth: 80 },
  { id: "TGL_UPLOAD_", label: "Tanggal Upload", minWidth: 80 },
  { id: "file_upload", label: "Nama File", minWidth: 120 },
  { id: "data_count", label: "Jumlah Data", minWidth: 50 },
  // { id: 'error_count', label: 'Jumlah Data Error', minWidth: 50 },
];

const StickyHeadTable = ({ openBackdrop, list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [obackDrop, setObackDrop] = React.useState(openBackdrop);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setObackDrop(openBackdrop), [openBackdrop]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("-");
    const upload_id = baseVal[0];
    if (baseVal[1] === "hapus") {
      setOpenDialog(true);
      SaveItem("delete_id", upload_id);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteData = () => {
    setOpenDialog(false);
    setObackDrop(true);
    axios
      .delete(
        "prosesdata/delete_file/" +
          GetItem("delete_id") +
          "/" +
          GetItem("closing_id") +
          "/" +
          GetItem("user_id")
      )
      .then((res) => {
        setObackDrop(false);
        if (res.data.message === "success") {
          setRows(res.data.data);
          alert("Berhasil Hapus File");
        } else {
          alert("Gagal Hapus File");
        }
      })
      .catch((err) => {
        setObackDrop(false);
        alert("Gagal Hapus File");
      });
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={obackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin ingin menghapus data ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Batal</Button>
          <Button onClick={deleteData} autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center">
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="edit" align="center" style={{ minWidth: 30 }}>
                Aksi
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem value={row.upload_id + "-hapus"}>
                            Hapus File
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
