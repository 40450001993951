import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  ValidateData,
  ValidateDataEmail,
  ValidateEmail,
  GetItem,
} from "../../../common/util";
import {
  BRIDGEGET,
  BRIDGEPUT,
  BASE_URL,
} from "../../../common/constantVariable";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "5px auto 5px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class UserUpdateApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ACCT_CODE: GetItem("ACCT_CODE"),
      ACCT_MODEL: GetItem("ACCT_MODEL"),
      acct_model: "",
      acct_code: "",
      cr_or_dr: "",
      coa_partner_desc: "",
      coa_partner_code: "",
      coa_partner_category: "",
      sort: "",
      acct_desc: "",
      acct_category: "",
      listCategory: [],
      listModel: [],
      inserted_by: GetItem("user_id"),
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get(
        BASE_URL +
          "setupparameter/list_accounting/" +
          this.state.ACCT_MODEL +
          "/" +
          this.state.ACCT_CODE
      )
      .then((result) => {
        this.setState({ openBackdrop: false });
        if (Object.keys(result.data.data).length > 0) {
          const data = result.data.data[0];
          this.setState({
            acct_model: data.ACCT_MODEL,
            acct_code: data.ACCT_CODE,
            cr_or_dr: data.CR_OR_DR,
            sort: data.SORT,
            acct_desc: data.ACCT_DESC,
            acct_category: data.ACCT_CATEGORY,
            coa_partner_code: data.COA_PARTNER,
            coa_partner_desc: data.acct_desc2,
          });
        }
      });

    axios.get(BASE_URL + "/setupparameter/accounting_init").then((result) => {
      if (result.data.errorcode === 0) {
        this.setState({
          listCategory: result.data.data.listCategory,
          listModel: result.data.data.listModel,
        });
      }
    });
  }

  handleSubmit = () => {
    const e = document.getElementById("form1");

    if (ValidateData(e.elements) === false) {
      this.setState({
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    this.setState({ openBackdrop: true });
    axios
      .put(
        "setupparameter/accounting/" +
          this.state.ACCT_CODE +
          "/" +
          this.state.ACCT_MODEL,
        this.state
      )
      .then((res) => {
        this.setState({ openBackdrop: false });

        if (res.data.message != "success") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errormessage: "Gagal simpan data",
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    if (e.target.name === "acct_model") {
      this.setState({ openBackdrop: true });
      axios
        .get(BASE_URL + "/setupparameter/accounting_init/" + e.target.value)
        .then((result) => {
          this.setState({ openBackdrop: false });
          if (result.data.errorcode === 0) {
            this.setState({
              listCategory: result.data.data.listCategory,
            });
          }
        });
    }
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Grid item sm />
        <Grid item sm>
          <Typography variant="h6" className={classes.pageTitle}>
            Update Accounting {this.state.ACCT_CODE}
          </Typography>

          <form id="form1" name="form1" noValidate>
            <TextField
              id="user_id"
              name="acct_code"
              type="text"
              label="Accounting Code"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
              className={classes.textField}
              value={this.state.acct_code}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <FormControl variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Account Model
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.acct_model}
                onChange={this.handleChange}
                size="small"
                name="acct_model"
                readOnly
                label="Account Model"
              >
                {this.state.listModel.map((data, idx) => (
                  <MenuItem value={data.ACCT_MODEL}>{data.ACCT_MODEL}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <br></br>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.acct_category}
                onChange={this.handleChange}
                size="small"
                name="acct_category"
                label="Category"
              >
                {this.state.listCategory.map((data, idx) => (
                  <MenuItem value={data.ACCT_CATEGORY}>
                    {data.ACCT_CATEGORY}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <br></br>
            <TextField
              id="user_id"
              name="acct_desc"
              type="text"
              label="Accounting Desc"
              size="small"
              variant="outlined"
              InputProps={{
                readOnly: true,
              }}
              className={classes.textField}
              value={this.state.acct_desc}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="coa_partner_code"
              name="coa_partner_code"
              type="text"
              label="COA Partner Code"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.coa_partner_code}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="coa_partner_desc"
              name="coa_partner_desc"
              type="text"
              label="COA Partner Description"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.coa_partner_desc}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                COA Partner Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.coa_partner_category}
                onChange={this.handleChange}
                size="small"
                name="coa_partner_category"
                label="COA Partner Category"
              >
                {this.state.listCategory.map((data, idx) => (
                  <MenuItem value={data.ACCT_CATEGORY}>
                    {data.ACCT_CATEGORY}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br></br>
            <br></br>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                CR / DR
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.state.cr_or_dr}
                onChange={this.handleChange}
                size="small"
                name="cr_or_dr"
                label="CR / DR"
              >
                <MenuItem value="C">CR (Credit)</MenuItem>
                <MenuItem value="D">DR (Debit)</MenuItem>
              </Select>
            </FormControl>
            <br></br>
            <br></br>
            <TextField
              id="user_id"
              name="sort"
              type="number"
              label="Sort"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.sort}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Simpan
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserUpdateApp);
