import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../common/util";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class RoleUpdateApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      role_id: "",
      role_name: "",
      role_name1: "",
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
    };
    // console.log(this.state);
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios.get("role/role/" + this.props.match.params.id).then((result) => {
      this.setState({ openBackdrop: false });
      if (Object.values(result.data.data).length > 0) {
        const dt = result.data.data;
        this.setState({
          role_id: this.props.match.params.id,
          role_name1: dt.role_name,
          role_name: dt.role_name,
        });
      }
    });
  }

  handleSubmit = () => {
    const e = document.getElementById("form1");

    if (ValidateData(e.elements) == false) {
      this.setState({
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    const data = {
      role_id: this.state.role_id,
      role_name: this.state.role_name,
      inserted_by: GetItem("user_id"),
    };

    this.setState({ openBackdrop: true });
    axios
      .put("role/role/" + this.state.role_id, data)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message != "success") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          errormessage: "Gagal update data",
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };
  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Grid item sm />
        <Grid item sm>
          <Typography variant="h4" className={classes.pageTitle}>
            Update {this.state.role_name1}
          </Typography>

          <form id="form1" name="form1" noValidate>
            <TextField
              id="username"
              name="role_name"
              type="text"
              variant="outlined"
              size="small"
              label="Nama Role"
              className={classes.textField}
              helperText={this.state.helpers["role_name"]}
              error={this.state.errors["role_name"]}
              value={this.state.role_name}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Simpan
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(RoleUpdateApp);
