import React, { Component, Fragment, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";

import TablePagination from "@mui/material/TablePagination";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { SaveItem, GetItem } from "../../common/util";
import Paper from "@mui/material/Paper";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import {
  BRIDGEGET,
  BASE_URL,
  BRIDGEDELETE,
} from "../../common/constantVariable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const styles = {
  pageTitle: {
    textAlign: "center",
  },
};

class ProsesDetailApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      valid: false,
      messageError: "",
      openSnackError: false,
      message: "",
      upload_id: "",
      closing_id: "",
      jenis_file: "",
      nama_file: "",
      showForm: false,
      openBackdrop: false,
      openSnack: false,
      disabled_option: false,
      download_file: "",
      btnWording: "Simpan",
      bulan_laporan: "",
      tahun_laporan: "",
    };
  }

  componentDidMount() {
    this.setState({ upload_id: GetItem("upload_id") });
    this.setState({ openBackdrop: true });

    axios
      .get("prosesdata/detailKualitasData/" + GetItem("upload_id"))
      .then((res) => {
        this.setState({ openBackdrop: false });
        SaveItem("closing_id", res.data.data.closing_id);
        SaveItem("jenis_file", res.data.data.jenis_file);
        SaveItem("bulan_laporan", res.data.data.bulan_laporan);
        SaveItem("tahun_laporan", res.data.data.tahun_laporan);
        SaveItem("upload_ulang", "1");
        this.setState({
          list: res.data.data.list,
          nama_file: res.data.data.nama_file,
          download_file: res.data.data.link_download,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <div>
            <Button
              style={{
                display: this.state.isPeriodOpen == false ? "none" : "",
              }}
              variant="contained"
              color="primary"
              component={Link}
              to="/prosesdata/upload"
            >
              Upload Ulang
            </Button>
            &nbsp;&nbsp;
            <a target="_blank" href={this.state.download_file}>
              <Button variant="contained" color="primary">
                Download Kembali File
              </Button>
            </a>
            &nbsp; &nbsp;{" "}
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Kembali
            </Button>
            <br></br>
            <br></br>
            <Typography variant="h6" className={classes.pageTitle}>
              Detail Kualitas Data File <br></br>
              {this.state.nama_file}
              <br></br>
            </Typography>
          </div>
          <br></br>

          <Paper variant="outlined">
            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.list}
              showProgress={this.state.isLoading}
            />
          </Paper>
          <br></br>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProsesDetailApp);

const columns = [
  { id: "field_nm", label: "Error Column", minWidth: 80 },
  { id: "rownumber", label: "Record Number", minWidth: 120 },
  { id: "return_desc", label: "Error Description", minWidth: 200 },
];

const StickyHeadTable = ({ openBackdrop, list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(150);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [uploadId, setUploadId] = React.useState("");
  const [obackDrop, setObackDrop] = React.useState("");

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setObackDrop(obackDrop), [obackDrop]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center">
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[150, 250, 500]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
