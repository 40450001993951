import React, { Component, useState}  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
// import AppIcon  from '.\www14.png';
import axios from 'axios'; 

import Grid  from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import TextField  from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import {ValidateData,GetItem} from '../../common/util';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'; 
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom'; 
  
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup'; 
import { UPLOAD_FILE,BRIDGEPOSTFILE } from '../../common/constantVariable';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 

const styles = {
    form: {
        textAlign: 'center'
    },
    image: {
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '10px auto 10px auto'
    },
    button: {
        marginTop: 10
    },
    
    m10: {
        marginTop: 10
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    }
};
const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  }));

class tambahAdjustmentApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {  
            upload_dokumen: '',   
            closing_id : GetItem('closing_id'), 
            bulan_laporan : GetItem('bulan_laporan'), 
            tahun_laporan : GetItem('tahun_laporan'), 
            jenis_file : '',
            valid: false,
            messageError : '',  
            message : '',
            showForm : false,
            openSnackError : false,
            Backdrop : false,
            openSnack : false,
            disabled_option : false,
            btnWording : 'Proses Adjustment'
        }
    }
    
    componentDidMount() {  
         
 
    }

    handleChange = (e) => {
        const error = e.target.required && e.target.value===''; 

        this.setState({
            [e.target.name]: e.target.value,
            errormessage: '',
            errors: this.state.errors, 
            helpers: this.state.helpers
          })
      

    };

      handleChangeUpload = (e) => {      
        this.setState({
          [e.target.name]: e.target.files[0]
        })
      };
 
    handleSubmit = () => {

        const e = document.getElementById("form1"); 
        const valid = ValidateData(e.elements);

        if (this.state.upload_dokumen === '' || this.state.jenis_file === '') {
            this.setState({
                message : 'Data tidak lengkap, mohon lengkapi data!',
                btnWording : 'Proses Adjustment',
                openSnackError : true
            })  
            return false;
        }

        this.setState({ 
            btnWording : 'sedang mengupload dokumen',
            isLoading : true
        })


        
        const formData = new FormData() 
        formData.append('upload_dokumen', this.state.upload_dokumen)   
        formData.append('closing_id', this.state.closing_id) 
        formData.append('jenis_file', this.state.jenis_file) 
        formData.append('url', UPLOAD_FILE) 
        
        this.setState({openBackdrop:true})
            axios.post(UPLOAD_FILE, formData)
            .then((res) => { 
                this.setState({openBackdrop:false})
                if(res.data.message !== 'success'){
                    this.setState({
                        message : res.data.data,
                        btnWording : 'Proses Adjustment',
                        openSnackError : true
                    })
                }else
                {
                    this.setState({
                        message : res.data.data,
                        btnWording : 'Proses Adjustment',
                        openSnack : true
                    })
                }
            })
            .catch( (err) => {
                this.setState({openBackdrop:false})
                this.setState({
                    errors      : err.response.data.data,
                    message     : err.response.data.data,
                    openSnackError   : true,
                    btnWording : 'Proses Adjustment',
                    loading     : false
                })
            });
         
        
    }

   
    handleClose = (event, reason) => {
        this.setState({openSnack:false});
        if (reason === 'timeout') {
            window.history.back(-1);
          }
      };

    render() { 
        const { classes } = this.props; 

        return (
            <Box>
                <Paper  variant="outlined"
                            sx={{
                                p: 1,
                                margin: 'auto',
                                flexGrow: 1,
                            }}
                            >
                    <Grid container className={classes.form} >
        
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={this.state.openBackdrop}
                            onClick={this.handleClose}
                            >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    
                            <Snackbar open={this.state.openSnack} autoHideDuration={1000} 
                                onClose={(event, reason) => this.handleClose(event, reason)}>
                                <Alert severity="success" sx={{ width: '100%' }} variant="filled" 
                                onClose={() => {this.setState({openSnack:false}); window.history.back(-1)}}>                
                                    {this.state.message}
                                </Alert>
                            </Snackbar>
                            <Snackbar open={this.state.openSnackError} autoHideDuration={2000} >
                                <Alert severity="error" sx={{ width: '100%' }} variant="filled" 
                                onClose={() => {this.setState({openSnackError:false})}}>                
                                    {this.state.message}
                                </Alert>
                            </Snackbar> 
                        <Grid item sm/>
                        <Grid item sm>
                        
                            <Typography variant="h6" className={classes.pageTitle}>
                                Upload dan Tambah Adjustment 
                            </Typography> 
                            <Typography variant="h6" className={classes.pageTitle}>
                                Periode {this.state.bulan_laporan} {this.state.tahun_laporan}
                            </Typography>
                            <form id="form1" name="form1" noValidate > <br></br> 
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Jenis File</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        value={this.state.jenis_file} 
                                        onChange={this.handleChange}
                                        size="small"
                                        name="jenis_file"
                                        label="Jenis File" 
                                        > 
                                        <MenuItem value="GMM">GMM</MenuItem>
                                        <MenuItem value="PAA">PAA</MenuItem>
                                        <MenuItem value="VFA">VFA</MenuItem>
                                        <MenuItem value="LIC">LIC</MenuItem>
                                        <MenuItem value="TRANSAKSI KLAIM">TRANSAKSI KLAIM</MenuItem>
                                        <MenuItem value="TRANSAKSI NON ASURANSI">TRANSAKSI EXPENSE</MenuItem>
                                        
                                        </Select>
                                    </FormControl>
                                    <FormGroup className={classes.m10}>
                                        <TextField
                                        variant='outlined'
                                        name="upload_dokumen"
                                        type="file" 
                                        inputProps={{ accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' }}
                                        onChange={this.handleChangeUpload} 
                                        fullWidth
                                        size="small"
                                        />
                                    </FormGroup><br></br>        
                        
                        

                                <Button variant='contained' color='primary' className={classes.button} onClick={this.handleSubmit}>
                                    {this.state.btnWording}
                                </Button> &nbsp;&nbsp;&nbsp;

                                <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                                    Batal
                                </Button>

                            </form> 
                        </Grid>
                        <Grid item sm />
                    </Grid>
                    
                    <Grid container>      
                                    <Grid item sm className={classes.m10}> 
                                        <Typography variant="h6" className={classes.pageTitle}>
                                            Catatan :
                                        </Typography> 
                                    </Grid>
                                    <Grid item xs={12}>
                                        1. Pastikan pilihan Jenis upload sama dengan format data yg diupload. Untuk contoh format data dapat didownload di bagian bawah.
                                    </Grid>
                                    <Grid item xs={12}>
                                        2. Pastikan kode produk yg diupload sudah dimapping di menu setup produk.
                                    </Grid>
                                </Grid>
                </Paper>
                <Paper className={classes.m20}  variant="outlined"
                            sx={{
                                p: 1,
                                margin: 'auto',
                                marginTop:'10px',
                                flexGrow: 1,
                            }}
                >
                    <Grid container>      
                        <Grid item sm> 
                            <Typography variant="h6" className={classes.pageTitle}>
                                Download Template Upload
                            </Typography> 
                            <Grid container rowSpacing={0.1}>
                            <br></br> 
                             

                        
                        </Grid>
                        </Grid> 
                    </Grid>

                </Paper>
            </Box>
            


        )
    }
}

export default withStyles(styles) (tambahAdjustmentApp);




