import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "@material-ui/core/Button";
import "../pages/login.css";

const CaptchaButton = ({ setToken }) => {
  const captchaRef = useRef(null);
  // const { classes } = this.props;

  const handleClick = () => {
    // Execute reCAPTCHA when the button is clicked
    captchaRef.current.execute();
  };

  const onRecaptchaChange = (token) => {
    console.log('Captcha value:', token);
    // Reset the reCAPTCHA after obtaining the token
    // captchaRef.current.reset();
    setToken(token);

    // Perform further actions with the token (e.g., send it to your server)
  };

  return (
    <div className='recaptcha-container'>
      <ReCAPTCHA
        sitekey="6LcsjhAqAAAAAAg5qm-LIgDc9bDk1tXgiH8z-wS0"
        ref={captchaRef}
        onChange={onRecaptchaChange}
      />
      {/* <button onClick={handleClick}>Submit</button> */}
      <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleClick}
        >
          Log In
        </Button>
    </div>
  );
};

export default CaptchaButton;