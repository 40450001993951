import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ValidateData, GetItem } from "../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import FORMAT_UPLOAD from "./assets/format upload.xlsx";

import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import { REGISTER_SETUP_RIDER1_RATE_UPLOAD } from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload_dokumen: "",
      age: "",
      rate: "",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",

      listBulanPeriode: [],
      listTahunPeriode: [],
      bulan_laporan: "",
      tahun_laporan: "",
      year: new Date().getFullYear(),
      minYear: 2010,
      param_model: "",
    };
  }

  componentDidMount() {
    const bulan = [
      {
        value: "01",
        label: "Januari",
      },
      {
        value: "02",
        label: "Februari",
      },
      {
        value: "03",
        label: "Maret",
      },
      {
        value: "04",
        label: "April",
      },
      {
        value: "05",
        label: "Mei",
      },
      {
        value: "06",
        label: "Juni",
      },
      {
        value: "07",
        label: "Juli",
      },
      {
        value: "08",
        label: "Agustus",
      },
      {
        value: "09",
        label: "September",
      },
      {
        value: "10",
        label: "Oktober",
      },
      {
        value: "11",
        label: "November",
      },
      {
        value: "12",
        label: "Desember",
      },
    ];

    this.setState({ listBulanPeriode: bulan });
  }

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    if (e.target.name === "param_model") {
      let inputValue = e.target.value;
      let value = inputValue.replace(/[^a-zA-Z0-9-]/g, "");
      value = value.replace(/\s/g, "");
      e.target.value = value;
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleChangeUpload = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleSubmit_upload = () => {
    console.log("upload");

    const e = document.getElementById("form2");

    if (this.state.upload_dokumen === "") {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("file", this.state.upload_dokumen);
    formData.append("param_model", this.state.param_model);
    formData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_RIDER1_RATE_UPLOAD, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording1: "Upload dan Simpan",
          loading: false,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Parameter Model
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <InputLabel htmlFor="custom-text-field">RIDER1RATE-</InputLabel>
                <TextField
                  id="param_model"
                  name="param_model"
                  type="text"
                  label="Parameter Model"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    style: { textTransform: "uppercase" },
                    maxLength: 15, // Jumlah maksimal karakter, sesuaikan dengan kebutuhan Anda
                  }}
                  style={{ width: "280px" }}
                  className={classes.textField}
                  value={this.state.param_model}
                  onChange={this.handleChange}
                  required
                  fullWidth
                />
              </div>

              <br></br>
              <Typography variant="h6" style={{ textAlign: "left" }}>
                Upload Benefit Rate
              </Typography>
              <form id="form2" name="form2" noValidate>
                {" "}
                <br></br>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    name="upload_dokumen"
                    type="file"
                    inputProps={{
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={this.handleChangeUpload}
                    fullWidth
                    size="small"
                  />
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit_upload}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
                <br></br>
              </form>
            </Grid>
            <Grid item sm />
          </Grid>

          <Grid container>
            <Grid item sm className={classes.m10}>
              <Typography variant="h6" className={classes.pageTitle}>
                Catatan :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              1. Pastikan format file yang diupload sama dengan format data
              upload. Untuk contoh format data dapat didownload di bagian bawah.
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.m20}
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Typography variant="h6" className={classes.pageTitle}>
                Download Template Upload
              </Typography>
              <Grid container rowSpacing={0.1}>
                <br></br>
                <Grid item xs={4}>
                  <Div>
                    {" "}
                    <a href={FORMAT_UPLOAD} download="format_upload.xlsx">
                      {" "}
                      Download Template Upload
                    </a>
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
