import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import RoleListApp from './rolelist'; 
import RoleRegisterApp from './register';
import RoleUpdateApp from './update';


export default class RoleApp extends React.Component {

     render() {

        return (
            <Switch> 
            <Route exact path='/setuprole/register' component={RoleRegisterApp} />
            <Route exact path='/setuprole/edit/:id' component={RoleUpdateApp} />
            <Route exact path='/setuprole/**' component={RoleListApp} />
            </Switch>
        );
    }

};