import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { GetItem, RemoveItem, SaveItem } from "../../../common/util";

import withStyles from "@material-ui/core/styles/withStyles";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      message: "",
    };
  }

  componentDidMount() {
    axios
      .get("setupparameter/list_balancesheet_param")
      .then((res) => {
        this.setState({
          list: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          list: [],
        });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div></div>
        <br></br>

        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
          classes={classes}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "BS_CODE", label: "Code", minWidth: 80 },
  { id: "BS_DESC", label: "Desc Default", minWidth: 50 },
  { id: "BS_REPORT", label: "Report Type", minWidth: 80 },
  { id: "BS_MODEL", label: "Model", minWidth: 80 },
  { id: "BS_DESC_PARTNER", label: "Desc Partner", minWidth: 120 },
];

const StickyHeadTable = ({ list, isLoading, classes }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [searched, setSearched] = React.useState("");

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("#");
    const code = baseVal[0];

    RemoveItem("CODE");
    SaveItem("CODE", code);
    history.push("/setup/balancesheet/update");
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleSelectOption = (e) => {
    const value = e.target.value;
    if (value === "reset") {
      setKeyword_cari("");
      setSearched("");
      requestSearch("");
    } else {
      setKeyword_cari(value);
    }
  };
  const requestSearch = (searchedVal) => {
    if (keyword_cari !== "") {
      const filteredRows = list.filter((row) => {
        if (keyword_cari === "code") {
          return row.BS_CODE.toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        } else if (keyword_cari === "desc_default") {
          return row.BS_DESC.toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        } else if (keyword_cari === "desc_partner") {
          return row.BS_DESC_PARTNER.toString()
            .toLowerCase()
            .includes(searchedVal.toLowerCase());
        }
      });
      setRows(filteredRows);
    }
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Cari Berdasarkan
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={keyword_cari}
              onChange={handleSelectOption}
              size="small"
              name="keyword_cari"
              label="Cari Berdasarkan"
            >
              <MenuItem value="code">Code</MenuItem>
              <MenuItem value="desc_default">Desc Default</MenuItem>
              <MenuItem value="desc_partner">Desc Partner</MenuItem>
              <MenuItem value="reset">Reset</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <SearchBar
            className={classes.search}
            value={searched}
            onChange={(searchedVal) => requestSearch(searchedVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="cari disini.."
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem value={row.BS_CODE}>
                            Ubah Parameter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
