import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { GetItem } from "../../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import format_upload from "./assets/format upload.xlsx";

import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import { REGISTER_SETUP_BENEFIT_3_UPLOAD } from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      upload_dokumen: "",
      mth: "",
      interest: "",
      coverage_period: "",
      db_rate: "",
      valid: false,
      messageError: "",
      kd_produk: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",
    };
  }

  componentDidMount() {}

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleChangeUpload = (e) => {
    this.setState({
      [e.target.name]: e.target.files[0],
    });
  };

  handleSubmit_upload = () => {
    const e = document.getElementById("form2");

    if (this.state.upload_dokumen === "") {
      this.setState({
        message: "Data tidak lengkap, mohon lengkapi data!",
        btnWording: "Simpan",
        openSnackError: true,
      });
      return false;
    }

    const formData = new FormData();
    formData.append("file", this.state.upload_dokumen);
    formData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_BENEFIT_3_UPLOAD, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording1: "Upload dan Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording1: "Upload dan Simpan",
          loading: false,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm />
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                Upload Data Benefit 3
              </Typography>
              <form id="form2" name="form2" noValidate>
                {" "}
                <br></br>
                <FormGroup>
                  <TextField
                    variant="outlined"
                    name="upload_dokumen"
                    type="file"
                    inputProps={{
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    onChange={this.handleChangeUpload}
                    fullWidth
                    size="small"
                  />
                </FormGroup>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit_upload}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
              </form>
            </Grid>
            <Grid item sm />
          </Grid>

          <Grid container>
            <Grid item sm className={classes.m10}>
              <Typography variant="h6" className={classes.pageTitle}>
                Catatan :
              </Typography>
            </Grid>
            <Grid item xs={12}>
              1. Upload ini akan menghapus data kode produk yang sudah ada
              sebelum nya, jika ingin tambah data pastikan semua data untuk kode
              produk yang akan diupload sudah lengkap di excel upload.
            </Grid>
            <Grid item xs={12}>
              2. Pastikan format file yang diupload sama dengan format data
              upload. Untuk contoh format data dapat didownload di bagian bawah.
            </Grid>
            <Grid item xs={12}>
              3. Pastikan kode produk yg diupload sudah dimapping di menu setup
              mapping produk model.
            </Grid>
          </Grid>
        </Paper>
        <Paper
          className={classes.m20}
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            marginTop: "10px",
            flexGrow: 1,
          }}
        >
          <Grid container>
            <Grid item sm>
              <Typography variant="h6" className={classes.pageTitle}>
                Download Template Upload
              </Typography>
              <Grid container rowSpacing={0.1}>
                <br></br>
                <Grid item xs={4}>
                  <Div>
                    {" "}
                    <a href={format_upload} download="format_upload.xls">
                      {" "}
                      Download Template Benefit
                    </a>
                  </Div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
