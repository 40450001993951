import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { BASE_URL } from "../../../common/constantVariable";
import { GetItem, ValidateData } from "../../../common/util";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  REGISTER_SETUP_MAP_PRODUK,
  REGISTER_SETUP_MAP_PRODUK_UPLOAD,
} from "../../../common/constantVariable";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "left",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
    width: 250,
  },
  textField2: {
    margin: "10px auto 10px auto",
    width: 760,
  },
  button: {
    marginTop: 10,
  },

  m10: {
    marginTop: 10,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};
const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class ProsesUploadApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      IFRS_MODEL: GetItem("IFRS_MODEL"),
      PRODUCT_CODE: GetItem("PRODUCT_CODE"),
      upload_dokumen: "",
      kd_produk: GetItem("PRODUCT_CODE"),
      listMap: [],
      listKeyBenefitDesc: [],
      listKdFormula: [],
      product_name: "",
      cohort_prefix: "",
      ifrs_model: GetItem("IFRS_MODEL"),
      product_risk: "",
      product_category: "",
      death_benefit: "",
      maturity_benefit: "",
      lapse_benefit: "",
      endowment_benefit: "",
      beban_penalty_benefit: "",
      beban_penalty_value: "",
      rider_benefit: "",
      valid: false,
      messageError: "",
      message: "",
      showForm: false,
      openSnackError: false,
      Backdrop: false,
      openSnack: false,
      disabled_option: false,
      beban_penalty_valueReadOnly: false,
      btnWording: "Simpan",
      btnWording1: "Upload dan Simpan",

      b1_key_benefit_1: "",
      b1_key_benefit_2: "",
      b1_key_benefit_3: "",
      b1_basis: "",
      b1_jenis_manfaat: "",
      b1_logic: "",
      b1_max_benefit: "",
      b1_af_max_benefit: "",
      b1_age_basis: "",
      b1_mth_0_benefit: "",

      b2_key_benefit_1: "",
      b2_key_benefit_2: "",
      b2_key_benefit_3: "",
      b2_basis: "",
      b2_jenis_manfaat: "",
      b2_logic: "",
      b2_max_benefit: "",
      b2_af_max_benefit: "",
      b2_age_basis: "",
      b2_mth_0_benefit: "",

      b3_key_benefit_1: "",
      b3_key_benefit_2: "",
      b3_key_benefit_3: "",
      b3_basis: "",
      b3_jenis_manfaat: "",
      b3_logic: "",
      b3_max_benefit: "",
      b3_af_max_benefit: "",
      b2_age_basis: "",
      b2_mth_0_benefit: "",

      b4_key_benefit_1: "",
      b4_key_benefit_2: "",
      b4_key_benefit_3: "",
      b4_basis: "",
      b4_jenis_manfaat: "",
      b4_logic: "",
      b4_max_benefit: "",
      b4_af_max_benefit: "",
      b4_age_basis: "",
      b4_mth_0_benefit: "",

      b5_key_benefit_1: "",
      b5_key_benefit_2: "",
      b5_key_benefit_3: "",
      b5_basis: "",
      b5_jenis_manfaat: "",
      b5_logic: "",
      b5_max_benefit: "",
      b5_af_max_benefit: "",
      b5_age_basis: "",
      b5_mth_0_benefit: "",

      b6_key_benefit_1: "",
      b6_key_benefit_2: "",
      b6_key_benefit_3: "",
      b6_basis: "",
      b6_jenis_manfaat: "",
      b6_logic: "",
      b6_max_benefit: "",
      b6_af_max_benefit: "",
      b6_age_basis: "",
      b6_mth_0_benefit: "",

      b7_key_benefit_1: "",
      b7_key_benefit_2: "",
      b7_key_benefit_3: "",
      b7_basis: "",
      b7_jenis_manfaat: "",
      b7_logic: "",
      b7_max_benefit: "",
      b7_af_max_benefit: "",
      b7_age_basis: "",
      b7_mth_0_benefit: "",

      visibileBenefit1: false,
      visibileBenefit2: false,
      visibileBenefit3: false,
      visibileBenefit4: false,
      visibileBenefit5: false,
      visibileBenefit6: false,
      visibileBenefit7: false,
      kd_formula: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get(BASE_URL + "setupparameter/list_map_desc")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          listMap: res.data.data.RISK,
          listKeyBenefitDesc: res.data.data.KEY_BENEFIT,
          listKdFormula: res.data.data.KD_FORMULA,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          listMap: [],
        });
      });
  }

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    // if (e.target.name === "b1_logic") {
    //   console.log(e.target.value);

    //   const value = e.target.checked ? 1 : 0;
    //   e.target.value = value;
    //   console.log(e.target.value);

    //   return;
    // }

    if (e.target.name === "beban_penalty_benefit" && e.target.value === "1") {
      this.setState({
        beban_penalty_valueReadOnly: false,
        beban_penalty_value: "",
      });
    } else if (
      e.target.name === "beban_penalty_benefit" &&
      e.target.value === "0"
    ) {
      this.setState({
        beban_penalty_valueReadOnly: true,
        beban_penalty_value: "0",
      });
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    if (e.target.name === "b1_logic" && e.target.checked) {
      this.setState({ visibileBenefit1: true });
    } else if (e.target.name === "b1_logic" && !e.target.checked) {
      this.setState({ visibileBenefit1: false });
    }
    if (e.target.name === "b2_logic" && e.target.checked) {
      this.setState({ visibileBenefit2: true });
    } else if (e.target.name === "b2_logic" && !e.target.checked) {
      this.setState({ visibileBenefit2: false });
    }
    if (e.target.name === "b3_logic" && e.target.checked) {
      this.setState({ visibileBenefit3: true });
    } else if (e.target.name === "b3_logic" && !e.target.checked) {
      this.setState({ visibileBenefit3: false });
    }
    if (e.target.name === "b4_logic" && e.target.checked) {
      this.setState({ visibileBenefit4: true });
    } else if (e.target.name === "b4_logic" && !e.target.checked) {
      this.setState({ visibileBenefit4: false });
    }
    if (e.target.name === "b5_logic" && e.target.checked) {
      this.setState({ visibileBenefit5: true });
    } else if (e.target.name === "b5_logic" && !e.target.checked) {
      this.setState({ visibileBenefit5: false });
    }
    if (e.target.name === "b6_logic" && e.target.checked) {
      this.setState({ visibileBenefit6: true });
    } else if (e.target.name === "b6_logic" && !e.target.checked) {
      this.setState({ visibileBenefit6: false });
    }
    if (e.target.name === "b7_logic" && e.target.checked) {
      this.setState({ visibileBenefit7: true });
    } else if (e.target.name === "b7_logic" && !e.target.checked) {
      this.setState({ visibileBenefit7: false });
    }
  };

  handleSubmit = () => {
    const e = document.getElementById("form1");

    const valid = ValidateData(e.elements);

    if (
      this.state.kd_produk === "" ||
      this.state.product_name === "" ||
      this.state.product_category === "" ||
      this.state.product_risk === "" ||
      this.state.ifrs_model === "" ||
      this.state.beban_penalty_benefit === ""
    ) {
      this.setState({
        message: "Data tidak lengkap!",
        openSnackError: true,
      });
      return false;
    }

    const formData = new FormData();
    // formData.append("kd_produk", this.state.kd_produk);
    // formData.append("product_name", this.state.product_name);
    // formData.append("product_category", this.state.product_category);
    // formData.append("product_risk", this.state.product_risk);
    // formData.append("ifrs_model", this.state.ifrs_model);
    // formData.append("cohort_prefix", this.state.cohort_prefix);
    // formData.append("lapse_benefit", this.state.lapse_benefit);
    // formData.append("maturity_benefit", this.state.maturity_benefit);
    // formData.append("death_benefit", this.state.death_benefit);
    // formData.append("endowment_benefit", this.state.endowment_benefit);
    // formData.append("rider_benefit", this.state.rider_benefit);
    // formData.append("beban_penalty_benefit", this.state.beban_penalty_benefit);
    // formData.append("beban_penalty_value", this.state.beban_penalty_value);
    formData.append("inserted_by", GetItem("user_id"));

    for (const key in this.state) {
      formData.append(key, this.state[key]);
    }

    this.setState({ openBackdrop: true });
    axios
      .post(REGISTER_SETUP_MAP_PRODUK, formData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        if (res.data.message !== "success") {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            btnWording: "Simpan",
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          btnWording: "Simpan",
          loading: false,
        });
      });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Box>
        <Paper
          variant="outlined"
          sx={{
            p: 1,
            margin: "auto",
            flexGrow: 1,
          }}
        >
          <Grid container className={classes.form}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop}
              onClick={this.handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
              open={this.state.openSnack}
              autoHideDuration={1000}
              onClose={(event, reason) => this.handleClose(event, reason)}
            >
              <Alert
                severity="success"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnack: false });
                  window.history.back(-1);
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
              <Alert
                severity="error"
                sx={{ width: "100%" }}
                variant="filled"
                onClose={() => {
                  this.setState({ openSnackError: false });
                }}
              >
                {this.state.message}
              </Alert>
            </Snackbar>
            <Grid item sm>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                <br></br> Setup Product
              </Typography>
              <form id="form1" name="form1" noValidate>
                {" "}
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="kd_produk"
                    type="text"
                    label="Product Code"
                    size="small"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.kd_produk}
                    onChange={this.handleChange}
                    required
                  />
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="product_name"
                    type="text"
                    label="Product Name"
                    size="small"
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.product_name}
                    onChange={this.handleChange}
                    required
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{ marginTop: 10 }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    IFRS MODEL
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.ifrs_model}
                    onChange={this.handleChange}
                    size="small"
                    name="ifrs_model"
                    label="IFRS MODEL"
                  >
                    <MenuItem value="GMM">GMM</MenuItem>
                    <MenuItem value="PAA">PAA</MenuItem>
                    <MenuItem value="VFA">VFA</MenuItem>
                    <MenuItem value="LIC">LIC</MenuItem>
                    <MenuItem value="REASGMM">REAS GMM</MenuItem>
                    <MenuItem value="REASPAA">REAS PAA</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{ marginTop: 10 }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Product Risk
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.product_risk}
                    onChange={this.handleChange}
                    size="small"
                    name="product_risk"
                    label="Product Risk"
                  >
                    {this.state.listMap.map((listM, idx) => (
                      <MenuItem value={listM.PRODUCT_RISK}>
                        {listM.PRODUCT_RISK}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Product Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.product_category}
                    onChange={this.handleChange}
                    size="small"
                    name="product_category"
                    label="Product Category"
                  >
                    <MenuItem value="GROUP">GROUP</MenuItem>
                    <MenuItem value="INDIVIDU">INDIVIDU</MenuItem>
                    <MenuItem value="GUARANTED (INDIVIDU)">
                      GUARANTED (INDIVIDU)
                    </MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Beban Penalty Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.beban_penalty_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="beban_penalty_benefit"
                    label="Beban Penalty Benefit"
                  >
                    <MenuItem value="0">YA</MenuItem>
                    <MenuItem value="1">TIDAK</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl variant="outlined" className={classes.textField}>
                  <TextField
                    id="user_id"
                    name="beban_penalty_value"
                    type="number"
                    label="Beban Penalty Value"
                    size="small"
                    variant="outlined"
                    disabled={this.state.beban_penalty_valueReadOnly}
                    value={this.state.beban_penalty_value}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <br></br>
                <br></br>
                <FormControl variant="outlined" className={classes.textField}>
                  <InputLabel id="demo-simple-select-helper-label">
                    Kode Formula
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.kd_formula}
                    onChange={this.handleChange}
                    size="small"
                    name="kd_formula"
                    label="Kode Formula"
                  >
                    {this.state.listKdFormula.map((listM, idx) => (
                      <MenuItem value={listM.KD_FORMULA}>
                        {listM.KD_FORMULA} - {listM.DESKRIPSI}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b1_logic"
                      value={this.state.b1_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 1"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b1_jenis_manfaat"
                    name="b1_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b1_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_basis"
                    value={this.state.b1_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b1_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_1"
                    value={this.state.b1_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_2"
                    value={this.state.b1_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_key_benefit_3"
                    value={this.state.b1_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_max_benefit"
                    value={this.state.b1_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b1_af_max_benefit"
                    value={this.state.b1_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b1_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b1_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit1 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b2_logic"
                      value={this.state.b2_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 2"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b2_jenis_manfaat"
                    name="b2_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b2_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_basis"
                    value={this.state.b2_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b2_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_1"
                    value={this.state.b2_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_2"
                    value={this.state.b2_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_key_benefit_3"
                    value={this.state.b2_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_max_benefit"
                    value={this.state.b2_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b2_af_max_benefit"
                    value={this.state.b2_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b2_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b2_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit2 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b3_logic"
                      value={this.state.b3_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 3"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b3_jenis_manfaat"
                    name="b3_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b3_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_basis"
                    value={this.state.b3_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b3_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_1"
                    value={this.state.b3_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_2"
                    value={this.state.b3_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_key_benefit_3"
                    value={this.state.b3_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_max_benefit"
                    value={this.state.b3_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b3_af_max_benefit"
                    value={this.state.b3_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b3_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b3_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit3 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b4_logic"
                      value={this.state.b4_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 4"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b4_jenis_manfaat"
                    name="b4_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b4_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_basis"
                    value={this.state.b4_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b4_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_1"
                    value={this.state.b4_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_2"
                    value={this.state.b4_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_key_benefit_3"
                    value={this.state.b4_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_max_benefit"
                    value={this.state.b4_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b4_af_max_benefit"
                    value={this.state.b4_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b4_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b4_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit4 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b5_logic"
                      value={this.state.b5_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 5"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b5_jenis_manfaat"
                    name="b5_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b5_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_basis"
                    value={this.state.b5_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b5_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_1"
                    value={this.state.b5_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_2"
                    value={this.state.b5_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_key_benefit_3"
                    value={this.state.b5_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_max_benefit"
                    value={this.state.b5_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b5_af_max_benefit"
                    value={this.state.b5_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b5_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b5_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit5 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b6_logic"
                      value={this.state.b6_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 6"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b6_jenis_manfaat"
                    name="b6_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b6_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_basis"
                    value={this.state.b6_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b6_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_1"
                    value={this.state.b6_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_2"
                    value={this.state.b6_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_key_benefit_3"
                    value={this.state.b6_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_max_benefit"
                    value={this.state.b6_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b6_af_max_benefit"
                    value={this.state.b6_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b6_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b6_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                ></br>
                <Divider
                  style={{
                    display: this.state.visibileBenefit6 ? "" : "none",
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="b7_logic"
                      value={this.state.b7_logic}
                      onChange={this.handleChange}
                    />
                  }
                  label="MAPPING BENEFIT 7"
                />
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <TextField
                    id="b7_jenis_manfaat"
                    name="b7_jenis_manfaat"
                    type="text"
                    label="Jenis Manfaat"
                    size="small"
                    variant="outlined"
                    value={this.state.b7_jenis_manfaat}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_basis"
                    value={this.state.b7_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_basis"
                    label="Basis"
                  >
                    <MenuItem value="1">Per 1000 UP</MenuItem>
                    <MenuItem value="2">Per 100 Premi</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Age Basis
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b7_age_basis}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_age_basis"
                    label="Age Basis"
                  >
                    <MenuItem value="POL_TERM_M">Pol Term M</MenuItem>
                    <MenuItem value="POL_TERM_AVG">Pol Term AVG</MenuItem>
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 1
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_1"
                    value={this.state.b7_key_benefit_1}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_1"
                    label="Key Benefit 1"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 2
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_2"
                    value={this.state.b7_key_benefit_2}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_2"
                    label="Key Benefit 2"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Key Benefit 3
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_key_benefit_3"
                    value={this.state.b7_key_benefit_3}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_key_benefit_3"
                    label="Key Benefit 3"
                  >
                    {this.state.listKeyBenefitDesc.map((listM, idx) => (
                      <MenuItem value={listM.KEY_BENEFIT}>
                        {listM.KEY_BENEFIT_DESC}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <br
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                ></br>
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir Before Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_max_benefit"
                    value={this.state.b7_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_max_benefit"
                    label="Nilai Bulan Terakhir Before Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Nilai Bulan Terakhir After Decrement
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="b7_af_max_benefit"
                    value={this.state.b7_af_max_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_af_max_benefit"
                    label="Nilai Bulan Terakhir After Decrement"
                  >
                    <MenuItem value="0">0</MenuItem>
                    <MenuItem value="0">!= 0</MenuItem>
                  </Select>
                </FormControl>{" "}
                <FormControl
                  variant="outlined"
                  className={classes.textField}
                  style={{
                    display: this.state.visibileBenefit7 ? "" : "none",
                  }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Month 0 Benefit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    required
                    value={this.state.b7_mth_0_benefit}
                    onChange={this.handleChange}
                    size="small"
                    name="b7_mth_0_benefit"
                    label="Month 0 Benefit"
                  >
                    <MenuItem value="0">Month Benefit dimulai bulan 0</MenuItem>
                    <MenuItem value="1">Month Benefit dimulai bulan 1</MenuItem>
                  </Select>
                </FormControl>
                <br></br>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleSubmit}
                >
                  {this.state.btnWording}
                </Button>{" "}
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Batal
                </Button>
              </form>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withStyles(styles)(ProsesUploadApp);
