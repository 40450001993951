import React, { useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import TablePagination from "@mui/material/TablePagination";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { SaveItem, GetItem, RemoveItem } from "../../common/util";
import Paper from "@mui/material/Paper";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import { BASE_URL } from "../../common/constantVariable";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
const styles = {
  pageTitle: {
    textAlign: "center",
  },
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

class ProsesDetailApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      valid: false,
      progress: 83,
      messageError: "",
      openSnackError: false,
      message: "",
      closing_id: "",
      showForm: false,
      openBackdrop: false,
      openBackdrop1: false,
      openBackdrop2: false,
      hasCheckData: false,
      openSnack: false,
      cekDataDone: 0,
      disabled_option: false,
      wordingBackdrop2:
        "Proses Generate Kualitas data sedang dijalankan. silakan cek berkala halaman ini.",
      btnWording: "Simpan",
      wordingPeriode: "",
      tahun_laporan: "",
      nama_bulan: "",
      has_run_check_data: false,
      interval_check_data: false,
      persentase_cek_done: 0,
    };
  }

  ambilData = () => {
    this.setState({ openBackdrop: true });
    axios
      .get("prosesdata/listKualitasDataUploadInsepsi")
      .then((res) => {
        this.setState({ openBackdrop: false });

        RemoveItem("upload_id");
        RemoveItem("jenis_file");
        RemoveItem("upload_ulang");
        this.setState({
          list: res.data.data.list,
          cekDataDone: res.data.data.cekDataDone,
          persentase_cek_done: res.data.data.persentaseProsesCekData,
        });

        if (res.data.data.list.length > 0) {
          this.setState({
            wordingPeriode:
              "Periode " +
              res.data.data.list[0].tahun_laporan +
              " " +
              res.data.data.list[0].nama_bulan,
          });
        }

        if (res.data.data.hasCheckData === "100%") {
          this.setState({ hasCheckData: true });
        } else {
          this.setState({ hasCheckData: false });
        }
        if (
          res.data.data.cekDataDone === 2 &&
          this.state.interval_check_data === false
        ) {
          alert(
            "Proses cek kualitas data sedang berjalan. mohon cek secara berkala"
          );

          this.setState({ interval_check_data: true });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  };

  componentDidMount() {
    this.ambilData();

    this.interval = setInterval(this.runIntervalCheck, 3000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  runIntervalCheck = () => {
    if (
      // this.state.interval_check_data === true &&
      this.state.cekDataDone === 2
    ) {
      this.ambilData();
    }
  };

  handleCekData = () => {
    this.setState({ openBackdrop1: true });

    var postData = new FormData();
    postData.append("mode", "1");
    postData.append("inserted_by", GetItem("user_id"));

    axios
      .post("prosesdata/runCheckDataInsepsi", postData)
      .then((res) => {
        this.setState({
          openBackdrop1: false,
          has_run_check_data: true,
        });
        alert(res.data.data);
        this.ambilData();
      })
      .catch((err) => {
        this.setState({ openBackdrop1: false });
        alert("Terjadi kesalahan");
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.openBackdrop1}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Typography variant="h6" className={classes.pageTitle}>
              Cek Kualitas Data Upload Insepsi
            </Typography>
          </div>
          <br></br>

          <Paper
            variant="outlined"
            style={{ display: this.state.list.length == 0 ? "" : "none" }}
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" className={classes.pageTitle}>
              Belum ada file yang diupload, silakan upload file di menu{" "}
              <b>Upload Data</b>
            </Typography>
          </Paper>

          <Paper
            variant="outlined"
            style={{
              display:
                this.state.cekDataDone == 2 && this.state.list.length > 0
                  ? ""
                  : "none",
            }}
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" className={classes.pageTitle}>
              {this.state.wordingBackdrop2}
            </Typography>
            <br></br>
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={this.state.persentase_cek_done} />
            </Box>
            <br></br>
          </Paper>
          <br></br>

          <Button
            style={{
              display:
                this.state.cekDataDone === 2 ||
                this.state.list < 1  
                  ? "none"
                  : "",
            }}
            variant="contained"
            color="primary"
            onClick={this.handleCekData}
          >
            Mulai Cek Kualitas Data
          </Button>
          <br></br>
          <br></br>

          <Paper
            variant="outlined"
            style={{
              display:
                this.state.cekDataDone == 2 || this.state.list < 1
                  ? "none"
                  : "",
            }}
          >
            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.list}
              showProgress={this.state.isLoading}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProsesDetailApp);

const columns = [
  { id: "file_upload", label: "Nama File", minWidth: 120 },
  { id: "jenis_file", label: "Jenis File", minWidth: 80 },
  { id: "data_count", label: "Jumlah Data", minWidth: 50 },
  { id: "kualitas_data", label: "Kualitas Data (%)", minWidth: 80 },
  { id: "error_count", label: "Data Error", minWidth: 50 },
];

const StickyHeadTable = ({ openBackdrop, list, isLoading }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [uploadId, setUploadId] = React.useState("");
  const [obackDrop, setObackDrop] = React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setObackDrop(obackDrop), [obackDrop]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const handleOpenDetail = (upload_id) => {
    SaveItem("upload_id", upload_id);
    history.push("/prosesdata/detailKualitasData");
  };

  return (
    <Paper sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell key="no" align="center">
                No
              </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="edit" align="center" style={{ minWidth: 30 }}>
                Hasil Pengecekan
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "kualitas_data" ? (
                            value === "100%" ? (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#10AC50",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            ) : (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#FB0101",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      {row.kualitas_data == "100%" ? (
                        <Typography component="div" variant="body1">
                          <Box
                            sx={{
                              color: "primary.main",
                              fontWeight: "bold",
                              m: 1,
                            }}
                          >
                            OK
                          </Box>
                        </Typography>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleOpenDetail(row.upload_id);
                          }}
                        >
                          Detail
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
