import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import MenuListApp from './menulist'; 
import MenuRegisterApp from './register';
import MenuUpdateApp from './update';


export default class MenuApp extends React.Component {

     render() {

        return (
            <Switch> 
            <Route exact path='/setupmenu/register' component={MenuRegisterApp} />
            <Route exact path='/setupmenu/edit/:id' component={MenuUpdateApp} />
            <Route exact path='/setupmenu/**' component={MenuListApp} />
            </Switch>
        );
    }

};