import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listApp from './list';   

export default class Loguser extends React.Component {
 
     render() {

        return (
            <Switch>   
            <Route exact path='/tools/loguser/**' component={listApp} /> 
            </Switch>
        );
    }

};