// import React, { Component, useState } from "react";
import React, { useRef } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@mui/material/IconButton";

import axios from "axios";
import { ValidateData } from "../common/util";
import {
  LOGIN_URL,
  APPS_NAME,
  FORGOT_PASSWORD_URL,
} from "../common/constantVariable";
import "./login.css";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { SaveItem } from "../common/util";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ReCAPTCHA from "react-google-recaptcha";
import CaptchaButton from '../components/CaptchaButton';


const styles = {
  form: {
    textAlign: "center",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "10px",
    marginTop: "20px",
    backgroundImage: `url(${"../assets/bg_login.jpg"})`,
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "50px auto 10px auto",
  },
  pageTitle2: {
    margin: "10px auto 10px auto",
    fontSize: 14,
  },
  textField: {
    marginTop: "10px",
    marginBottom: "10px",
    width: "300px",
  },
  button: {
    marginTop: 20,
    width: "350px",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  mt50: {
    marginTop: "10px",
  },
};

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "} {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

class LoginApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      resetPasswordEmail: "",
      resetPasswordUsername: "",
      errormessage: "",
      helpers: [],
      showPassword: false,
      btnSubmitForgot: "Submit",
      errors: [],
      openDialog: false,
      message: "",
      openSnackError: false,
      captchaToken: "",
    };
  }

  handleClickShowPassword = () => {
    if (this.state.showPassword) {
      this.setState({
        showPassword: false,
      });
    } else {
      this.setState({
        showPassword: true,
      });
    }
  };

  handleCaptchaSubmit = (token) => {
    console.log('Captcha token:', token);
    // Here you can handle the form submission with the reCAPTCHA token
    // Example: You can call your API with the token
    this.handleSubmit();
  };

  setCaptchaToken = (token) => {
    this.setState({ captchaToken: token });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleOpenDialog = () => {
    this.setState({
      openDialog: true,
    });
  };
  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    if (e.target.name == "username") {
      const inputValueWithoutSpaces = e.target.value.replace(/\s/g, "");
      e.target.value = inputValueWithoutSpaces;
    }

    this.setState({
      errormessage: "",
      [e.target.name]: e.target.value,
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleSubmit = (e) => {
    this.setState({
      errormessage: "loading",
    });

    e.preventDefault();

    const valid = ValidateData(e.target.elements);

    if (!valid) {
      this.setState({
        errormessage: "Data is not complete",
      });
      return false;
    }

    var postData = new FormData();
    postData.append("username", this.state.username);
    postData.append("password", this.state.password);
    postData.append("g-recaptcha-response", this.state.captchaToken); // Include captcha token

    axios
      .post(LOGIN_URL, postData)
      .then((res) => {
        SaveItem("username", res.data.data.username);
        SaveItem("token", res.data.data.token);
        SaveItem("user_id", res.data.data.user_id);
        SaveItem("user_email", res.data.data.email);
        SaveItem("agent_id", res.data.data.agent_id);
        SaveItem("dataMenu", JSON.stringify(res.data.data.userMenu));
        SaveItem("logo_company", res.data.data.img_logo);

        this.setState({
          errormessage: "",
        });

        this.props.handleLogin(res.data.data.token);
      })
      .catch((err) => {
        this.setState({
          errormessage: err.response.data.data,
        });
      });
  };

  handleSubmitForgotPassword = () => {
    this.setState({
      btnSubmitForgot: "sedang mengirim..",
    });

    var postData = new FormData();
    postData.append("username", this.state.resetPasswordUsername);
    postData.append("email", this.state.resetPasswordEmail);

    axios
      .post(FORGOT_PASSWORD_URL, postData)
      .then((res) => {
        this.setState({
          btnSubmitForgot: "Submit",
          resetPasswordEmail: "",
          resetPasswordUsername: "",
        });
        alert(res.data.data);
      })
      .catch((err) => {
        this.setState({
          btnSubmitForgot: "Submit",
          resetPasswordEmail: "",
          resetPasswordUsername: "",
        });
        alert(err.response.data.data);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="container">
        <div className="vertical-center">
          <Grid container className={classes.form}>
            <Grid item sm />
            <Dialog
              open={this.state.openDialog}
              onClose={this.handleCloseDialog}
            >
              <DialogTitle>Lupa Password ?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Masukan username dan email akun anda, kami akan mengirimkan
                  reset password.
                </DialogContentText>
                <div style={{ textAlign: "center" }}>
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    style={{ width: "350px" }}
                    name="resetPasswordUsername"
                    label="Username"
                    type="text"
                    fullWidth
                    variant="standard"
                    helperText={this.state.helpers["resetPasswordUsername"]}
                    error={this.state.errors["resetPasswordUsername"]}
                    value={this.state.resetPasswordUsername}
                    onChange={this.handleChange}
                  />
                  <TextField
                    autoFocus
                    required
                    margin="dense"
                    style={{ width: "350px" }}
                    helperText={this.state.helpers["resetPasswordEmail"]}
                    error={this.state.errors["resetPasswordEmail"]}
                    value={this.state.resetPasswordEmail}
                    onChange={this.handleChange}
                    id="name"
                    name="resetPasswordEmail"
                    label="Alamat Email yang terdaftar"
                    type="email"
                    fullWidth
                    variant="standard"
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button type="submit" onClick={this.handleSubmitForgotPassword}>
                  {this.state.btnSubmitForgot}
                </Button>
                <Button onClick={this.handleCloseDialog}>Batal</Button>
              </DialogActions>
            </Dialog>
            <Grid item sm>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  opacity: [0.9],

                  "& > :not(style)": {
                    m: 1,
                    width: 450,
                    height: 460,
                  },
                }}
              >
                <Paper elevation={3}>
                  <Typography variant="h6" className={classes.pageTitle}>
                    {APPS_NAME}
                  </Typography>
                  <Typography variant="h6" className={classes.pageTitle2}>
                    IFRS (International Financial Reporting Standard) 17 System
                  </Typography>
                  <form
                    id="form1"
                    className={classes.form}
                    noValidate
                    onSubmit={this.handleSubmit}
                  >
                    <TextField
                      id="username"
                      name="username"
                      type="text"
                      variant="outlined"
                      size="small"
                      label="User Name"
                      className={classes.textField}
                      helperText={this.state.helpers["username"]}
                      error={this.state.errors["username"]}
                      value={this.state.username}
                      onChange={this.handleChange}
                      required
                      fullWidth
                    />

                    <TextField
                      id="password"
                      variant="outlined"
                      size="small"
                      name="password"
                      label="Password"
                      helperText={this.state.helpers["password"]}
                      error={this.state.errors["password"]}
                      className={classes.textField}
                      onChange={this.handleChange}
                      type={this.state.showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}
                            >
                              {this.state.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      required
                      fullWidth
                    />

                    <Typography variant="body2" className={classes.customError}>
                      {this.state.errormessage}
                    </Typography>
                    <CaptchaButton setToken={this.setCaptchaToken} />
                    <div className={classes.mt50}>
                      <Link
                        href="#"
                        style={{ textDecoration: "none", color: "#384baa" }}
                        onClick={this.handleOpenDialog}
                      >
                        Lupa Password ?
                      </Link>
                    </div>

                    <Copyright sx={{ mt: 8, mb: 4 }} className={classes.mt50} />
                  </form>
                </Paper>
              </Box>
            </Grid>
            <Grid item sm />
          </Grid>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LoginApp);
