import React, { Component, Fragment, useState}  from 'react';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow'; 
import Button from '@mui/material/Button'; 
import {USER_LIST,BRIDGEGET,BASE_URL,BRIDGEDELETE} from '../../common/constantVariable';
 
class RoleListApp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            errormessage: '', 
            openSnack : false,
            openSnackError : false,
            message: '',
        }
    }
    
    componentDidMount() {
        axios.get('role')
            .then( (res) => {
                this.setState({
                  list: res.data.data
                })
            })
            .catch( (err) => {
                console.error(err.message);
                this.setState({
                  list: []
                })
            });
    }

    handleDelete = (id) => {
      axios.delete('role/' + id)
        .then( res => {
          if (res.data.message === 'success') {
            const data = this.state.list.filter(item => item.role_id !== id);
            this.setState({ 
              list: data
            })
          }else{
             
          }
        })
        .catch( err => {
          
        })
    };

    render() {

        const { classes } = this.props;

        return (
          <Fragment>
            
            <div> 
              <Button variant="contained" color="primary" component={Link} to="/setuprole/register">
              Tambah Role
                </Button>
            </div>

            <StickyHeadTable list={this.state.list} handleDelete={this.handleDelete}  />

          </Fragment>
        )

    }
}

export default RoleListApp; 
const columns = [
  { id: 'role_id', label: 'ID Role', minWidth: 80 },
  { id: 'role_name', label: 'Nama Role', minWidth: 80 }, 
]

const StickyHeadTable = ({list, handleDelete}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
 

  return (
 
    <Paper sx={{ overflow: 'hidden' }}> 

      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
                <TableCell
                  key='no'
                  align='center'
                  style={{ minWidth: 40 }}
                >
                  No
                </TableCell>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
                <TableCell
                  key='edit'
                  align='center'
                  style={{ minWidth: 30 }}
                >
                  Edit
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                // console.log(row);
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell key='no' align='center'>
                        {idx + 1 + page * rowsPerPage}    
                      </TableCell>

                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                      <TableCell key='edit' align='center'>
                          <Link to={`/setuprole/edit/${row.role_id}`}>edit</Link>
                      </TableCell>


                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );

}









