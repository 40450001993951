import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import SearchBar from "material-ui-search-bar";
import { MULAI_PROSES } from "../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { GetItem, SaveItem } from "../../common/util";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const styles = {
  pageTitle: {
    textAlign: "center",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      bulanLaporan: "",
      tahunLaporan: "",
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openBackdrop: false,
      openSnackError: false,
      openBackdrop1: false,
      hasCheckData: false,
      count_cohort: 0,
      message: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    this.getData();
  }

  getData = () => {
    axios
      .get("prosesdata/list_monitor_proses/insepsi")
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: res.data.data.list,
          count_cohort: res.data.data.count_cohort,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          list: [],
        });
      });
  };

  prosesGabungan = (closing_id) => {
    var postData = new FormData();
    postData.append("closing_id", closing_id);
    postData.append("inserted_by", GetItem("user_id"));
    postData.append("tipe", "proses_gabugan");
    this.setState({ openBackdrop: true });
    axios
      .post(MULAI_PROSES, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });
        alert(res.data.data);
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <div>
            <Typography variant="h6" className={classes.pageTitle}>
              Monitoring Proses
            </Typography>
          </div>
          <br></br>

          <Paper variant="outlined">
            <StickyHeadTable
              openBackdrop={this.state.openBackdrop}
              list={this.state.list}
              countCohort={this.state.count_cohort}
              showProgress={this.state.isLoading}
              prosesGabungan={this.prosesGabungan}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "tgl_mulai_proses_", label: "Tanggal Proses", minWidth: 80 },
  { id: "closing_status_", label: "Status", minWidth: 80 },
  { id: "kualitas_data", label: "Kualitas Data", minWidth: 80 },
];

const StickyHeadTable = ({
  openBackdrop,
  list,
  countCohort,
  isLoading,
  prosesGabungan,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searched, setSearched] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [cohort, setCohort] = React.useState(countCohort);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [uploadId, setUploadId] = React.useState("");
  const [openFormMulaiProses, setOpenFormMulaiProses] = React.useState("");
  const [openFormUlangiProses, setOpenFormUlangiProses] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [wordingDialog, setWordingDialog] = React.useState("");
  const [openDialogUlangiProses, setOpenDialogUlangiProses] =
    React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setCohort(countCohort), [countCohort]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = list.filter((row) => {
      return (
        row.no_polis.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.client_name.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  let history = useHistory();

  const handleOpenFormMulaiProses = (closing_id) => {
    setUploadId(closing_id);
    setOpenFormMulaiProses(true);
  };

  const handleProsesDataMulaiProses = () => {
    const closing_id = GetItem("closing_id");
    handleCloseFormMulaiProses();
    alert("Simpan data berhasil");
  };

  const handleCloseFormMulaiProses = () => {
    setOpenFormMulaiProses(false);
  };

  const handleOpenFormUlangiProses = (closing_id) => {
    setUploadId(closing_id);
    setOpenFormUlangiProses(true);
  };

  const handleProsesDataUlangiProses = () => {
    const closing_id = GetItem("closing_id");
    handleCloseFormUlangiProses();
    alert("Simpan data berhasil");
  };

  const handleCloseFormUlangiProses = () => {
    setOpenFormUlangiProses(false);
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("-");
    const closing_id = baseVal[0];
    SaveItem("closing_id", closing_id);
    // alert(baseVal[1])
    SaveItem("bulan_laporan", baseVal[3]);
    SaveItem("tahun_laporan", baseVal[4]);
    if (baseVal[1] == "lihat_data") {
      history.push("/saldoawalinsepsi/detailProses");
    } else if (baseVal[1] == "proses_gabugan") {
      prosesGabungan(closing_id);
    } else if (baseVal[1] == "mulai_proses") {
      if (baseVal[6] > 0 && baseVal[7] === "0") {
        history.push("/saldoawalinsepsi/detailMulaiProses");
      } else if (baseVal[6] > 0 && baseVal[7] === "1") {
        //history.push("/prosesdata/detailMulaiProses");
        setOpenDialogUlangiProses(true);
      } else {
        setWordingDialog(
          "Mohon generate cohort terlebih dahulu di menu Monitor Cohort"
        );
        setOpenDialog(true);
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDialogUlangiProses = () => {
    setOpenDialogUlangiProses(false);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  return (
    <Paper sx={{ overflow: "hidden" }}>
      {showProgress && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ mt: 3, ml: 1 }} color="secondary" />
        </div>
      )}

      <TableContainer>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Error !"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {wordingDialog}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>OK</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialogUlangiProses}
          onClose={handleCloseDialogUlangiProses}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Proses aktuarial sudah pernah dijalankan, apakah ingin mengulangi
              proses ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => handleCloseDialogUlangiProses()}
            >
              Batalkan
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              color="primary"
              onClick={() =>
                history.push("/saldoawalinsepsi/detailMulaiProses")
              }
            >
              Ulangi Proses Aktuari
            </Button>
          </DialogActions>
        </Dialog>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "kualitas_data" ? (
                            value === "100%" ? (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#10AC50",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            ) : (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#FB0101",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            )
                          ) : column.id == "closing_status_" ? (
                            value === "Open" ? (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    color: "#10AC50",
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            ) : (
                              <Typography component="div" variant="body1">
                                <Box
                                  sx={{
                                    fontWeight: "bold",
                                    m: 1,
                                  }}
                                >
                                  {value}
                                </Box>
                              </Typography>
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>

                          {row.closing_status === "C" ||
                          row.closing_status === "P" ? (
                            <MenuItem
                              value={
                                row.closing_id +
                                "-lihat_data-" +
                                row.closing_status +
                                "-" +
                                row.nama_bulan +
                                "-" +
                                row.tahun_laporan +
                                "-" +
                                row.kualitas_data
                              }
                            >
                              Detail Proses
                            </MenuItem>
                          ) : (
                            ""
                          )}
                          {row.closing_status === "O" ? (
                            <MenuItem
                              value={
                                row.closing_id +
                                "-mulai_proses-" +
                                row.closing_status +
                                "-" +
                                row.nama_bulan +
                                "-" +
                                row.tahun_laporan +
                                "-" +
                                row.kualitas_data +
                                "-" +
                                row.IS_GENERATE_COHORT +
                                "-" +
                                row.done_actuarial_process
                              }
                            >
                              {" "}
                              Mulai Proses Aktuari
                            </MenuItem>
                          ) : (
                            ""
                          )}

                          {(row.closing_status === "P" &&
                            row.done_actuarial_process === "1") ||
                          (row.eligible_rerun === "1" &&
                            row.closing_status === "C") ? (
                            <MenuItem
                              value={
                                row.closing_id +
                                "-mulai_proses-" +
                                row.closing_status +
                                "-" +
                                row.nama_bulan +
                                "-" +
                                row.tahun_laporan +
                                "-" +
                                row.kualitas_data +
                                "-" +
                                row.IS_GENERATE_COHORT +
                                "-" +
                                row.done_actuarial_process
                              }
                            >
                              Mulai Ulang Proses Aktuari
                            </MenuItem>
                          ) : (
                            ""
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};
