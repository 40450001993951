import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import ListApp from './list'; 
import RegisterApp from './register';
import UpdateApp from './update';

export default class RoleMenuApp extends React.Component {

     render() {

        return (
            <Switch> 
            <Route exact path='/setuprolemenu/register' component={RegisterApp} />
            <Route exact path='/setuprolemenu/edit/:id' component={UpdateApp} />
            <Route exact path='/setuprolemenu/**' component={ListApp} />
            </Switch>
        );
    }

};