import React from "react";
import { Route, Switch } from "react-router-dom";

import listApp from "./list";
import update from "./update";

export default class General extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/setup/profitandloss/update" component={update} />
        <Route exact path="/setup/profitandloss/**" component={listApp} />
      </Switch>
    );
  }
}
