import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listApp from './list';  
import register from './register';  
import update from './update';    

export default class General extends React.Component {
 
     render() {

        return (
            <Switch>  
            <Route exact path='/setup/mortalityrate/register' component={register} /> 
            <Route exact path='/setup/mortalityrate/update' component={update} />
            <Route exact path='/setup/mortalityrate/**' component={listApp} /> 
            </Switch>
        );
    }

};