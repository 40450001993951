import React, { Component, Fragment, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  BRIDGEGET,
  BASE_URL,
  BRIDGEDELETE,
} from "../../../common/constantVariable";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { GetItem, SaveItem } from "../../../common/util";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SearchBar from "material-ui-search-bar";
import Grid from "@mui/material/Grid";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const styles = {
  search: {
    backgroundColor: "#dedede",
  },
};

class ProseslistApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      searched: "",
      errormessage: "",
      openSnack: false,
      isLoading: false,
      openSnackError: false,
      listKodeProduk: [],
      message: "",
    };
  }

  componentDidMount() {
    // axios
    //   .get("setupbenefit/benefit_3")
    //   .then((res) => {
    //     this.setState({
    //       list: res.data.data,
    //     });
    //   })
    //   .catch((err) => {
    //     this.setState({
    //       list: [],
    //     });
    //   });

    this.getListKodeProduk();
  }

  getListKodeProduk = () => {
    axios
      .get(BASE_URL + "setupbenefit/list_kode_produk_benefit")
      .then((res) => {
        this.setState({
          listKodeProduk: res.data.data.BENEFIT_1,
        });
      })
      .catch((err) => {
        this.setState({
          listKodeProduk: [],
        });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleNewProses}
            component={Link}
            to="/setup/benefit_3/register"
          >
            Tambah Benefit 3
          </Button>
        </div>
        <br></br>

        <StickyHeadTable
          list={this.state.list}
          showProgress={this.state.isLoading}
          classes={classes}
          list_kode_produk={this.state.listKodeProduk}
        />
      </Fragment>
    );
  }
}

// export default ProseslistApp;

export default withStyles(styles)(ProseslistApp);

const columns = [
  { id: "KD_PRODUK", label: "Kode Produk", minWidth: 100 },
  { id: "MTH", label: "Bulan ke", minWidth: 80 },
  { id: "KEY_BENEFIT_1", label: "Key Benefit 1", minWidth: 100 },
  { id: "KEY_BENEFIT_2", label: "Key Benefit 2", minWidth: 100 },
  { id: "KEY_BENEFIT_3", label: "Key Benefit 3", minWidth: 100 },
  { id: "Rate", label: "Rate", minWidth: 100 },
];

const StickyHeadTable = ({ list, isLoading, classes, list_kode_produk }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [rows, setRows] = React.useState([]);
  const [showProgress, setShowProgress] = React.useState(false);
  const [rowsDefault, setRowsDefault] = React.useState([]);
  const [searched, setSearched] = React.useState("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [keyword_cari, setKeyword_cari] = React.useState("");
  const [searchedVal, setSearchedVal] = React.useState("");
  const [listKodeProduk, setListKodeProduk] = React.useState([]);
  const [kode_produk, setKode_produk] = React.useState("");
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  useEffect(() => setRows(list), [list]);
  useEffect(() => setRowsDefault(list), [list]);
  useEffect(() => setShowProgress(isLoading), [isLoading]);
  useEffect(() => setListKodeProduk(list_kode_produk), [list_kode_produk]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let history = useHistory();

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const deleteData = () => {
    axios
      .delete(
        "setupbenefit/benefit_3/" +
          GetItem("kd_produk") +
          "/" +
          GetItem("mth") +
          "/" +
          GetItem("KEY_BENEFIT_1") +
          "/" +
          GetItem("KEY_BENEFIT_2") +
          "/" +
          GetItem("KEY_BENEFIT_3") +
          "/" +
          GetItem("RATE")
      )
      .then((res) => {
        setRows([]);
        handleCloseDialog();
        axios
          .get("setupbenefit/benefit_3")
          .then((res) => {
            setRows(res.data.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        handleCloseDialog();
      });
  };

  const handleDownloadData = () => {
    if (kode_produk !== "") {
      axios
        .get(
          "setupbenefit/benefit_3_download/" +
            kode_produk +
            "/" +
            keyword_cari +
            "/" +
            searchedVal
        )
        .then((res) => {
          if (res.data.message !== "success") {
            alert(res.data.data);
          } else {
            window.open(res.data.data.url, "_blank");
          }
        })
        .catch((err) => {});
    } else {
      alert("Pilih kode produk yang ingin didownload");
    }
  };

  const handleOpenClosing = (e) => {
    const baseVal = e.target.value.split("-");
    const kd_produk = baseVal[0];
    const mth = baseVal[1];
    const KEY_BENEFIT_1 = baseVal[2];
    const KEY_BENEFIT_2 = baseVal[3];
    const KEY_BENEFIT_3 = baseVal[4];
    const RATE = baseVal[5];
    SaveItem("mth", mth);
    SaveItem("kd_produk", kd_produk);
    SaveItem("KEY_BENEFIT_1", KEY_BENEFIT_1);
    SaveItem("KEY_BENEFIT_2", KEY_BENEFIT_2);
    SaveItem("KEY_BENEFIT_3", KEY_BENEFIT_3);
    SaveItem("RATE", RATE);

    if (baseVal[6] == "update") {
      history.push("/setup/benefit_3/update");
    } else if (baseVal[6] == "delete") {
      setOpenDialog(true);
    }
  };

  const handleSelectOption = (e) => {
    const value = e.target.value;
    if (value === "reset") {
      setKeyword_cari("");
      requestSearch("");
      setSearched("");
      setSearchedVal("");
    } else {
      setKeyword_cari(value);
    }
  };
  const requestSearch = (searchedVal) => {
    setSearchedVal(searchedVal);
  };

  const cancelSearch = () => {
    setSearched("");
    setKeyword_cari("");
    requestSearch(searched);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#cdcdcd",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleChangeJenisKodeProduk = (e) => {
    const value = e.target.value;
    if (value === "") {
      setKode_produk("");
    } else {
      setKode_produk(value);
    }
  };

  const setData = () => {
    if (kode_produk === "") {
      alert("Pilih kode produk terlebih dahulu");
      return false;
    }

    if (searchedVal !== "" && keyword_cari === "") {
      alert("Pilih cari berdasarkan terlebih dahulu");
      return false;
    }

    setOpenBackdrop(true);
    setRows([]);
    axios
      .get(
        "setupbenefit/benefit_3/getData/" +
          kode_produk +
          "/" +
          keyword_cari +
          "/" +
          searchedVal
      )
      .then((res) => {
        setOpenBackdrop(false);
        setRows(res.data.data);
      })
      .catch((err) => {
        setOpenBackdrop(false);
        setRows([]);
      });
  };

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Hapus Data ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Anda yakin ingin menghapus data ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Batal</Button>
          <Button onClick={deleteData} autoFocus>
            Hapus
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Pilih Kode Produk
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              onChange={handleChangeJenisKodeProduk}
              value={kode_produk}
              size="small"
              style={{ height: 49 }}
              label="Pilih Kode Produk"
              name="kode_produk"
            >
              {list_kode_produk.map((dataPrm, idx) => (
                <MenuItem value={dataPrm.KD_PRODUK}>
                  {dataPrm.KD_PRODUK}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-helper-label">
              Cari Berdasarkan
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={keyword_cari}
              onChange={handleSelectOption}
              size="small"
              name="keyword_cari"
              label="Cari Berdasarkan"
            >
              <MenuItem value="mth">Bulan Ke</MenuItem>
              <MenuItem value="key_benefit_1">Key Benefit 1</MenuItem>
              <MenuItem value="key_benefit_2">Key Benefit 2</MenuItem>
              <MenuItem value="key_benefit_3">Key Benefit 3</MenuItem>
              <MenuItem value="rate">Rate</MenuItem>
              {/* <MenuItem value="reset">Reset</MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <SearchBar
            className={classes.search}
            value={searched}
            name="searched"
            onChange={(searchedVal) => requestSearch(searchedVal)}
            onCancelSearch={() => cancelSearch()}
            placeholder="cari disini.."
          />
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" color="primary" onClick={() => setData()}>
            Cari
          </Button>
          &nbsp; &nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleDownloadData()}
          >
            Download Data
          </Button>
        </Grid>
      </Grid>

      <br />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableCell key="no" align="center" style={{ minWidth: 40 }}>
                No
              </StyledTableCell>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
              <StyledTableCell
                key="edit"
                align="center"
                style={{ minWidth: 30 }}
              >
                Aksi
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell key="no" align="center">
                      {idx + 1 + page * rowsPerPage}
                    </TableCell>

                    {columns.map((column) => {
                      let value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}

                    <TableCell key="edit" align="center">
                      <FormControl
                        variant="standard"
                        sx={{ m: 1, minWidth: 50 }}
                      >
                        <Select
                          value="aksi"
                          onChange={handleOpenClosing}
                          label="Aksi"
                        >
                          <MenuItem value="aksi">Aksi</MenuItem>
                          <MenuItem
                            value={
                              row.KD_PRODUK +
                              "-" +
                              row.MTH +
                              "-" +
                              row.KEY_BENEFIT_1 +
                              "-" +
                              row.KEY_BENEFIT_2 +
                              "-" +
                              row.KEY_BENEFIT_3 +
                              "-" +
                              row.Rate +
                              "-update"
                            }
                          >
                            Ubah Parameter
                          </MenuItem>
                          <MenuItem
                            value={
                              row.KD_PRODUK +
                              "-" +
                              row.MTH +
                              "-" +
                              row.KEY_BENEFIT_1 +
                              "-" +
                              row.KEY_BENEFIT_2 +
                              "-" +
                              row.KEY_BENEFIT_3 +
                              "-" +
                              row.Rate +
                              "-delete"
                            }
                          >
                            Hapus Parameter
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 70, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};
