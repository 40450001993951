import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import ListApp from './list'; 
import RegisterApp from './register';
import MenuUpdateApp from './update';


export default class RoleUserApp extends React.Component {

     render() {

        return (
            <Switch> 
            <Route exact path='/setuproleuser/register' component={RegisterApp} />
            <Route exact path='/setuproleuser/edit/:user_id/:role_id' component={MenuUpdateApp} />
            <Route exact path='/setuproleuser/**' component={ListApp} />
            </Switch>
        );
    }

};