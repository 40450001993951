import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";
import { styled } from "@mui/material/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { GetItem } from "../../common/util";
import Paper from "@mui/material/Paper";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

import { MULAI_PROSES_TRANSISI } from "../../common/constantVariable";
import Box from "@mui/material/Box";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Title from "../../components/title";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "left",
  },
  pageTitle: {
    textAlign: "center",
  },
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

class detailMulaiProsesApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closing_id: GetItem("closing_id"),
      bulan_laporan: GetItem("bulan_laporan"),
      tahun_laporan: GetItem("tahun_laporan"),
      valid: false,
      progress: 83,
      messageError: "",
      total_record: "",
      total_gmm: "",
      total_vfa: "",
      total_paa: "",
      total_lic: "",
      total_reasgmm: "",
      total_reaspaa: "",
      total_file_upload: 10,
      openSnackError: false,
      message: "",
      showForm: false,
      openSnack: false,
      disabled_option: false,
      bukaDialog: false,
      openBackdrop: false,
      btnWording: "Simpan",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get("dataSummary/summaryRecord/" + this.state.closing_id)
      .then((res) => {
        this.setState({ openBackdrop: false });
        this.setState({
          total_record: res.data.data.total_record,
          total_gmm: res.data.data.total_gmm,
          total_vfa: res.data.data.total_vfa,
          total_paa: res.data.data.total_paa,
          total_lic: res.data.data.total_lic,
          total_reasgmm: res.data.data.total_reasgmm,
          total_reaspaa: res.data.data.total_reaspaa,
        });
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
      });
  }

  mulaiProses = () => {
    var postData = new FormData();
    postData.append("closing_id", this.state.closing_id);
    postData.append("inserted_by", GetItem("user_id"));
    postData.append("tipe", "mulai_proses");
    this.handleTutupDialog();
    this.setState({ openBackdrop: true });
    axios
      .post(MULAI_PROSES_TRANSISI, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });

        alert(res.data.data);
        window.history.back(-1);
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
      });
  };

  handleTutupDialog = () => {
    this.setState({
      bukaDialog: false,
    });
  };
  handleBukaDialog = () => {
    this.setState({
      bukaDialog: true,
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.pageTitle}>
            Mulai Proses Data Transisi Periode {this.state.bulan_laporan}{" "}
            {this.state.tahun_laporan}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <br></br>
        </Grid>
        <Grid item xs={12}>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.openBackdrop}
            onClick={this.handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog open={this.state.bukaDialog} onClose={this.handleTutupDialog}>
            <DialogTitle id="alert-dialog-title">Proses Data?</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Apakah anda yakin untuk memulai proses?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.mulaiProses} autoFocus>
                {" "}
                Ya{" "}
              </Button>
              <Button onClick={this.handleTutupDialog}>Tidak</Button>
            </DialogActions>
          </Dialog>

          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_record}
                </Typography>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record GMM</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_gmm}
                </Typography>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record VFA</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_vfa}
                </Typography>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record PAA</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_paa}
                </Typography>
              </Paper>
            </Grid>

            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record LIC</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_lic}
                </Typography>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record REAS GMM</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_reasgmm}
                </Typography>
              </Paper>
            </Grid>
            <Grid item sm={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: 85,
                }}
              >
                <Title>Total Record REAS PAA</Title>
                <Typography component="p" variant="h4">
                  {this.state.total_reaspaa}
                </Typography>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <br></br> Catatan :
            </Grid>
            <Grid item xs={12}>
              Setelah Klik tombol "Mulai Proses", data periode{" "}
              {this.state.bulan_laporan} {this.state.tahun_laporan} akan masuk
              kedalam antrian proses.<br></br>
              klik tombol "Mulai Proses" untuk memulai proses data."
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <br></br>
          <br></br>
          <Grid container rowSpacing={0.5}>
            <Grid item xs={12}>
              <Box sx={{ width: "100%" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => {
                    window.history.back(-1);
                  }}
                >
                  Kembali
                </Button>
                &nbsp; &nbsp; &nbsp;
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    this.handleBukaDialog();
                  }}
                >
                  Mulai Proses
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(detailMulaiProsesApp);
