import React, { Component, useState}  from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
// import AppIcon  from '.\www14.png';
import axios from 'axios'; 
 
import Grid  from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import TextField  from '@material-ui/core/TextField';
import Button  from '@material-ui/core/Button';
import {ValidateData} from '../../common/util';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; 
import {COMPANY_REGISTER,BRIDGEPOST,COMPANY_REGISTER_UPLOAD,BRIDGEPOSTFILE} from '../../common/constantVariable';
import FormGroup from '@mui/material/FormGroup'; 


import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress'; 

const styles = {
    form: {
        textAlign: 'center'
    },
    image: {
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto'
    },
    textField: {
        margin: '5px auto 5px auto'
    },
    button: {
        marginTop: 20
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    }
};


class UserRegisterApp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            company_id: '',
            company_name: '',
            company_address : '', 
            effective_date: '',
            logo_company: '',
            valid: false,
            helpers: [],
            errors: [],
            errormessage: '', 
            openSnack : false,
            openBackdrop : false,
            message: '',
        }
        // console.log(this.state);
    }
    
    componentDidMount() { 
    }
 
    handleSubmit = () => {

        const e = document.getElementById("form1");

        const valid = ValidateData(e.elements);

        if (!valid) {
            this.setState({
                errormessage: "Data tidak lengkap"
            });    
            return false;
        }

        // let url_post = BRIDGEPOSTFILE;
        let url = COMPANY_REGISTER_UPLOAD;
 
        if(this.state.logo_company === '')
        {
            // url_post = BRIDGEPOST;
            url = COMPANY_REGISTER;
        }
 
        var postData = new FormData(); 
        postData.append("company_id", this.state.company_id);
        postData.append("company_name", this.state.company_name);
        postData.append("company_address", this.state.company_address);  
        postData.append("upload_dokumen", this.state.logo_company); 
        postData.append("url", url);

        

        this.setState({openBackdrop : true})
        axios.post(url, postData)
            .then((res) => { 
                this.setState({openBackdrop : false})
                  this.setState({
                        message : res.data.data,
                        openSnack : true
                    })
                
 
            })
            .catch( (err) => {
                this.setState({openBackdrop:false})
                this.setState({
                    errors      : err.response.data.data,
                    message     : err.response.data.data,
                    openSnack   : true, 
                })
            });
    }

    handleChange = (e) => {
        const error = e.target.required && e.target.value==='';

        this.state.errors[e.target.name] = error;
        this.state.helpers[e.target.name] =  error ? 'required' : '';
        
        

        this.setState({
            [e.target.name]: e.target.value,
            errormessage: '',
            errors: this.state.errors, 
            helpers: this.state.helpers
        })

    };

    handleClose = (event, reason) => {
        this.setState({openSnack:false});
        if (reason === 'timeout') {
            window.history.back(-1);
          }
      };

      handleChangeUpload = (e) => {      
        this.setState({
          [e.target.name]: e.target.files[0]
        })
      };

    render() {
     
        const { classes } = this.props;
        const { errors, loading } = this.state;

        return (
            <Grid container className={classes.form} >
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.openBackdrop}
                onClick={this.handleClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar open={this.state.openSnack} autoHideDuration={1000} 
                onClose={(event, reason) => this.handleClose(event, reason)}>
                    <Alert severity="success" sx={{ width: '100%' }} variant="filled" 
                    onClose={() => {this.setState({openSnack:false}); window.history.back(-1)}}>                
                        {this.state.message}
                    </Alert>
                </Snackbar>
                <Grid item sm />
                <Grid item sm>
                    <Typography variant="h6" className={classes.pageTitle}>
                        Tambah Identitas Perusahaan
                    </Typography>
                    {/* <form id="form1" noValidate onSubmit={this.handleSubmit}> */}
                    <form id="form1" name="form1" noValidate >
                   

                        <TextField
                            id="company_id" name="company_id"  
                            type="text"
                            variant='outlined'
                            size='small'
                            label="ID Perusahaan"
                            className={classes.textField}
                            helperText={this.state.helpers['company_id']}
                            error={this.state.errors['company_id']}
                            value={this.state.company_id}
                            onChange={this.handleChange}
                            required fullWidth />

                        <TextField
                            id="company_name" name="company_name"  
                            type="text"
                            label="Nama Perusahaan"
                            variant='outlined'
                            size='small'
                            className={classes.textField}
                            helperText={this.state.helpers['company_name']}
                            error={this.state.errors['company_name']}
                            value={this.state.company_name}
                            onChange={this.handleChange}
                            required fullWidth />

                        <TextField
                            id="company_address" name="company_address"  
                            type="text"
                            variant='outlined'
                            size='small'
                            label="Alamat Perusahaan"
                            multiline
                            rows={3}
                            className={classes.textField}
                            helperText={this.state.helpers['company_address']}
                            error={this.state.errors['company_address']}
                            value={this.state.company_address}
                            onChange={this.handleChange}
                            required fullWidth />
                            
                            <input
                                accept="image/*"
                                name='logo_company'
                                className={classes.input}
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={this.handleChangeUpload}
                                />
                                <label htmlFor="raised-button-file">
                                <Button color="primary" variant="outlined" component="span">
                                    Upload Logo Perusahaan
                                </Button>
                                </label> 

 
                        <Typography variant='body2' className={classes.customError} >
                            {this.state.errormessage}
                        </Typography>

                        <Button variant='contained' color='primary' className={classes.button} onClick={this.handleSubmit}>
                            Simpan
                        </Button> &nbsp;&nbsp;&nbsp;

                        <Button variant='contained' color='secondary' className={classes.button} onClick={ () => {window.history.back(-1) }}  >
                            Batal
                        </Button>

                     </form>
                </Grid>
                <Grid item sm />
            </Grid>
        )
    }
}

export default withStyles(styles) (UserRegisterApp);




