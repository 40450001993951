export const SWAL_TIMEOUT = "3000";
export const SWAL_SUCCESS_REPORT_NAME = "Form";
export const SWAL_SUCCESS_VERIFIER_FORM = "Verifier for form";
export const PAGINATION_RECORD_PER_PAGE = 50;
export const APPS_NAME = "INSURA17";

export const BASE_URL = "";
export const BRIDGE = "/bridge.php";
export const BRIDGEPOST = "/bridgePost.php";
export const BRIDGEGET = "/bridgeGet.php";
export const BRIDGEPUT = "/bridgePut.php";
export const BRIDGEDELETE = "/bridgeDelete.php";
export const BRIDGEPOSTFILE = "/bridgePostFile.php";

export const LOGIN_URL = BASE_URL + "auth/login";
export const FORGOT_PASSWORD_URL = BASE_URL + "auth/forgotPassword";
export const USER_LIST = BASE_URL + "user/list";
export const USER_REGISTER = BASE_URL + "user/user";
export const REGISTER_GENERAL = BASE_URL + "setupparameter/register_general";

export const COMPANY_LIST = BASE_URL + "setupparameter/company";
export const COMPANY_REGISTER = BASE_URL + "setupparameter/company_register";
export const COMPANY_REGISTER_UPLOAD =
  BASE_URL + "setupparameter/company_register_upload";

export const COMPANY_UPDATE = BASE_URL + "setupparameter/company_update";
export const COMPANY_UPDATE_UPLOAD =
  BASE_URL + "setupparameter/company_update_upload";

export const MENU_LIST = BASE_URL + "menu/list";
export const GET_MENU_DETAIL = BASE_URL + "menu/";
export const GET_PARENT_MENU = BASE_URL + "menu/getParentMenu/";
export const MENU_REGISTER = BASE_URL + "menu/register";

export const ROLE_REGISTER = BASE_URL + "role/role";
export const ROLEUSER_REGISTER = BASE_URL + "roleuser/role";
export const ROLEMENU_REGISTER = BASE_URL + "rolemenu/register";
export const CLOSING_PERIODE_REGISTER =
  BASE_URL + "prosesdata/register_closing_periode";
export const MULAI_PROSES = BASE_URL + "prosesdata/mulaiProses";
export const MULAI_PROSES_INSEPSI = BASE_URL + "prosesdata/mulaiProsesInsepsi";
export const MULAI_PROSES_TRANSISI =
  BASE_URL + "prosesdata/mulaiProsesTransisi";

export const UPLOAD_FILE = BASE_URL + "prosesdata/upload";
export const UPLOAD_FILE_INSEPSI = BASE_URL + "prosesdata/uploadInsepsi";

export const REGISTER_SETUP_MAP_PRODUK =
  BASE_URL + "setupparameter/map_product_register";

export const REGISTER_SETUP_MORTALITY_RATE_UPLOAD =
  BASE_URL + "setupparameter/mortality_rate_register_upload";
export const REGISTER_SETUP_MORTALITY_RATE =
  BASE_URL + "setupparameter/mortality_rate_register";

export const REGISTER_SETUP_LAPSE_RATE_UPLOAD =
  BASE_URL + "setupparameter/lapse_rate_register_upload";
export const REGISTER_SETUP_LAPSE_RATE =
  BASE_URL + "setupparameter/lapse_rate_register";

export const REGISTER_SETUP_MORBIDITY_RATE_UPLOAD =
  BASE_URL + "setupparameter/morbidity_rate_register_upload";
export const REGISTER_SETUP_MORBIDITY_RATE =
  BASE_URL + "setupparameter/morbidity_rate_register";

export const REGISTER_SETUP_CV_UPLOAD =
  BASE_URL + "setupparameter/cashvalue_benefit_register_upload";
export const REGISTER_SETUP_CV =
  BASE_URL + "setupparameter/cashvalue_benefit_register";

export const REGISTER_SETUP_BENEFIT_1_UPLOAD =
  BASE_URL + "setupbenefit/benefit_1_register_upload";
export const REGISTER_SETUP_BENEFIT_2_UPLOAD =
  BASE_URL + "setupbenefit/benefit_2_register_upload";
export const REGISTER_SETUP_BENEFIT_3_UPLOAD =
  BASE_URL + "setupbenefit/benefit_3_register_upload";
export const REGISTER_SETUP_BENEFIT_4_UPLOAD =
  BASE_URL + "setupbenefit/benefit_4_register_upload";
export const REGISTER_SETUP_BENEFIT_5_UPLOAD =
  BASE_URL + "setupbenefit/benefit_5_register_upload";
export const REGISTER_SETUP_BENEFIT_6_UPLOAD =
  BASE_URL + "setupbenefit/benefit_6_register_upload";
export const REGISTER_SETUP_BENEFIT_7_UPLOAD =
  BASE_URL + "setupbenefit/benefit_7_register_upload";

export const REGISTER_SETUP_RIDER1_RATE_UPLOAD =
  BASE_URL + "setuprate/rider_1_rate_register_upload";

export const REGISTER_SETUP_RIDER2_RATE_UPLOAD =
  BASE_URL + "setuprate/rider_2_rate_register_upload";

export const REGISTER_SETUP_RIDER3_RATE_UPLOAD =
  BASE_URL + "setuprate/rider_3_rate_register_upload";

export const REGISTER_SETUP_MB_UPLOAD =
  BASE_URL + "setupparameter/maturity_benefit_register_upload";
export const REGISTER_SETUP_MB =
  BASE_URL + "setupparameter/maturity_benefit_register";

export const REGISTER_SETUP_AB_UPLOAD =
  BASE_URL + "setupparameter/annuity_benefit_register_upload";
export const REGISTER_SETUP_AB =
  BASE_URL + "setupparameter/annuity_benefit_register";

export const REGISTER_SETUP_RB_UPLOAD =
  BASE_URL + "setupparameter/rider_benefit_register_upload";
export const REGISTER_SETUP_RB =
  BASE_URL + "setupparameter/rider_benefit_register";

export const REGISTER_SETUP_ACCOUNTING_UPLOAD =
  BASE_URL + "setupparameter/accounting_register_upload";
export const REGISTER_SETUP_ACCOUNTING =
  BASE_URL + "setupparameter/accounting_register";

export const REGISTER_SETUP_LAPSE_BENEFIT_UPLOAD =
  BASE_URL + "setupBenefit/lapse_benefit_register_upload";
export const REGISTER_SETUP_LAPSE_BENEFIT =
  BASE_URL + "setupBenefit/lapse_benefit_register";

export const REGISTER_SETUP_LIVING_BENEFIT_UPLOAD =
  BASE_URL + "setupBenefit/living_benefit_register_upload";
export const REGISTER_SETUP_LIVING_BENEFIT =
  BASE_URL + "setupBenefit/living_benefit_register";

export const REGISTER_SETUP_GENERAL_RATE_UPLOAD =
  BASE_URL + "setupparameter/register_general_upload";
export const REGISTER_SETUP_ASUMPTION_RATE =
  BASE_URL + "setuprate/register_asumption_rate";
