import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
// import AppIcon  from '.\www14.png';
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { ValidateData } from "../../common/util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { ROLEMENU_REGISTER } from "../../common/constantVariable";
import { GetItem } from "../../common/util";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "10px auto 10px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class MenuRegisterApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      role_id: "",
      menu_id: "",
      listMenu: [],
      listRole: [],
      listOwnedMenu: [],
      checked: [],
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
    };
    // console.log(this.state);
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios.get("rolemenu/initialData/0").then((result) => {
      this.setState({ openBackdrop: false });
      if (result.data.errorcode === 0) {
        // const menu = result.data.data;
        this.setState({
          listMenu: result.data.data.listMenu,
          listRole: result.data.data.listRole,
        });
      }
    });
  }

  handleSubmit = () => {
    const e = document.getElementById("form1");

    const valid = ValidateData(e.elements);

    if (!valid) {
      this.setState({
        openSnackError: true,
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    const dt = JSON.stringify(this.state.checked);

    var postData = new FormData();
    postData.append("role_id", this.state.role_id);
    postData.append("menu_id", dt);
    postData.append("url", ROLEMENU_REGISTER);
    postData.append("inserted_by", GetItem("user_id"));

    this.setState({ openBackdrop: true });
    axios
      .post(ROLEMENU_REGISTER, postData)
      .then((res) => {
        this.setState({ openBackdrop: false });

        if (res.data.message === "fail") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          errors: err.response.data.data,
          message: err.response.data.data,
          openSnackError: true,
          loading: false,
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });

    // if (e.target.name === "role_id") {
    //   axios
    //     .get("rolemenu/getRoleMenu/notassign/" + e.target.value)
    //     .then((result) => {
    //       if (result.data.errorcode === 0) {
    //         this.setState({
    //           listMenu: result.data.data,
    //         });
    //       }
    //     });
    // }
  };

  handleChangeMenu = (event) => {
    const { name, value, checked } = event.target;

    // Update state.checked based on the clicked checkbox
    if (checked) {
      // If the checkbox is checked, include the value in the state.checked array
      this.setState((prevState) => ({
        checked: [...prevState.checked, value],
      }));
    } else {
      // If the checkbox is unchecked, filter out the value from the state.checked array
      this.setState((prevState) => ({
        checked: prevState.checked.filter((item) => item !== value),
      }));
    }

    // Check if the clicked checkbox has submenus
    const submenus = this.state.listMenu.filter(
      (menu) => menu.parent_menu_id === value
    );

    // If it has submenus, update state.checked for all submenus
    if (submenus.length > 0) {
      const submenuIds = submenus.map((submenu) => submenu.menu_id);

      if (checked) {
        // If the parent is checked, include all submenuIds in the state.checked array
        this.setState((prevState) => ({
          checked: [...prevState.checked, ...submenuIds],
        }));
      } else {
        // If the parent is unchecked, filter out all submenuIds from the state.checked array
        this.setState((prevState) => ({
          checked: prevState.checked.filter(
            (item) => !submenuIds.includes(item)
          ),
        }));
      }
    }
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.errormessage}
          </Alert>
        </Snackbar>
        <Grid item sm />
        <Grid item sm>
          <Typography variant="h4" className={classes.pageTitle}>
            Tambah Role Menu
          </Typography>
          {/* <form id="form1" noValidate onSubmit={this.handleSubmit}> */}
          <form id="form1" name="form1" noValidate>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-helper-label">
                Role User
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                required
                value={this.state.role_id}
                onChange={this.handleChange}
                error={this.state.errors["role_id"]}
                size="small"
                name="role_id"
                label="Role User"
              >
                {this.state.listRole.map((role, idx) => (
                  <MenuItem value={role.role_id}>{role.role_name}</MenuItem>
                ))}
              </Select>
              <br />
              <br />
              <Typography variant="body2">List Menu</Typography>
              <FormGroup>
                {this.state.listMenu.map((menu, idx) => (
                  // {menu.parent_menu_id === '#'}
                  <FormControlLabel
                    control={<Checkbox />}
                    name="menu_id"
                    className={menu.parent_menu_id}
                    onChange={this.handleChangeMenu}
                    value={menu.menu_id}
                    style={{
                      marginLeft: menu.parent_menu_id != "#" ? "25px" : "0",
                    }}
                    checked={this.state.checked.includes(menu.menu_id)}
                    label={menu.menu_name}
                  />
                ))}
              </FormGroup>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Tambah
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(MenuRegisterApp);
