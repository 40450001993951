import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import HomeApp from "./pages/home";
import AboutApp from "./pages/about";
import UserApp from "./pages/user/user";
import companyApp from "./pages/company/company";
import RoleApp from "./pages/role/role";
import RoleUserApp from "./pages/roleuser/roleuser";
import RoleMenuApp from "./pages/rolemenu/rolemenu";
import MenuApp from "./pages/menu/menu";
import ProsesDataApp from "./pages/prosesdata/prosesdata";
import InsepsiApp from "./pages/saldoawalinsepsi/prosesdata";
import TransisiApp from "./pages/saldoawaltransisi/prosesdata";
import MenuProfitAndLoss from "./pages/report/profitandloss/profitandloss";
import BalanceSheet from "./pages/report/balancesheet/balancesheet";
import Accounting from "./pages/report/accounting/accounting";
import masterdata from "./pages/report/masterdata/masterdata";
import reportMovement from "./pages/report/movement/movement";
import reportcashflow from "./pages/report/cashflow/cashflow";

//setup rate
import setupMorbidity from "./pages/setupparameter/morbidityrate/morbidityrate";
import setupGeneral from "./pages/setuprate/asumption_rate/general";
import setupMortality from "./pages/setuprate/mortality_rate/mortalityrate";
import setupLapseRate from "./pages/setuprate/lapse_rate/lapserate";
import setupRider1_Rate from "./pages/setuprate/benefit_3_rate/rider_rate";
import setupRider2_Rate from "./pages/setuprate/benefit_4_rate/rider_rate";
import setupRider3_Rate from "./pages/setuprate/benefit_5_rate/rider_rate";

//setup benefit
import setupBenefit_1 from "./pages/setupbenefit/benefit_1/benefit";
import setupBenefit_2 from "./pages/setupbenefit/benefit_2/benefit";
import setupBenefit_3 from "./pages/setupbenefit/benefit_3/benefit";
import setupBenefit_4 from "./pages/setupbenefit/benefit_4/benefit";
import setupBenefit_5 from "./pages/setupbenefit/benefit_5/benefit";
import setupBenefit_6 from "./pages/setupbenefit/benefit_6/benefit";
import setupBenefit_7 from "./pages/setupbenefit/benefit_7/benefit";

import setupMappingproduk from "./pages/setupparameter/mappingproduk/mappingproduk";
import setupAccounting from "./pages/setupparameter/accounting/accounting";

//setup param
import setupBalanceSheet from "./pages/setupparam/balancesheet/balancesheet";
import setupProfitandLoss from "./pages/setupparam/profitandloss/profitandloss";

import logUser from "./pages/tools/loguser/loguser";
import deletebackupdata from "./pages/tools/deletebackupdata/deletebackupdata";

import reportActualTransaction from "./pages/report/reportactualtransaction/reportactualtransaction";
import reportCohort from "./pages/report/reportcohort/reportcohort";

import profile from "./pages/user/profile";
import setupmail from "./pages/setup/setupmail";
import reportExpectedExpenseTransisi from "./pages/report/reportexpensetransisi/expensetransisi";
import reportAcqcost from "./pages/report/reportacqcost/acqcost";

export default class RouteMain extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/home" component={HomeApp} />
        <Route exact path="/dashboard" component={HomeApp} />
        <Route exact path="/user/*" component={UserApp} />
        <Route exact path="/setupcompany/*" component={companyApp} />
        <Route exact path="/setuprole/*" component={RoleApp} />
        <Route exact path="/setupmenu/*" component={MenuApp} />
        <Route exact path="/setuproleuser/*" component={RoleUserApp} />
        <Route exact path="/setuprolemenu/*" component={RoleMenuApp} />
        <Route exact path="/prosesdata/*" component={ProsesDataApp} />
        <Route exact path="/saldoawaltransisi/*" component={TransisiApp} />
        <Route exact path="/saldoawalinsepsi/*" component={InsepsiApp} />
        <Route exact path="/reportprofitloss/*" component={MenuProfitAndLoss} />
        <Route exact path="/reportbalancesheet/*" component={BalanceSheet} />
        <Route exact path="/reportaccounting/*" component={Accounting} />
        <Route exact path="/reportmasterdata/*" component={masterdata} />
        <Route exact path="/reportmovement/*" component={reportMovement} />
        <Route exact path="/reportcashflow/*" component={reportcashflow} />

        {/* setup rate */}

        <Route exact path="/setup/general/*" component={setupGeneral} />
        <Route exact path="/setup/mortalityrate/*" component={setupMortality} />
        <Route exact path="/setup/lapserate/*" component={setupLapseRate} />
        <Route exact path="/setup/morbidityrate/*" component={setupMorbidity} />
        <Route
          exact
          path="/setup/benefit_3_rate/*"
          component={setupRider1_Rate}
        />
        <Route
          exact
          path="/setup/benefit_4_rate/*"
          component={setupRider2_Rate}
        />
        <Route
          exact
          path="/setup/benefit_5_rate/*"
          component={setupRider3_Rate}
        />

        {/*setup benefit*/}
        <Route exact path="/setup/benefit_1/*" component={setupBenefit_1} />
        <Route exact path="/setup/benefit_2/*" component={setupBenefit_2} />
        <Route exact path="/setup/benefit_3/*" component={setupBenefit_3} />
        <Route exact path="/setup/benefit_4/*" component={setupBenefit_4} />
        <Route exact path="/setup/benefit_5/*" component={setupBenefit_5} />
        <Route exact path="/setup/benefit_6/*" component={setupBenefit_6} />
        <Route exact path="/setup/benefit_7/*" component={setupBenefit_7} />

        <Route
          exact
          path="/setup/mappingproduk/*"
          component={setupMappingproduk}
        />
        <Route exact path="/setup/journal/*" component={setupAccounting} />

        <Route
          exact
          path="/setup/profitandloss/*"
          component={setupProfitandLoss}
        />
        <Route
          exact
          path="/setup/balancesheet/*"
          component={setupBalanceSheet}
        />

        <Route exact path="/tools/loguser/*" component={logUser} />
        <Route
          exact
          path="/tools/deletedatabackup/*"
          component={deletebackupdata}
        />
        <Route
          exact
          path="/reportactualtransaction/*"
          component={reportActualTransaction}
        />
        <Route
          exact
          path="/report/expectedexpense/*"
          component={reportExpectedExpenseTransisi}
        />
        <Route
          exact
          path="/report/acqcost/*"
          component={reportAcqcost}
        />
        
        <Route exact path="/reportcohort/*" component={reportCohort} />
        <Route exact path="/profile" component={profile} />
        <Route exact path="/setupmail" component={setupmail} />

        <Route exact path="**" component={HomeApp} />
      </Switch>
    );
  }
}
