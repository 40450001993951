import React, { Component, useState } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import axios from "axios";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  ValidateData,
  ValidateDataEmail,
  ValidateEmail,
  GetItem,
} from "../../../common/util";
import {
  BRIDGEGET,
  BRIDGEPUT,
  BASE_URL,
} from "../../../common/constantVariable";

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  form: {
    textAlign: "center",
  },
  image: {
    margin: "20px auto 20px auto",
  },
  pageTitle: {
    margin: "10px auto 10px auto",
  },
  textField: {
    margin: "5px auto 5px auto",
  },
  button: {
    marginTop: 20,
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
};

class UserUpdateApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      MTH: GetItem("mth"),
      KD_PRODUK: GetItem("kd_produk"),
      KEY_BENEFIT_1: GetItem("KEY_BENEFIT_1"),
      KEY_BENEFIT_2: GetItem("KEY_BENEFIT_2"),
      KEY_BENEFIT_3: GetItem("KEY_BENEFIT_3"),
      RATE: GetItem("RATE"),

      kd_produk: GetItem("kd_produk"),
      mth: GetItem("mth"),
      key_benefit_1: GetItem("KEY_BENEFIT_1"),
      key_benefit_2: GetItem("KEY_BENEFIT_2"),
      key_benefit_3: GetItem("KEY_BENEFIT_3"),
      user_id: GetItem("user_id"),
      rate: "",
      valid: false,
      helpers: [],
      errors: [],
      errormessage: "",
      openSnack: false,
      openSnackError: false,
      Backdrop: false,
      message: "",
    };
  }

  componentDidMount() {
    this.setState({ openBackdrop: true });
    axios
      .get(
        BASE_URL +
          "setupbenefit/benefit_6/getDetail/" +
          this.state.KD_PRODUK +
          "/" +
          this.state.MTH +
          "/" +
          this.state.KEY_BENEFIT_1 +
          "/" +
          this.state.KEY_BENEFIT_2 +
          "/" +
          this.state.KEY_BENEFIT_3 +
          "/" +
          this.state.RATE
      )
      .then((result) => {
        this.setState({ openBackdrop: false });
        if (Object.keys(result.data.data).length > 0) {
          const data = result.data.data[0];
          this.setState({
            key_benefit_2: data.KEY_BENEFIT_2,
            key_benefit_1: data.KEY_BENEFIT_1,
            key_benefit_3: data.KEY_BENEFIT_3,
            rate: data.Rate,
          });
        }
      });
  }

  handleSubmit = () => {
    const e = document.getElementById("form1");

    if (ValidateData(e.elements) === false) {
      this.setState({
        errormessage: "Data tidak lengkap",
      });
      return false;
    }

    this.setState({ openBackdrop: true });
    axios
      .put(
        "setupbenefit/benefit_6/" +
          this.state.KD_PRODUK +
          "/" +
          this.state.MTH +
          "/" +
          this.state.KEY_BENEFIT_1 +
          "/" +
          this.state.KEY_BENEFIT_2 +
          "/" +
          this.state.KEY_BENEFIT_3 +
          "/" +
          this.state.RATE,
        this.state
      )
      .then((res) => {
        this.setState({ openBackdrop: false });

        if (res.data.message != "success") {
          this.setState({
            message: res.data.data,
            openSnackError: true,
          });
        } else {
          this.setState({
            message: res.data.data,
            openSnack: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ openBackdrop: false });
        this.setState({
          errormessage: "Gagal simpan data",
        });
      });
  };

  handleChange = (e) => {
    const error = e.target.required && e.target.value === "";

    this.state.errors[e.target.name] = error;
    this.state.helpers[e.target.name] = error ? "required" : "";

    if (!error && e.target.type === "email") {
      if (!ValidateEmail(e.target.value)) {
        this.state.errors[e.target.name] = true;
        this.state.helpers[e.target.name] = "format email salah";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      errormessage: "",
      errors: this.state.errors,
      helpers: this.state.helpers,
    });
  };

  handleClose = (event, reason) => {
    this.setState({ openSnack: false });
    if (reason === "timeout") {
      window.history.back(-1);
    }
  };

  render() {
    const { classes } = this.props;
    const { errors, loading } = this.state;

    return (
      <Grid container className={classes.form}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.openBackdrop}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Snackbar
          open={this.state.openSnack}
          autoHideDuration={1000}
          onClose={(event, reason) => this.handleClose(event, reason)}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnack: false });
              window.history.back(-1);
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>
        <Snackbar open={this.state.openSnackError} autoHideDuration={2000}>
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            variant="filled"
            onClose={() => {
              this.setState({ openSnackError: false });
            }}
          >
            {this.state.message}
          </Alert>
        </Snackbar>

        <Grid item sm />
        <Grid item sm>
          <Typography variant="h6" className={classes.pageTitle}>
            Update Benefit
          </Typography>

          <form id="form1" name="form1" noValidate>
            <TextField
              id="kd_produk"
              name="kd_produk"
              type="text"
              label="Kode Produk"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.kd_produk}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="user_id"
              name="mth"
              type="number"
              label="Month"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.mth}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <TextField
              id="user_id"
              name="key_benefit_1"
              type="number"
              label="Key Benefit 1"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.key_benefit_1}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              id="key_benefit_2"
              name="key_benefit_2"
              type="number"
              label="Key Benefit 2"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.key_benefit_2}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              id="key_benefit_3"
              name="key_benefit_3"
              type="number"
              label="Key Benefit 3"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.key_benefit_3}
              onChange={this.handleChange}
              fullWidth
            />
            <TextField
              id="rate"
              name="rate"
              type="number"
              label="Rate"
              size="small"
              variant="outlined"
              className={classes.textField}
              value={this.state.rate}
              onChange={this.handleChange}
              required
              fullWidth
            />
            <Typography variant="body2" className={classes.customError}>
              {this.state.errormessage}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.handleSubmit}
            >
              Simpan
            </Button>{" "}
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => {
                window.history.back(-1);
              }}
            >
              Batal
            </Button>
          </form>
        </Grid>
        <Grid item sm />
      </Grid>
    );
  }
}

export default withStyles(styles)(UserUpdateApp);
