import React  from 'react';
import { Route, Switch } from 'react-router-dom';

import listAccounting from './listaccounting';    
import listaccounting_detail from './listaccounting_detail';     

export default class accounting extends React.Component {
 
     render() {

        return (
            <Switch>  
            <Route exact path='/reportaccounting/detail' component={listaccounting_detail} /> 
            <Route exact path='/reportaccounting/**' component={listAccounting} /> 
            </Switch>
        );
    }

};