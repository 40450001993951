import React from 'react';
import { useIntl } from 'react-intl';
import SwitchBtn from 'react-switch';
// import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { FaHeart, FaBars } from 'react-icons/fa';

import RouteMain from '../routemain';

const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
}) => {
  const intl = useIntl();
  return (
    <main>
      <div className="block ">
        <SwitchBtn
          height={16}
          width={30}
          checkedIcon={false}
          uncheckedIcon={false}
          onChange={handleCollapsedChange}
          checked={collapsed}
          onColor="#219de9"
          offColor="#bbbbbb"
        />
        <span> {intl.formatMessage({ id: 'collapsed' })}</span>
      </div>

      <div>
        <RouteMain />          
      </div>
      

      <footer>
        <small>
          © {new Date().getFullYear()}  
          
        </small>
      </footer>
    </main>
  );
};

export default Main;
