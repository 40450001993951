import React from "react";
import { Route, Switch } from "react-router-dom";

import listApp from "./list";

export default class Deletebackupdata extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/tools/deletedatabackup/**" component={listApp} />
      </Switch>
    );
  }
}
